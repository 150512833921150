import { IM, IMStyle, LoadingSpinnerProps } from '@infominds/react-native-components'
import React from 'react'
import { Modal, StyleSheet } from 'react-native'

export default function LoadingSpinnerModal({ isVisible = false, color = IMStyle.palette.tint, ...otherProps }: LoadingSpinnerProps) {
  return (
    <Modal visible={isVisible} statusBarTranslucent transparent>
      <IM.LoadingSpinner color={color} style={styles.modal} {...otherProps} isVisible />
    </Modal>
  )
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    backgroundColor: '#00000070',
  },
})
