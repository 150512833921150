import { ItemSelector, useItemSelector } from '@infominds/react-native-components'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import type { Document, Employee, EmployeeTime, EmployeeTimeType } from '../apis/types/apiResponseTypes'
import { useActivities } from '../contexts/ActivitiesContext'
import DocumentUtils from '../utils/DocumentUtils'
import TimeUtils from '../utils/TimeUtils'
import useUserSettings from './useUserSettings'

type MissingData = 'activity' | 'document' | 'note' | 'time'

export type NewTimeHandlerMode = 'create' | 'modify'
export interface NewTimeHandler {
  mode: NewTimeHandlerMode
  date: Date | undefined
  from: number
  until: number
  selectedActivity?: EmployeeTimeType
  selectedDocument?: Document
  employeeSelector: ItemSelector<Employee>
  note?: string
  missingDataToStart: MissingData[]
  missingDataToCreate: MissingData[]
  setSelectedActivity: Dispatch<SetStateAction<EmployeeTimeType | undefined>>
  setSelectedDocument: Dispatch<SetStateAction<Document | undefined>>
  setNote: Dispatch<SetStateAction<string>>
  setFrom: Dispatch<SetStateAction<number>>
  setUntil: Dispatch<SetStateAction<number>>
}

export type NewTimeHandlerOptions = {
  presetDate?: Date
  presetActivity?: EmployeeTimeType
  presetDocument?: Document
  activeTime?: EmployeeTime
  presetEmployees?: Employee[]
  presetFrom?: number
  presetUntil?: number
  presetNote?: string
  mode?: NewTimeHandlerMode
}

export default function useNewTimeHandler({
  presetDate,
  presetActivity,
  presetDocument,
  activeTime,
  presetEmployees,
  presetFrom,
  presetUntil,
  presetNote,
  mode = 'create',
}: NewTimeHandlerOptions): NewTimeHandler {
  const userSettings = useUserSettings()
  const { getActivityById } = useActivities()
  const [from, setFrom] = useState<number>(presetFrom ?? TimeUtils.getCurrentMinutes() * 60)
  const [until, setUntil] = useState<number>(presetUntil ?? (TimeUtils.getCurrentMinutes() + 1) * 60)
  const [selectedActivity, setSelectedActivity] = useState<EmployeeTimeType | undefined>(presetActivity)
  const [selectedDocument, setSelectedDocument] = useState<Document | undefined>(presetDocument)
  const employeeSelector = useItemSelector<Employee>(presetEmployees ?? [], (a, b) => a.id === b.id)
  const [note, setNote] = useState(presetNote ?? '')
  const missingDataToStart: MissingData[] = checkStart()
  const missingDataToCreate: MissingData[] = checkCreate()

  useEffect(() => {
    if (!activeTime) return
    employeeSelector.set(activeTime.subordinates ?? [])
    setSelectedActivity(getActivityById(activeTime.employeeTimeTypeId))
    setSelectedDocument(DocumentUtils.createDocumentFromEmployeeTime(activeTime))
    setNote(activeTime?.note ?? '')
    setFrom(activeTime.from)
    setUntil(activeTime.until)
  }, [activeTime?.from, activeTime?.until, activeTime?.note, activeTime?.documentId, activeTime?.employeeTimeTypeId])

  function checkStart() {
    const missing: MissingData[] = []
    if (!selectedActivity) missing.push('activity')
    if (!!selectedActivity?.documentNecessary && !selectedDocument) missing.push('document')
    if (!note && !!userSettings?.commentAsMandatoryInput) missing.push('note')
    return missing
  }

  function checkCreate() {
    const missing = checkStart()
    const invalidTime =
      !Number.isInteger(from) ||
      !Number.isInteger(until) ||
      from === until ||
      from < 0 ||
      until < 0 ||
      (mode === 'modify' && until < from && until > 0)
    if (invalidTime) missing.push('time')
    return missing
  }

  return {
    mode,
    date: presetDate,
    from,
    until,
    selectedActivity,
    selectedDocument,
    employeeSelector,
    note,
    missingDataToStart,
    missingDataToCreate,
    setSelectedActivity,
    setSelectedDocument,
    setNote,
    setFrom,
    setUntil,
  }
}
