import { IM, useLanguage } from '@infominds/react-native-components'
import { RouteProp, StackActions, useNavigation, useRoute } from '@react-navigation/native'
import React from 'react'

import { ScreenHeader } from '../../components/ScreenHeader'
import type { RootStackParamList } from '../../navigation/Navigator'
import InfoboxFolderView from '../../views/InfoBox/InfoboxFolderView'

const InfoboxFolderScreen = () => {
  const navigation = useNavigation()
  const { i18n } = useLanguage()
  const { document, navigationSource } = useRoute<RouteProp<RootStackParamList, 'InfoboxFolder'>>().params
  const abortController = new AbortController()

  return (
    <IM.Screen
      screenHeader={
        <ScreenHeader
          goBack={() => {
            navigation.setParams({ navigationSource: undefined })
            if (navigationSource === 'HomeStack' || navigationSource === 'HistoryStack') {
              navigation.dispatch(StackActions.popToTop())
              navigation.navigate(navigationSource)
            } else {
              navigation.goBack()
            }
          }}
          title={document ? document.code : i18n.t('NO_DOCUMENT_SELECTED')}
        />
      }>
      {document && <InfoboxFolderView document={document} navigationSource={navigationSource} abortController={abortController} />}
    </IM.Screen>
  )
}

export default InfoboxFolderScreen
