import { IM, ModalController, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { FlatList, Platform } from 'react-native'

import type { EmployeeTimeType } from '../apis/types/apiResponseTypes'
import ActivityCard from '../cards/ActivityCard'
import ModalFullScreen from '../components/Infominds/ModalFullScreen'
import { ScreenHeader } from '../components/ScreenHeader'

export interface ActivitySelectionModalProps {
  onSelected: (item: EmployeeTimeType) => void
  controller: ModalController<EmployeeTimeType[]>
}

export default function ActivitySelectionModal({ onSelected, controller }: ActivitySelectionModalProps) {
  const { i18n } = useLanguage()

  return (
    <ModalFullScreen isVisible={controller.isShown} onClose={controller.close} useNativeDriver={Platform.OS === 'android'}>
      <IM.Screen
        screenHeader={
          <ScreenHeader
            title={i18n.t('Activity')}
            close={() => {
              controller.close()
            }}
          />
        }
        spacing="all">
        <FlatList
          data={controller.data ?? []}
          renderItem={({ item }) => (
            <ActivityCard
              spacing="bottom"
              activity={item}
              onPress={() => {
                onSelected(item)
                controller.close()
              }}
            />
          )}
        />
      </IM.Screen>
    </ModalFullScreen>
  )
}
