import { useEffect, useRef, useState } from 'react'

export type ListLoaderOptions<T> = {
  take: number
  onLoadedCallback?: (items: T[], reload: boolean) => void
  onErrorCallback?: (reload: boolean) => void
}

export type LoadingType = 'reload' | 'loadMore' | false

interface SkipTake {
  skip?: number
  take?: number
}

/**
 * Hook manages data for dynamically growing list.
 * Load can be used to load first time or to reload.
 * LoadMore can be used f.ex. onListEndReached
 * @param getData function to get Data
 * @param options loader options
 * @returns [items, loadItems, loading, loadMoreItems, setItems, allDataLoaded]
 */
export default function useListLoader<T, TRequest extends SkipTake>(
  getData: (request: TRequest, abortController: AbortController) => Promise<T[]>,
  options: ListLoaderOptions<T>
): [T[], (request: TRequest) => void, LoadingType, () => void, React.Dispatch<React.SetStateAction<T[]>>, boolean] {
  const { take, onLoadedCallback, onErrorCallback } = options

  const [items, setItems] = useState<T[]>([])
  const [loading, setLoading] = useState<LoadingType>(false)
  const [loadCounter, setLoadCounter] = useState(0)
  const [endOfListCounter, setEndOfListCounter] = useState(0)
  const allDataLoaded = useRef(false)
  const loadMoreAbortController = useRef<AbortController | null>(null)
  const request = useRef<TRequest | null>(null)

  useEffect(() => {
    if (!loadCounter) return
    loadMoreAbortController.current?.abort()
    const abortController = loader(true)
    return () => abortController?.abort()
  }, [loadCounter])

  useEffect(() => {
    if (!endOfListCounter) return
    loadMoreAbortController.current = loader()
    return () => loadMoreAbortController.current?.abort()
  }, [endOfListCounter])

  function loader(reload?: boolean) {
    if ((!reload && (!endOfListCounter || allDataLoaded.current)) || !request.current) return null
    setLoading(reload ? 'reload' : 'loadMore')
    const abortController = new AbortController()
    const skip = reload ? 0 : items.length
    getData({ ...request.current, skip, take }, abortController)
      .then(result => {
        allDataLoaded.current = result.length < take
        setItems(prev => {
          if (reload) return result ?? []
          prev.push(...result)
          if (onLoadedCallback) onLoadedCallback(prev, !!reload)
          return prev
        })
      })
      .catch(err => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (err?.message === 'Aborted') return
        if (onErrorCallback) onErrorCallback(!!reload)
        else console.error('Failed load() on useListLoader', err)
      })
      .finally(() => setLoading(false))

    return abortController
  }

  function load(requestData: TRequest) {
    request.current = requestData
    setLoadCounter(prev => prev + 1)
  }

  function loadMore() {
    if (allDataLoaded.current || loading) return
    setEndOfListCounter(prev => prev + 1)
  }

  return [items, load, loading, loadMore, setItems, allDataLoaded.current]
}
