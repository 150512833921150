import { ModalController, useItemSelector, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { FlatList } from 'react-native'

import type { Employee } from '../apis/types/apiResponseTypes'
import EmployeeCard from '../cards/EmployeeCard'
import Modal from '../components/Infominds/Modal'
import { ScreenHeader } from '../components/ScreenHeader'

export interface PersonalSelectionModalProps {
  allEmployees: Employee[]
  selectedEmployees: Employee[]
  setSelectedEmployees: (employees: Employee[]) => void
  controller: ModalController<void>
}

export default function PersonalSelectionModal({ allEmployees, selectedEmployees, setSelectedEmployees, controller }: PersonalSelectionModalProps) {
  const { i18n } = useLanguage()

  const [changed, setChanged] = useState(false)

  const selector = useItemSelector<Employee>(selectedEmployees, (a, b) => a.id === b.id)

  useEffect(() => {
    setChanged(false)
    if (!controller.isShown) return
    selector.set(selectedEmployees)
  }, [controller.isShown])

  return (
    <Modal
      statusBarTranslucent
      isVisible={controller.isShown}
      onClose={controller.close}
      spacing="top"
      screenHeader={
        <ScreenHeader
          title={i18n.t('Personal')}
          close={() => {
            setSelectedEmployees(selector.items)
            controller.close()
          }}
          closeIcon={changed ? ['fal', 'check'] : ['fal', 'times']}
        />
      }>
      <FlatList
        data={allEmployees ?? []}
        renderItem={({ item }) => (
          <EmployeeCard
            spacing={['bottom', 'horizontal']}
            employee={item}
            onPress={() => {
              selector.toggle(item)
              setChanged(true)
            }}
            showSelector
            selected={selector.includes(item)}
          />
        )}
      />
    </Modal>
  )
}
