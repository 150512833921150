import { IM, SpacingProps, useTheme } from '@infominds/react-native-components'
import React from 'react'

import type { Employee } from '../../apis/types/apiResponseTypes'
import EmployeeUtils from '../../utils/EmployeeUtils'

export type EmployeeBadgeProps = { employee: Employee | undefined; onPress?: () => void; spacing?: SpacingProps }

export default function EmployeeBadge({ employee, onPress, spacing }: EmployeeBadgeProps) {
  const { themeUtils } = useTheme()
  const code = EmployeeUtils.getInitials(employee)

  if (!employee) return <></>
  return (
    <IM.Badge spacing={spacing} backgroundColor={themeUtils.getRandomColorFromTheme(code)} onPress={onPress}>
      {code}
    </IM.Badge>
  )
}
