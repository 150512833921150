import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import Color from 'color'
import { format, isSameDay } from 'date-fns'
import isEqual from 'lodash/isEqual'
import React, { useMemo } from 'react'
import { Pressable, StyleSheet } from 'react-native'

import appUtils from '../../../utils/Utils'
import { CalendarContextProps, UpdateSources } from './context/CalendarContext'
import useCalendar from './hooks/useCalendar'

export const CALENDAR_DAY_COLOR = '#D5D5D5'

interface DayProps {
  displayDate: Date
  width: number
  context: CalendarContextProps
  barColor?: string | null
}

function arePropsEqual(prevProps: DayProps, nextProps: DayProps) {
  const { context: prevContext, ...prevOthers } = prevProps
  const { context: nextContext, ...nextOthers } = nextProps

  return isEqual(prevContext, nextContext) && isEqual(prevOthers, nextOthers)
}

function Day({ displayDate, width, barColor }: DayProps) {
  const { date, today, setDate } = useCalendar()
  const { language } = useLanguage()
  const { theme, colorScheme } = useTheme()

  const isSelected = useMemo(() => isSameDay(displayDate, date), [date, displayDate])

  const todayColor = useMemo(() => (isSameDay(displayDate, today) ? theme.chip.highlightedBackground : CALENDAR_DAY_COLOR), [today, displayDate])

  const todayWeight = useMemo(
    () => (isSameDay(displayDate, today) ? IMStyle.typography.fontWeightMedium : IMStyle.typography.fontWeightRegular),
    [date, today, displayDate]
  )

  const color = useMemo(
    () => (isSelected ? (colorScheme === 'dark' ? theme.chip.background : Color(IMStyle.palette.grey).lighten(0.4).toString()) : 'transparent'),
    [isSelected, theme]
  )

  const handlePress = () => {
    setDate(displayDate, UpdateSources.DAY_PRESS)
  }

  return (
    <Pressable
      style={[
        styles.day,
        {
          width: width / 7,
          backgroundColor: color,
          borderRadius: IMStyle.layout.borderRadius,
        },
      ]}
      onPress={handlePress}>
      <IM.Text style={{ color: todayColor, fontWeight: todayWeight }}>
        {format(displayDate, language === 'de' ? 'EEEEEE' : 'EEE', { locale: appUtils.languageToLocale(language) })}
      </IM.Text>
      <IM.Text
        style={{
          color: todayColor,
          fontSize: IMStyle.typography.fontSizeRegular,
          fontWeight: todayWeight,
        }}>
        {displayDate.getDate()}
      </IM.Text>
      {!!barColor && <IM.View style={[styles.statusBar, { backgroundColor: barColor }]} />}
    </Pressable>
  )
}

export default React.memo(Day, arePropsEqual)

const styles = StyleSheet.create({
  day: {
    alignItems: 'center',
    paddingVertical: 4,
  },
  statusBar: {
    marginTop: 3,
    width: 15,
    height: 3,
    borderRadius: 100,
  },
})
