import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { ReactNode, useMemo } from 'react'
import { Pressable, ScrollView, StyleSheet } from 'react-native'
import { useRecoilState, useResetRecoilState } from 'recoil'

import useLayout from '../hooks/useLayout'
import useUserSettings from '../hooks/useUserSettings'
import { infoboxFilterPreferenceAtom } from '../utils/stateManager'
import appUtils from '../utils/Utils'

interface Props {
  documentName?: string
  mediaHeader?: ReactNode
  forceSmallDevice?: boolean
}

export default function InfoboxHeader({ documentName, mediaHeader, forceSmallDevice }: Props) {
  const userSettings = useUserSettings()
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const { isSmallDevice } = useLayout()

  const [filter, setFilter] = useRecoilState(infoboxFilterPreferenceAtom)
  const resetFilter = useResetRecoilState(infoboxFilterPreferenceAtom)

  const masterFlex = useMemo(() => (!forceSmallDevice && !isSmallDevice ? 0.333 : 1), [isSmallDevice])

  const selectedViewStyle = {
    backgroundColor: IMStyle.palette.tint,
  }

  const selectedTextStyle = {
    color: theme.chip.highlightedText,
  }

  const handlePress = (type?: string) => {
    if (type) {
      setFilter(type)
    } else {
      resetFilter()
    }
  }

  return (
    <IM.View style={styles.container}>
      <IM.View style={{ flex: masterFlex }}>
        <ScrollView horizontal contentContainerStyle={styles.scrollViewContent}>
          {userSettings?.documentTypesAvailable?.map(type => {
            return (
              <Pressable key={type} onPress={() => handlePress(type)}>
                <IM.View
                  style={[styles.filter, styles.groupedFilter, { backgroundColor: theme.chip.background }, filter === type && selectedViewStyle]}>
                  <IM.Text style={[styles.text, { color: theme.chip.text }, filter === type && selectedTextStyle]}>
                    {appUtils.translateFilter(type, i18n)}
                  </IM.Text>
                </IM.View>
              </Pressable>
            )
          })}
        </ScrollView>
      </IM.View>
      {!forceSmallDevice && !isSmallDevice && (
        <>
          {documentName && (
            <IM.View style={styles.details}>
              <IM.View style={styles.firstDetail}>
                <IM.Text style={styles.title}>{documentName}</IM.Text>
              </IM.View>
            </IM.View>
          )}
          {mediaHeader && (
            <IM.View style={styles.details}>
              <IM.View style={styles.secondDetail}>{mediaHeader}</IM.View>
            </IM.View>
          )}
        </>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  secondDetail: {
    paddingLeft: 8,
  },
  firstDetail: {
    paddingLeft: 4,
  },
  details: {
    flex: 0.333,
    justifyContent: 'center',
  },
  scrollViewContent: { alignItems: 'center' },
  filter: {
    borderRadius: IMStyle.layout.borderRadius,
    minWidth: 52,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 5,
    paddingHorizontal: 9,
  },
  groupedFilter: {
    marginRight: 7,
  },
  text: {
    fontSize: IMStyle.typography.fontSizeSmall,
    fontWeight: IMStyle.typography.fontWeightRegular,
  },
  title: {
    fontSize: IMStyle.typography.fontSizeRegular,
    fontWeight: IMStyle.typography.fontWeightRegular,
    color: IMStyle.palette.white,
  },
})
