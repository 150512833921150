import { IM, ModalController } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'
import type Toast from 'react-native-toast-notifications'

import type { EmployeeTime } from '../../apis/types/apiResponseTypes'
import TimeNote from '../../components/TimeNote'
import TimeSpanSelector from '../../components/TimeSpanSelector'
import useAllowEmployeeTimeEdit from '../../hooks/useAllowEmployeeTimeEdit'
import type { NewTimeHandler } from '../../hooks/useNewTimeHandler'
import useUserSettings from '../../hooks/useUserSettings'
import { ModifyTimeData } from '../../modals/ModifyTimeModal'
import type { NewTimeModalData } from '../../modals/NewTimeModal'
import TimeUtils from '../../utils/TimeUtils'
import ActivitySelectionView from '../../views/NewTime/ActivitySelectionView'
import DocumentSelectionView from '../../views/NewTime/DocumentSelectionView'
import PersonalSelectionView from '../../views/NewTime/PersonalSelectionView'

export type TimeSelectionViewProps = {
  handler: NewTimeHandler
  toastRef?: React.MutableRefObject<Toast | undefined>
  creationMode?: boolean
  activeTime?: EmployeeTime
  disableEmployeeSelection?: boolean
  allowDocumentUnselecting?: boolean
  onNoteChanged?: (note: string) => Promise<void>
  newTimeController?: ModalController<NewTimeModalData> | ModalController<ModifyTimeData>
  isCreationAlwaysAllowed?: boolean
}

export default function TimeSelectionView({
  handler,
  toastRef,
  creationMode,
  activeTime,
  disableEmployeeSelection,
  allowDocumentUnselecting,
  newTimeController,
  isCreationAlwaysAllowed,
  onNoteChanged,
}: TimeSelectionViewProps) {
  const userSettings = useUserSettings()
  const { allowEdit } = useAllowEmployeeTimeEdit(handler.date ?? new Date())
  const disabled = !!activeTime || (!allowEdit && !isCreationAlwaysAllowed)

  //if time is active send new note to api
  function handleNoteChanged() {
    if (!onNoteChanged) return
    onNoteChanged(handler.note ?? '').catch(console.error)
  }

  const activityOk = !!handler.selectedActivity
  const showDocument = (disabled && !!handler.selectedDocument) || (!disabled && activityOk)
  const documentOk = !!handler.selectedDocument || (!!handler.selectedActivity && !handler.selectedActivity?.documentNecessary)
  const showPersonal =
    (disabled || (disableEmployeeSelection && activityOk) || (!disabled && documentOk && activityOk)) && !!userSettings?.subordinates?.length
  const showNote = disabled || (!disabled && documentOk && activityOk)
  const showTime = showNote && (!!handler.note || !userSettings?.commentAsMandatoryInput)

  return (
    <IM.View spacing="vertical" style={styles.view}>
      <ActivitySelectionView disabled={disabled} selectedActivity={handler.selectedActivity} setSelectedActivity={handler.setSelectedActivity} />
      {showDocument && (
        <DocumentSelectionView
          disabled={disabled}
          selectedDocument={handler.selectedDocument}
          setSelectedDocument={handler.setSelectedDocument}
          allowUnselected={allowDocumentUnselecting}
          newTimeController={newTimeController}
        />
      )}
      {showPersonal && (
        <PersonalSelectionView disabled={disabled || disableEmployeeSelection} employeeSelector={handler.employeeSelector} reducedView={disabled} />
      )}
      {showNote && (
        <TimeNote
          value={handler.note}
          setValue={handler.setNote}
          spacing="all"
          onEndEditing={handleNoteChanged}
          editable={!!allowEdit || !!activeTime || !!isCreationAlwaysAllowed}
        />
      )}
      {!!creationMode && showTime && (
        <TimeSpanSelector
          spacing="all"
          from={handler.from ?? TimeUtils.getCurrentSeconds()}
          until={handler.until ?? TimeUtils.getCurrentSeconds()}
          setFrom={handler.setFrom}
          setUntil={handler.setUntil}
          error={!!handler.missingDataToCreate.includes('time')}
          allowDateSkip={handler.mode === 'create'}
          toastRef={toastRef}
          disabled={!allowEdit}
        />
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
})
