import { IM, SpacingProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import TimeUtils from '../../utils/TimeUtils'

export type PresenceTimeHourCardProps = {
  title: string
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
} & ({ timeS: number; timeM?: never } | { timeS?: never; timeM: number })

export default function PresenceTimeHourCard({ title, timeS, timeM, spacing, style }: PresenceTimeHourCardProps) {
  const time = useMemo(
    () => (timeS ? TimeUtils.formatSeconds(timeS, undefined, true, true) : TimeUtils.formatDurationMinutes(timeM ?? 0)),
    [timeM, timeS]
  )

  return (
    <IM.View spacing={spacing} style={style}>
      <IM.CardBasic style={styles.card}>
        <IM.Text>{title}</IM.Text>
        <IM.Text primary>{time}</IM.Text>
      </IM.CardBasic>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  card: {
    padding: 10,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
})
