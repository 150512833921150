import { IM, IMStyle } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'
import { useRecoilState, useRecoilValue } from 'recoil'

import { assetsToSyncAtom, mediaSortingMethodInfoboxAtom } from '../utils/stateManager'
import appUtils from '../utils/Utils'
import { screenHeaderSettings } from './ScreenHeader'

interface Props {
  onMediaUpload: () => void
}

export default function InfoboxTabletHeader({ onMediaUpload }: Props) {
  const [displayMethod, setDisplayMethod] = useRecoilState(mediaSortingMethodInfoboxAtom)
  const assetToSync = useRecoilValue(assetsToSyncAtom)

  return (
    <IM.View style={styles.container}>
      <IM.PressableIcon
        color={IMStyle.palette.white}
        icon={['fal', displayMethod === 'twice' ? 'list' : displayMethod === 'details' ? 'square' : 'grid-2']}
        size={screenHeaderSettings.iconSize}
        onPress={() => {
          setDisplayMethod(appUtils.getMediaDisplayMethod(displayMethod))
        }}
      />
      {assetToSync > 0 && (
        <IM.PressableIcon color={IMStyle.palette.white} icon={['fal', 'check']} size={screenHeaderSettings.iconSize} onPress={onMediaUpload} />
      )}
    </IM.View>
  )
}
const styles = StyleSheet.create({
  container: { flexDirection: 'row', justifyContent: 'space-between' },
})
