import { PermissionsAndroid, Platform } from 'react-native'
import Geolocation from 'react-native-geolocation-service'

export type LatLng = { latitude: number; longitude: number }

const GeoLocationUtils = {
  /**
   * Requests Permission for GeoLocation.
   * @returns Promise. Resolves true if permission is granted. Rejects otherwise
   */
  requestPermission() {
    return new Promise<boolean>((resolve, reject) => {
      if (Platform.OS === 'ios') {
        Geolocation.requestAuthorization('always')
          .then(result => {
            if (result === 'granted') resolve(true)
            else reject('Failed to authorize GeoLocation')
          })
          .catch(reject)
      } else if (Platform.OS === 'android') {
        PermissionsAndroid.requestMultiple(['android.permission.ACCESS_FINE_LOCATION', 'android.permission.ACCESS_COARSE_LOCATION'])
          .then(result => {
            if (result['android.permission.ACCESS_FINE_LOCATION'] === 'granted' && result['android.permission.ACCESS_COARSE_LOCATION']) {
              resolve(true)
            } else {
              reject('Failed to authorize GeoLocation')
            }
          })
          .catch(reject)
      }
    })
  },
  /**
   * Get current Device position. Android & iOS only.
   * Requests permission if not already granted
   * @returns device position
   */
  getDevicePosition() {
    return new Promise<LatLng | undefined>((resolve, reject) => {
      // Geolocation only works for ios and android
      if (Platform.OS === 'web' || Platform.OS === 'windows') {
        resolve(undefined)
      }
      this.requestPermission()
        .then(() => {
          Geolocation.getCurrentPosition(
            position => {
              resolve({ latitude: position.coords.latitude, longitude: position.coords.longitude })
            },
            error => {
              console.error(error)
              reject(error)
            },
            {
              enableHighAccuracy: true,
              timeout: 2000,
            }
          )
        })
        .catch(reject)
    })
  },
}

export default GeoLocationUtils
