import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { format } from 'date-fns'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import useCalendar from '../../components/Infominds/Calendar/hooks/useCalendar'
import appUtils from '../../utils/Utils'

export default function DateView() {
  const { theme } = useTheme()
  const { language } = useLanguage()
  const { date } = useCalendar()

  const day = useMemo(() => format(date, 'EEEE', { locale: appUtils.languageToLocale(language) }), [language, date])
  const formDate = useMemo(() => format(date, 'PPP', { locale: appUtils.languageToLocale(language) }), [language, date])

  return (
    <IM.View style={styles.container} spacing="vertical">
      <IM.View style={styles.date}>
        <IM.Text style={styles.dayText}>{appUtils.capitalizeFirstLetter(day)}</IM.Text>
        <IM.Text style={[styles.dateText, { color: theme.textDetail }]}>{formDate}</IM.Text>
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  date: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  dateText: { paddingLeft: IMStyle.layout.horizontalMargin, fontSize: IMStyle.typography.fontSizeSmall },
  dayText: {
    fontSize: IMStyle.typography.fontSizeRegular + 2,
    fontWeight: IMStyle.typography.fontWeightBold,
  },
  pressable: { backgroundColor: '#34393a' },
})
