import { useTheme } from '@infominds/react-native-components'

import { PresenceTimeKeyType } from '../apis/types/apiResponseTypes'
import { ThemeColorExpanded } from '../types'

export default function useCalendarDayColor() {
  const { theme } = useTheme<ThemeColorExpanded>()

  function getDayColorByKeyType(keyType: PresenceTimeKeyType | null | undefined) {
    if (!keyType) return theme.presenceTime.calendarDay.missing

    switch (keyType) {
      case PresenceTimeKeyType.Vacation:
        return theme.presenceTime.calendarDay.vacation
      case PresenceTimeKeyType.TimeCompensation:
        return theme.info
      default:
        return theme.presenceTime.calendarDay.ok
    }
  }

  return { getDayColorByKeyType }
}
