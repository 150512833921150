import { useEffect, useState } from 'react'

export default function useTimer(intervalS?: number) {
  const [time, setTime] = useState(0)
  const [running, setRunning] = useState(false)

  useEffect(() => {
    if (!running) return

    let interval = intervalS || 1
    //setInterval may behave incorrectly if used in a remote debugger (f.ex chrome. caused by system time differences). With a higher interval the behavior is almost correct
    if (global.location && global.location.pathname.includes('/debugger-ui')) {
      interval = 5
    }

    const intervalId = setInterval(() => {
      setTime(prev => prev + interval)
    }, 1000 * interval)

    return () => clearInterval(intervalId)
  }, [running])

  function reset() {
    setTime(0)
    setRunning(false)
  }

  return { time, setTime, start: () => setRunning(true), stop: () => setRunning(false), reset, isRunning: running }
}
