import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome'
import { IM, IMStyle, useTheme } from '@infominds/react-native-components'
import Color from 'color'
import React from 'react'
import { Pressable, PressableProps, StyleProp, StyleSheet, ViewStyle } from 'react-native'

type Props = {
  icon: IconProp
  size?: number
  color?: string
  shadow?: boolean
  style?: StyleProp<ViewStyle>
  pressableStyle?: StyleProp<ViewStyle>
  opacityColor?: string
  iconStyle?: FontAwesomeIconStyle
}

export type PressableIconProps = PressableProps & Props

export default function PressableIcon({
  icon,
  size = 30,
  color,
  shadow,
  style,
  pressableStyle,
  iconStyle,
  opacityColor,
  ...pressableProps
}: PressableIconProps) {
  const { theme } = useTheme()

  return (
    <IM.View
      style={[
        styles.container,
        {
          height: size * 1.75,
          width: size * 1.75,
          borderRadius: IMStyle.layout.iconRadius,
        },
        shadow && IMStyle.layout.shadow,
        style,
      ]}>
      <Pressable
        style={({ pressed }) => [
          styles.pressable,
          {
            backgroundColor: pressed && !pressableProps.android_ripple ? opacityColor ?? theme.button.pressedOpacity : 'transparent',
          },
          pressableStyle,
        ]}
        {...pressableProps}>
        <IM.Icon
          size={size}
          icon={icon}
          color={pressableProps.disabled ? Color(theme.textDetail).darken(0.2).toString() : color}
          iconStyle={iconStyle}
        />
      </Pressable>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
  },
  pressable: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  details: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
