import { useInfomindsAnalyticsNavigation } from '@infominds/react-native-analytics'
import { IM, TabNavigationScreen, useLanguage } from '@infominds/react-native-components'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackNavigationOptions } from '@react-navigation/native-stack'
import React, { useEffect } from 'react'
import { Platform } from 'react-native'
import Orientation from 'react-native-orientation-locker'

import type { Document, EmployeeTimeType, File, Folder } from '../apis/types/apiResponseTypes'
import { EmployeeTimeContextProvider } from '../contexts/EmployeeTimeContext'
import { HistoryContextProvider } from '../contexts/HistoryContext'
import { PresenceTimeContextProvider } from '../contexts/PresenceTimeContext'
import useIsEmployeeTimeEnabled from '../hooks/useIsEmployeeTimeEnabled'
import useIsPresenceTimeEnabled from '../hooks/useIsPresenceTimeEnabled'
import useLayout from '../hooks/useLayout'
import HistoryScreen from '../screens/History/HistoryScreen'
import HomeScreen from '../screens/Home/HomeScreen'
import NewTimeScreen from '../screens/Home/NewTimeScreen'
import InfoboxFolderScreen from '../screens/InfoBox/InfoboxFolderScreen'
import InfoboxMediaScreen from '../screens/InfoBox/InfoboxMediaScreen'
import InfoBoxScreen from '../screens/InfoBox/InfoBoxScreen'
import AppLoginScreen from '../screens/Login/LoginScreen'
import PresenceTimeScreen from '../screens/PresenceTime/PresenceTimeScreen'
import SettingsScreen from '../screens/Settings/SettingsScreen'

export type RootStackParamList = {
  Login: undefined
  Root: undefined
  HomeStack: undefined
  PresenceTimeStack: undefined
  HistoryStack: undefined
  InfoBoxStack: undefined
  Home: undefined
  PresenceTime: undefined
  NewTime: { activity?: EmployeeTimeType; document?: Document } | undefined
  History: undefined
  InfoBox: { document?: Document }
  InfoboxFolder: { document?: Document; navigationSource?: string }
  InfoboxMedia: { document: Document; folder: Folder; files: File[] }
  SettingsStack: undefined
  Settings: undefined
}

const screenOptions: NativeStackNavigationOptions = {
  headerShown: false,
}

const Stack = createNativeStackNavigator<RootStackParamList>()

export default function Navigator() {
  const { i18n } = useLanguage()
  const { isSmallDevice } = useLayout()
  const isEmployeeTimeEnabled = useIsEmployeeTimeEnabled()
  const isPresenceTimeEnabled = useIsPresenceTimeEnabled()

  const bottomTabs: TabNavigationScreen[] = [
    {
      name: 'HomeStack',
      component: HomeStackNavigator,
      icon: ['fal', 'user-clock'],
      title: i18n.t('TAB_EMPLOYEE_TIMES'),
      isVisible: () => isEmployeeTimeEnabled,
    },
    {
      name: 'PresenceTimeStack',
      component: PresenceTimeStackNavigator,
      icon: ['fal', 'business-time'],
      title: i18n.t('TAB_PRESENCE_TIMES'),
      isVisible: () => isPresenceTimeEnabled,
    },
    { name: 'HistoryStack', component: HistoryStackNavigator, icon: ['fal', 'calendars'], title: i18n.t('TAB_HISTORY') },
    {
      name: 'InfoBoxStack',
      component: InfoBoxStackNavigator,
      icon: ['fal', 'photo-film-music'],
      title: i18n.t('TAB_INFOBOX'),
      isVisible: () => isEmployeeTimeEnabled,
    },
    {
      name: 'SettingsStack',
      component: SettingsStackNavigator,
      icon: ['fal', 'cog'],
      title: i18n.t('SETTINGS'),
    },
  ]

  useEffect(() => {
    isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios') && Orientation.lockToPortrait()
  }, [isSmallDevice])

  const { navigationRef, onReady, onStateChange } = useInfomindsAnalyticsNavigation()

  return (
    <NavigationContainer
      ref={navigationRef}
      onReady={onReady}
      onStateChange={onStateChange}
      linking={{
        enabled: false,
        prefixes: [],
      }}>
      <Stack.Navigator initialRouteName="Login" screenOptions={screenOptions}>
        <Stack.Screen name="Login" component={AppLoginScreen} />
        <Stack.Screen name="Root" component={IM.TabNavigator(bottomTabs, 'Home', { lazy: false })} />
      </Stack.Navigator>
    </NavigationContainer>
  )
}

const HomeStack = createNativeStackNavigator<RootStackParamList>()
function HomeStackNavigator() {
  return (
    <EmployeeTimeContextProvider>
      <HomeStack.Navigator screenOptions={screenOptions}>
        <HomeStack.Screen name="Home" component={HomeScreen} />
        <HomeStack.Screen name="NewTime" component={NewTimeScreen} />
      </HomeStack.Navigator>
    </EmployeeTimeContextProvider>
  )
}

const PresenceTimeStack = createNativeStackNavigator<RootStackParamList>()
function PresenceTimeStackNavigator() {
  return (
    <PresenceTimeContextProvider>
      <PresenceTimeStack.Navigator screenOptions={screenOptions}>
        <PresenceTimeStack.Screen name="PresenceTime" component={PresenceTimeScreen} />
      </PresenceTimeStack.Navigator>
    </PresenceTimeContextProvider>
  )
}

const HistoryStack = createNativeStackNavigator<RootStackParamList>()
function HistoryStackNavigator() {
  return (
    <HistoryContextProvider>
      <HistoryStack.Navigator screenOptions={screenOptions}>
        <HistoryStack.Screen name="History" component={HistoryScreen} />
      </HistoryStack.Navigator>
    </HistoryContextProvider>
  )
}

const InfoBoxStack = createNativeStackNavigator<RootStackParamList>()
function InfoBoxStackNavigator() {
  return (
    <InfoBoxStack.Navigator screenOptions={screenOptions}>
      <InfoBoxStack.Screen name="InfoBox" component={InfoBoxScreen} />
      <InfoBoxStack.Screen name="InfoboxFolder" component={InfoboxFolderScreen} />
      <InfoBoxStack.Screen name="InfoboxMedia" component={InfoboxMediaScreen} />
    </InfoBoxStack.Navigator>
  )
}

const SettingsStack = createNativeStackNavigator<RootStackParamList>()
function SettingsStackNavigator() {
  return (
    <SettingsStack.Navigator screenOptions={screenOptions}>
      <SettingsStack.Screen name="Settings" component={SettingsScreen} />
    </SettingsStack.Navigator>
  )
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    //eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface RootParamList extends RootStackParamList {}
  }
}
