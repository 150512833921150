import { useDimensions } from '@infominds/react-native-components'

export default function useLayout(forceLayout?: false | 'small' | 'medium' | 'large'): {
  isSmallDevice: boolean
  isLargeDevice: boolean
} {
  const { isSmallDevice } = useDimensions()

  if (forceLayout) {
    return {
      isSmallDevice: forceLayout === 'small',
      isLargeDevice: forceLayout === 'large',
    }
  }

  return {
    isSmallDevice,
    isLargeDevice: !isSmallDevice,
  }
}
