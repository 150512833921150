import { IM, IMLayout, IMStyle, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { FlatList } from 'react-native'

import MoreCard from '../../cards/MoreCard'
import AdditionalRepaymentKeyCard from '../../cards/PresenceTime/AdditionalRepaymentKeyCard'
import PresenceTimeKeyCard from '../../cards/PresenceTime/PresenceTimeKeyCard'
import IMRefreshControl from '../../components/IMRefreshControl'
import NoEntry from '../../components/NoEntry'
import PresenceTimeTabs, { PresenceTimeTabItem } from '../../components/PresenceTimeTabs'
import Separator from '../../components/Separator'
import { usePresenceTime } from '../../contexts/PresenceTimeContext'
import useLayout from '../../hooks/useLayout'
import PresenceTimeInfoView from './PresenceTimeInfoView'

type PresenceTimeViewProps = {
  forceSmallLayout?: boolean
  showAdditionalRepaymentsTab?: boolean
}

enum DisplayListType {
  PRESENCE_TIMES,
  ADDITIONAL_REPAYMENTS,
}

export default function PresenceTimeView({ forceSmallLayout, showAdditionalRepaymentsTab }: PresenceTimeViewProps) {
  const { i18n } = useLanguage()
  const presenceTime = usePresenceTime()
  const { isSmallDevice, isLargeDevice } = useLayout(forceSmallLayout && 'small')
  const [showUnOrderedState, setShowUnOrderedState] = useState(false)
  const [displayList, setDisplayList] = useState<DisplayListType>(DisplayListType.PRESENCE_TIMES)

  useEffect(() => {
    if (showAdditionalRepaymentsTab) setDisplayList(DisplayListType.ADDITIONAL_REPAYMENTS)
  }, [showAdditionalRepaymentsTab])

  const presenceTimeTabItems: PresenceTimeTabItem[] = [
    {
      name: i18n.t('TAB_PRESENCE_TIMES'),
      onClick: () => setDisplayList(DisplayListType.PRESENCE_TIMES),
    },
    {
      name:
        presenceTime.numActiveAdditionalRepayments > 0
          ? `${i18n.t('TAB_ADDITIONAL_REPAYMENTS')} (${presenceTime.numActiveAdditionalRepayments})`
          : i18n.t('TAB_ADDITIONAL_REPAYMENTS'),
      onClick: () => setDisplayList(DisplayListType.ADDITIONAL_REPAYMENTS),
    },
  ]
  const noOrderExists = useMemo(() => !presenceTime.presenceTimeEntries.find(item => item.key.order), [presenceTime.presenceTimeEntries])
  const timesToShow = useMemo(
    () =>
      presenceTime.presenceTimeEntries.filter(
        time =>
          presenceTime.activeTime?.time.presenceTimeTypeId === time.key.id ||
          !!showUnOrderedState ||
          noOrderExists ||
          time.key.order ||
          !!time.times.find(timeTime => !!timeTime.duration)
      ),
    [presenceTime.presenceTimeEntries, presenceTime.activeTime, showUnOrderedState]
  )

  const additionalRepaymentsToShow = useMemo(() => presenceTime.additionalRepaymentEntries, [presenceTime.additionalRepaymentEntries])

  const showMoreButton = useMemo(
    () => !showUnOrderedState && !!presenceTime.presenceTimeEntries.length && timesToShow.length !== presenceTime.presenceTimeEntries.length,
    [showUnOrderedState, presenceTime.presenceTimeEntries]
  )

  return (
    <IM.View style={IMStyle.layout.flex.f1}>
      <IM.View style={[IMLayout.flex.row, IMLayout.flex.f1]}>
        {isLargeDevice && (
          <>
            <IM.View style={IMLayout.flex.f3}>
              <PresenceTimeInfoView />
            </IM.View>
            <Separator type="vertical" />
          </>
        )}
        <IM.View style={[IMLayout.flex.f6]}>
          <IM.View>
            {isSmallDevice && <PresenceTimeInfoView forceSmallLayout={forceSmallLayout} />}
            <PresenceTimeTabs items={presenceTimeTabItems} startSelectedIndex={showAdditionalRepaymentsTab ? 1 : undefined} />
          </IM.View>
          {displayList === DisplayListType.PRESENCE_TIMES ? (
            <FlatList
              data={timesToShow}
              keyExtractor={item => item.key.id}
              ListFooterComponent={
                <IM.View>
                  {showMoreButton && <MoreCard spacing={['horizontal', 'bottom']} onPress={() => setShowUnOrderedState(true)} />}
                  {!presenceTime.loading && !presenceTime.presenceTimeEntries.length && <NoEntry description={i18n.t('NO_PRESENCE_TIME_KEYS')} />}
                </IM.View>
              }
              renderItem={({ item }) => <PresenceTimeKeyCard presenceTimeKey={item} spacing={['horizontal', 'bottom']} />}
              refreshControl={
                <IMRefreshControl
                  refreshing={presenceTime.loading}
                  onRefresh={() => {
                    presenceTime.load().catch(console.error)
                  }}
                />
              }
            />
          ) : (
            <FlatList
              data={additionalRepaymentsToShow}
              keyExtractor={item => item.key.id}
              ListFooterComponent={
                <IM.View>
                  {!presenceTime.loading && !presenceTime.additionalRepaymentEntries.length && (
                    <NoEntry description={i18n.t('NO_ADDITIONAL_REPAYMENT_KEYS')} />
                  )}
                </IM.View>
              }
              renderItem={({ item }) => <AdditionalRepaymentKeyCard additionalRepayment={item} spacing={['horizontal', 'bottom']} />}
              refreshControl={
                <IMRefreshControl
                  refreshing={presenceTime.loading}
                  onRefresh={() => {
                    presenceTime.load().catch(console.error)
                  }}
                />
              }
            />
          )}
        </IM.View>
      </IM.View>
    </IM.View>
  )
}
