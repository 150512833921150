import { IM, useLanguage, useModalController } from '@infominds/react-native-components'
import React from 'react'

import type { EmployeeTimeType } from '../../apis/types/apiResponseTypes'
import ActivityCard from '../../cards/ActivityCard'
import LoadingIndicatorCard from '../../cards/LoadingIndicatorCard'
import MoreCard from '../../cards/MoreCard'
import IMContentCard from '../../components/Card/IMContentCard'
import { useActivities } from '../../contexts/ActivitiesContext'
import ActivitySelectionModal from '../../modals/ActivitySelectionModal'

export interface ActivitySelectionViewProps {
  disabled?: boolean
  selectedActivity: EmployeeTimeType | undefined
  setSelectedActivity?: React.Dispatch<React.SetStateAction<EmployeeTimeType | undefined>>
  lastUsedItemCount?: number
  error?: boolean
}

export default function ActivitySelectionView({
  disabled,
  selectedActivity,
  setSelectedActivity,
  lastUsedItemCount = 1e10,
  error,
}: ActivitySelectionViewProps) {
  const { i18n } = useLanguage()
  const { activities } = useActivities()
  const controller = useModalController<EmployeeTimeType[]>()

  const enabled = !disabled && !!setSelectedActivity

  return (
    <IMContentCard title={i18n.t('Activity')} spacing="all" error={error}>
      <IM.View spacing={['horizontal', 'top']}>
        {!!selectedActivity && (
          <ActivityCard
            activity={selectedActivity}
            onPress={enabled ? () => setSelectedActivity(undefined) : undefined}
            selected
            editable={!disabled}
            spacing="bottom"
          />
        )}
        {!selectedActivity && enabled && (
          <>
            {activities?.length === 0 && <LoadingIndicatorCard />}

            {activities?.length === 0 && (
              <IM.View spacing="bottom">
                <IM.Text>{i18n.t('NO_ENTRIES')}</IM.Text>
              </IM.View>
            )}
            {activities
              ?.slice(0, lastUsedItemCount)
              .map(item => (
                <ActivityCard spacing="bottom" key={item.code} activity={item} onPress={enabled ? () => setSelectedActivity(item) : undefined} />
              ))}
            {!!activities && activities?.length > lastUsedItemCount && !disabled && (
              <MoreCard spacing="bottom" onPress={() => !controller.isShown && controller.show(activities ?? [])} />
            )}
          </>
        )}
      </IM.View>
      {enabled && <ActivitySelectionModal controller={controller} onSelected={setSelectedActivity} />}
    </IMContentCard>
  )
}
