import { IM, IMLayout, IMStyle, useTheme } from '@infominds/react-native-components'
import { Profile, useAuthentication } from '@infominds/react-native-license'
import React, { useEffect, useState } from 'react'
import { Image, StyleSheet } from 'react-native'

import SkeletonText from '../components/Infominds/Skeleton/SkeletonText'

export default function AADUserCard() {
  const { theme } = useTheme()
  const { aadAuth, getProfile } = useAuthentication()

  const [profile, setProfile] = useState<Profile | undefined>(undefined)
  const [aadLoading, setAadLoading] = useState(true)

  const isAad = aadAuth && aadAuth.state === 'success'

  useEffect(() => {
    if (isAad) {
      getProfile?.()
        .then(val => {
          setProfile(val)
          setAadLoading(false)
        })
        .catch(console.error)
    }
  }, [isAad])

  if (!isAad) return <></>

  const name = profile?.givenName
  const surname = profile?.surname
  const loading = aadLoading

  return (
    <IM.Card style={[styles.card, { backgroundColor: theme.input.border }]} borderless>
      {loading === false && name && surname ? (
        <IM.View style={[IMLayout.flex.row, styles.employeeBadgeContainer]}>
          {/* eslint-disable-next-line react-native/no-inline-styles */}
          <Image source={{ uri: profile.base64Picture }} style={{ width: 80, aspectRatio: 1, borderRadius: 50 }} />
          {/* eslint-disable-next-line react-native/no-inline-styles */}
          <IM.View style={{ marginLeft: 12 }}>
            <IM.Text style={{ fontSize: IMStyle.typography.fontSizeRegular }} allowFontScaling={false}>
              {name}
            </IM.Text>
            <IM.Text style={{ fontSize: IMStyle.typography.fontSizeRegular, fontWeight: IMStyle.typography.fontWeightBold }} allowFontScaling={false}>
              {surname}
            </IM.Text>
          </IM.View>
        </IM.View>
      ) : (
        <IM.View style={styles.skeleton}>
          <IM.View spacing="top">
            <SkeletonText />
          </IM.View>
          <IM.View spacing="vertical">
            <SkeletonText />
          </IM.View>
          <IM.View spacing="bottom">
            <SkeletonText />
          </IM.View>
        </IM.View>
      )}
    </IM.Card>
  )
}

const styles = StyleSheet.create({
  card: { marginTop: 12, padding: 0, borderRadius: 25 },
  employeeBadgeContainer: { alignItems: 'center' },
  row: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
  skeleton: {
    paddingTop: 2,
    paddingBottom: 1,
  },
})
