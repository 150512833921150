import { IM, IMStyle, ModalController, useTheme } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import Color from 'color'
import React from 'react'
import { StyleSheet } from 'react-native'

import useLayout from '../hooks/useLayout'
import type { NewTimeModalData } from '../modals/NewTimeModal'

export type NewTimeButtonProps = {
  visible: boolean
  controller: ModalController<NewTimeModalData>
}

export default function NewTimeButton({ visible, controller }: NewTimeButtonProps) {
  const navigation = useNavigation()
  const { isSmallDevice } = useLayout()
  const { theme } = useTheme()

  if (!visible) return <></>

  return (
    <IM.PressableIcon
      icon={['fal', 'plus']}
      size={30}
      onPress={() => (isSmallDevice ? navigation.navigate('NewTime') : controller.show({}))}
      color={IMStyle.palette.white}
      opacityColor={Color(theme.button.pressedOpacity).alpha(0.5).toString()}
      style={[styles.button, { backgroundColor: theme.card.headBackground }]}
    />
  )
}

const styles = StyleSheet.create({
  button: {
    position: 'absolute',
    bottom: 9,
    right: 9,
    zIndex: 10,
  },
  badge: {
    borderRadius: 100,
  },
})
