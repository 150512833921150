import { ModalController } from '@infominds/react-native-components'
import React, { useRef } from 'react'
import type Toast from 'react-native-toast-notifications'

import Modal from '../components/Infominds/Modal'
import { ScreenHeader } from '../components/ScreenHeader'
import { PresenceTimeContextProvider } from '../contexts/PresenceTimeContext'
import { ToastReferenceContextProvider } from '../contexts/ToastReferenceContext'
import PresenceTimeHeader from '../screens/PresenceTime/PresenceTimeHeader'
import CreatePresenceTimeModalView from './CreatePresenceTimeModalView'

type CreatePresenceTimeModalProps = {
  controller: ModalController
  onClose: () => void
  date: Date
  calendarMode?: boolean
  showAdditionalRepaymentsTab?: boolean
}

export default function CreatePresenceTimeModal({
  controller,
  onClose,
  date,
  calendarMode,
  showAdditionalRepaymentsTab,
}: CreatePresenceTimeModalProps) {
  const toastRef = useRef<Toast | undefined>(undefined)

  function close() {
    onClose()
    controller.close()
  }

  if (calendarMode && !controller.isShown) return <></>

  return (
    <PresenceTimeContextProvider editDate={date}>
      <Modal
        statusBarTranslucent
        isVisible={controller.isShown}
        onClose={close}
        toastRef={toastRef}
        screenHeader={<ScreenHeader goBack={close} barContent={<PresenceTimeHeader mode="history" />} />}>
        <ToastReferenceContextProvider toastRef={toastRef.current}>
          <CreatePresenceTimeModalView showAdditionalRepaymentsTab={showAdditionalRepaymentsTab} />
        </ToastReferenceContextProvider>
      </Modal>
    </PresenceTimeContextProvider>
  )
}
