import { useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { RefreshControl, RefreshControlProps } from 'react-native'

export default function IMRefreshControl({ refreshing, onRefresh, title, tintColor, titleColor, ...props }: RefreshControlProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()
  const [showRefreshing, setShowRefreshing] = useState(false)

  useEffect(() => {
    if (!refreshing) setShowRefreshing(false)
  }, [refreshing])

  function handleOnRefresh() {
    if (!onRefresh) return
    setShowRefreshing(true)
    onRefresh()
  }

  return (
    <RefreshControl
      {...props}
      refreshing={showRefreshing}
      onRefresh={handleOnRefresh}
      title={title ?? i18n.t('PULL_TO_REFRESH')}
      tintColor={tintColor ?? theme.text}
      titleColor={titleColor ?? theme.text}
    />
  )
}
