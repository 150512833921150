import { ModalController, useLanguage } from '@infominds/react-native-components'
import { RouteProp, useFocusEffect, useNavigation, useRoute } from '@react-navigation/native'
import React, { useCallback, useMemo, useState } from 'react'

import type { Document, EmployeeTimeType } from '../../apis/types/apiResponseTypes'
import KeyboardAvoidingScrollView from '../../components/Infominds/KeyboardAvoidingScrollView'
import Screen from '../../components/Infominds/Screen'
import { ScreenHeader } from '../../components/ScreenHeader'
import TimeHandlerButton from '../../components/TimeHandlerButton'
import { useEmployeeTime } from '../../contexts/EmployeeTimeContext'
import useLayout from '../../hooks/useLayout'
import useNewTimeHandler from '../../hooks/useNewTimeHandler'
import useUserSettings from '../../hooks/useUserSettings'
import type { NewTimeModalData } from '../../modals/NewTimeModal'
import type { RootStackParamList } from '../../navigation/Navigator'
import DateUtils from '../../utils/DateUtils'
import CurrentTimeView from '../../views/NewTime/CurrentTimeView'
import TimeSelectionView from '../../views/NewTime/TimeSelectionView'

type Props = {
  onGoBack?: () => void
  activity?: EmployeeTimeType
  document?: Document
  newTimeController?: ModalController<NewTimeModalData>
}

export default function NewTimeScreen({ activity, document, onGoBack, newTimeController }: Props) {
  const { language } = useLanguage()
  const navigation = useNavigation()
  const { isSmallDevice } = useLayout()
  const userSettings = useUserSettings()

  let route = useRoute<RouteProp<RootStackParamList, 'NewTime'>>().params

  if (!isSmallDevice) {
    route = { activity: activity, document: document }
  }

  const employeeTime = useEmployeeTime()
  const handler = useNewTimeHandler({
    presetActivity: route?.activity,
    presetDocument: route?.document,
    activeTime: employeeTime.currentTime,
    presetEmployees: employeeTime.lastTime?.subordinates,
  })

  const [showLastTimeId, setShowLastTimeId] = useState<string | undefined>(employeeTime.currentTime?.id)

  useFocusEffect(
    useCallback(() => {
      employeeTime.getCurrentTime().catch(console.error)
    }, [])
  )

  const showTime = useMemo(() => {
    if (employeeTime.isTimeActive) return employeeTime.currentTime
    if (!showLastTimeId) return undefined
    return employeeTime.todaysTimes.find(time => time.id === showLastTimeId)
  }, [employeeTime.currentTime, employeeTime.todaysTimes])

  //if time is active send new note to api
  async function handleNoteChanged(note: string) {
    if (!employeeTime.currentTime || note === employeeTime.currentTime?.note || (!note && !employeeTime.currentTime?.note)) {
      return
    }
    return employeeTime.updateCurrentTime(handler.note)
  }

  return (
    <Screen
      hideBackgroundImage={!isSmallDevice}
      screenHeader={
        <ScreenHeader
          goBack={() => {
            isSmallDevice ? navigation.goBack() : onGoBack && onGoBack()
          }}
          title={DateUtils.formatDate(new Date(), 'dd MMMM yyyy', language)}
        />
      }>
      <KeyboardAvoidingScrollView>
        <TimeSelectionView
          handler={handler}
          activeTime={employeeTime.currentTime}
          onNoteChanged={handleNoteChanged}
          newTimeController={newTimeController}
          creationMode={!userSettings?.isEmployeeTimeOnlineRecordingActive}
          isCreationAlwaysAllowed
        />
      </KeyboardAvoidingScrollView>

      {!!showTime && <CurrentTimeView time={showTime} />}
      <TimeHandlerButton
        handler={handler}
        spacing="all"
        postStart={setShowLastTimeId}
        postStop={() => navigation.goBack()}
        creationMode={!userSettings?.isEmployeeTimeOnlineRecordingActive}
        postCreated={() => navigation.goBack()}
      />
    </Screen>
  )
}
