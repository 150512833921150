import { IM, IMLayout } from '@infominds/react-native-components'
import React from 'react'

import ErrorDisplay from '../components/ErrorDIsplay'
import { usePresenceTime } from '../contexts/PresenceTimeContext'
import PresenceTimeLoadingView from '../views/PresenceTimes/PresenceTimeLoadingView'
import PresenceTimeView from '../views/PresenceTimes/PresenceTimeView'

export default function CreatePresenceTimeModalView({ showAdditionalRepaymentsTab }: { showAdditionalRepaymentsTab?: boolean }) {
  const presenceTime = usePresenceTime()

  return (
    <IM.View style={IMLayout.flex.f1}>
      {!!presenceTime.loading && !presenceTime.presenceTimeEntries.length && <PresenceTimeLoadingView forceSmallLayout />}
      {!presenceTime.loading && <ErrorDisplay error={presenceTime.error} spacing={'all'} />}
      {!!presenceTime.presenceTimeInfo && <PresenceTimeView forceSmallLayout showAdditionalRepaymentsTab={showAdditionalRepaymentsTab} />}
    </IM.View>
  )
}
