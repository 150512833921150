import { IM, useLanguage } from '@infominds/react-native-components'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { createRef, useEffect } from 'react'
import { BackHandler } from 'react-native'
import { useRecoilState, useRecoilValue } from 'recoil'

import { ScreenHeader } from '../../components/ScreenHeader'
import type { RootStackParamList } from '../../navigation/Navigator'
import { assetsToSyncAtom, mediaSortingMethodInfoboxAtom } from '../../utils/stateManager'
import appUtils from '../../utils/Utils'
import InfoboxMediaView, { InfoboxMediaViewImperativeMethods } from '../../views/InfoBox/InfoboxMediaView'

const InfoboxMediaScreen = () => {
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const mediaRef = createRef<InfoboxMediaViewImperativeMethods>()
  const mediaAbortController = new AbortController()

  const { document, folder, files } = useRoute<RouteProp<RootStackParamList, 'InfoboxMedia'>>().params

  const assetToSync = useRecoilValue(assetsToSyncAtom)
  const [displayMethod, setDisplayMethod] = useRecoilState(mediaSortingMethodInfoboxAtom)

  useEffect(() => {
    const subscription = BackHandler.addEventListener('hardwareBackPress', () => {
      customGoBack()
      return true
    })

    return () => subscription.remove()
  }, [assetToSync])

  function handlePress() {
    if (assetToSync > 0) {
      mediaRef.current?.handleUpload()
    } else {
      setDisplayMethod(appUtils.getMediaDisplayMethod(displayMethod))
    }
  }

  function customGoBack() {
    if (assetToSync > 0) {
      mediaRef.current?.mediaAlert()
    } else {
      navigation.goBack()
    }
  }

  return (
    <IM.Screen
      spacing="bottom"
      screenHeader={
        <ScreenHeader
          goBack={customGoBack}
          title={document.code}
          subTitle={{
            text: folder.description ?? i18n.t('FOLDER_WITHOUT_NAME'),
            icon: ['fal', 'folder-open'],
          }}
          icon={assetToSync > 0 ? ['fal', 'check'] : ['fal', displayMethod === 'twice' ? 'list' : displayMethod === 'details' ? 'square' : 'grid-2']}
          onIconPress={handlePress}
        />
      }>
      <InfoboxMediaView ref={mediaRef} document={document} files={files} folder={folder} abortController={mediaAbortController} />
    </IM.Screen>
  )
}

export default InfoboxMediaScreen
