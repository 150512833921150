import { IM, SpacingProps, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import type { LastUsedTimeData } from '../types'
import { CalculateInitials } from '../utils/Utils'

interface LastUsedTimesCardProps {
  spacing?: SpacingProps
  data: LastUsedTimeData | undefined
  onPress?: () => void
}

export default function LastUsedTimesCard({ spacing, data, onPress }: LastUsedTimesCardProps) {
  const { themeUtils } = useTheme()

  const secondaryText = data?.document?.code || data?.activity.description
  const code = data?.activity.code ?? ''
  const headText = useMemo(() => data?.activity.abbreviation?.toUpperCase() ?? CalculateInitials(code), [code])
  const headBackground = useMemo(() => themeUtils.getRandomColorFromTheme(code), [code])

  if (!data) return <></>
  return (
    <IM.Card
      spacing={spacing}
      head={{ text: headText, backGroundColor: headBackground }}
      defaultContent={{
        texts: [{ text: data.activity.code, primary: true }, !!secondaryText && { text: secondaryText, secondary: true }],
      }}
      onPress={onPress}
    />
  )
}
