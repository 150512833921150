import { library } from '@fortawesome/fontawesome-svg-core'
// WARNING: Import fontAwesome icons like these to prevent the build time to increase. https://fontawesome.com/docs/web/use-with/react-native
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown'
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
import { faAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft'
import { faBusinessTime } from '@fortawesome/pro-light-svg-icons/faBusinessTime'
import { faCalendarClock } from '@fortawesome/pro-light-svg-icons/faCalendarClock'
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay'
import { faCalendars } from '@fortawesome/pro-light-svg-icons/faCalendars'
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog'
import { faFileSlash } from '@fortawesome/pro-light-svg-icons/faFileSlash'
import { faFolder } from '@fortawesome/pro-light-svg-icons/faFolder'
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons/faFolderOpen'
import { faGrid2 } from '@fortawesome/pro-light-svg-icons/faGrid2'
import { faHouse } from '@fortawesome/pro-light-svg-icons/faHouse'
import { faList } from '@fortawesome/pro-light-svg-icons/faList'
import { faPause } from '@fortawesome/pro-light-svg-icons/faPause'
import { faPenToSquare } from '@fortawesome/pro-light-svg-icons/faPenToSquare'
import { faPersonToDoor } from '@fortawesome/pro-light-svg-icons/faPersonToDoor'
import { faPhotoFilmMusic } from '@fortawesome/pro-light-svg-icons/faPhotoFilmMusic'
import { faPlay } from '@fortawesome/pro-light-svg-icons/faPlay'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faQrcode } from '@fortawesome/pro-light-svg-icons/faQrcode'
import { faSave } from '@fortawesome/pro-light-svg-icons/faSave'
import { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare'
import { faStop } from '@fortawesome/pro-light-svg-icons/faStop'
import { faStopwatch } from '@fortawesome/pro-light-svg-icons/faStopwatch'
import { faTimer } from '@fortawesome/pro-light-svg-icons/faTimer'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash'
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons/faTriangleExclamation'
import { faUserClock } from '@fortawesome/pro-light-svg-icons/faUserClock'
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers'
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark'
import { faCheck as faRegularCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faTimes as faRegularTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faBooks } from '@fortawesome/pro-solid-svg-icons/faBooks'
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck'
import { faCog as faCogSolid } from '@fortawesome/pro-solid-svg-icons/faCog'
import { faFileInvoice } from '@fortawesome/pro-solid-svg-icons/faFileInvoice'
import { faGears } from '@fortawesome/pro-solid-svg-icons/faGears'
import { faPlayCircle } from '@fortawesome/pro-solid-svg-icons/faPlayCircle'
import { faStopCircle } from '@fortawesome/pro-solid-svg-icons/faStopCircle'
import { DefaultTheme, InfomindsColors, ThemeType } from '@infominds/react-native-components'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { ThemeColorExpanded } from './types'
import { infoboxFilterPreferenceAtom, mediaSortingMethodInfoboxAtom } from './utils/stateManager'

library.add(
  faCog,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faCalendarDay,
  faCalendars,
  faCircleCheck,
  faStopwatch,
  faHouse,
  faPhotoFilmMusic,
  faCheck,
  faPersonToDoor,
  faXmark,
  faStopwatch,
  faFolder,
  faFolderOpen,
  faSquare,
  faList,
  faGrid2,
  faQrcode,
  faTimes,
  faPlay,
  faPlayCircle,
  faPause,
  faStop,
  faStopCircle,
  faPenToSquare,
  faPlus,
  faCircle,
  faAngleDown,
  faAngleUp,
  faCircleCheck,
  faSave,
  faTrash,
  faFileSlash,
  faTriangleExclamation,
  faBooks,
  faFileInvoice,
  faGears,
  faUsers,
  faRegularCheck,
  faRegularTimes,
  faTimer,
  faUserClock,
  faBusinessTime,
  faCogSolid,
  faCalendarClock
)

export const themeExpansion: ThemeType<ThemeColorExpanded> = {
  light: {
    ...DefaultTheme.light,
    presenceTime: {
      calendarDay: {
        missing: '#D5D5D5',
        ok: '#0FCEAD',
        vacation: '#579AFD',
        timeCompensation: InfomindsColors.yellow,
      },
      calendar: {
        calendarBackground: DefaultTheme.light.background,
        dayTextColor: DefaultTheme.light.text,
        todayTextColor: DefaultTheme.light.primary,
        textDisabledColor: DefaultTheme.light.textDetail,
      },
    },
  },
  dark: {
    ...DefaultTheme.dark,
    presenceTime: {
      calendarDay: {
        missing: '#D5D5D5',
        ok: '#0FCEAD',
        vacation: '#579AFD',
        timeCompensation: InfomindsColors.yellow,
      },
      calendar: {
        calendarBackground: DefaultTheme.dark.background,
        dayTextColor: DefaultTheme.dark.text,
        todayTextColor: DefaultTheme.dark.primary,
        textDisabledColor: DefaultTheme.dark.textDetail,
      },
    },
  },
}

//There are some atoms that at first render gets the last store value fetched from storage.
// This is a workaround to ha the stored value before the screen first render.
export default function InitStructures() {
  useRecoilValue(infoboxFilterPreferenceAtom)
  useRecoilValue(mediaSortingMethodInfoboxAtom)
  return <></>
}
