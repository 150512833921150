import { IM, IMLayout } from '@infominds/react-native-components'
import React from 'react'

type PresenceTimeLoadingViewProps = {
  forceSmallLayout?: boolean
}

// separate view for web, since skeleton-loader cards caused dom issues
export default function PresenceTimeLoadingView(_props: PresenceTimeLoadingViewProps) {
  return (
    <IM.View style={IMLayout.flex.f1} spacing={'all'}>
      <IM.LoadingSpinner isVisible />
    </IM.View>
  )
}
