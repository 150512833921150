import type { AssetDisplayMethod } from '@infominds/react-native-media'
import { atom, atomFamily } from 'recoil'

import type { Document } from '../apis/types/apiResponseTypes'
import type { UploadType } from '../components/UploadModal'
import {
  INFOBOX_FILTER_PREFERENCE_STORAGE_KEY,
  INFOBOX_LAST_USED_STORAGE_KEY,
  MEDIA_SORTING_METHOD_STORAGE_KEY,
  SETTING_SAVE_MEDIA_ON_DEVICE_STORAGE_KEY,
  TIME_LAST_USED_STORAGE_KEY,
} from '../constants/StorageKeys'
import { HistoryDisplayMode } from '../contexts/HistoryContext'
import type { LastUsedTimeData } from '../types'
import appUtils from './Utils'

export const settingOpenAtom = atom({
  key: 'settingOpenAtom',
  default: false,
})

export const assetUploadVisibleAtom = atom({
  key: 'assetUploadVisibleAtom',
  default: false,
})

export const saveMediaOnDeviceAtom = atom({
  key: 'saveMediaOnDeviceAtom',
  default: false,
  effects: [appUtils.localStorageEffect(SETTING_SAVE_MEDIA_ON_DEVICE_STORAGE_KEY)],
})

export const documentCountAtom = atom<number[]>({
  key: 'documentCountAtom',
  default: [],
})

export const historyDisplayMode = atom<HistoryDisplayMode>({
  key: 'historyDisplayMode',
  default: HistoryDisplayMode.Day,
})

export const uploadCountAtom = atom<UploadType>({
  key: 'uploadCountAtom',
  default: { current: 0, total: -1 },
})

export const assetsToSyncAtom = atom({
  key: 'assetsToSyncAtom',
  default: 0,
})

export const fetchedDatesAtom = atom<Date[]>({
  key: 'fetchedDatesAtom',
  default: [],
})

export const infoboxFilterPreferenceAtom = atom({
  key: 'infoboxFilterPreferenceAtom',
  default: '',
  effects: [appUtils.localStorageEffect(INFOBOX_FILTER_PREFERENCE_STORAGE_KEY)],
})

export const lastUsedInfoboxAtom = atomFamily<Document[], string>({
  key: 'lastUsedInfoboxAtomFamily',
  default: [],
  effects: param => [appUtils.localStorageEffect(param + INFOBOX_LAST_USED_STORAGE_KEY)],
})

export const mediaSortingMethodInfoboxAtom = atom<AssetDisplayMethod>({
  key: 'mediaSortingMethodInfoboxAtom',
  default: 'twice',
  effects: [appUtils.localStorageEffect(MEDIA_SORTING_METHOD_STORAGE_KEY)],
})

export const lastUsedTimesAtom = atomFamily<LastUsedTimeData[], string>({
  key: 'lastUsedTimesAtomFamily',
  default: [],
  effects: param => [appUtils.localStorageEffect(param + TIME_LAST_USED_STORAGE_KEY)],
})
