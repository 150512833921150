import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Modal, StyleSheet, useWindowDimensions } from 'react-native'
import { useRecoilValue } from 'recoil'

import { assetUploadVisibleAtom } from '../utils/stateManager'
import AlertButton from './AlertButton'
import UploadModalMessage from './UploadModalMessage'

export type UploadType = {
  total: number
  current: number
}

const UploadModal = () => {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const { width } = useWindowDimensions()
  const visible = useRecoilValue(assetUploadVisibleAtom)

  return (
    <Modal visible={visible} transparent statusBarTranslucent>
      <IM.View style={styles.container}>
        <IM.View style={[styles.content, { backgroundColor: theme.background, width: 0.88 * width }]}>
          <IM.Text style={[styles.title, { fontWeight: IMStyle.typography.fontWeightBold }]}>{i18n.t('UPLOAD')}</IM.Text>
          <IM.View spacing="vertical" style={styles.description}>
            <UploadModalMessage />
            <IM.View spacing="left">
              <IM.LoadingSpinner isVisible size={22} />
            </IM.View>
          </IM.View>
          <IM.View style={styles.button} spacing="top">
            {__DEV__ && (
              <AlertButton
                title="CANCEL"
                color={IMStyle.palette.red}
                onPress={() => {
                  //TODO RT: manage abort controller
                  return
                }}
              />
            )}
          </IM.View>
        </IM.View>
      </IM.View>
    </Modal>
  )
}

export default UploadModal

const styles = StyleSheet.create({
  container: { flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#00000090' },
  content: {
    padding: 20,
    maxWidth: 300,
  },
  description: {
    flexDirection: 'row',
  },
  button: {
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
  },
})
