import { IM, IMLayout, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React from 'react'

import ErrorDisplay from '../../components/ErrorDIsplay'
import useCalendar from '../../components/Infominds/Calendar/hooks/useCalendar'
import SkeletonText from '../../components/Infominds/Skeleton/SkeletonText'
import { useHistory } from '../../contexts/HistoryContext'
import useAllowPresenceTimeEdit from '../../hooks/useAllowPresenceTimeEdit'
import CreatePresenceTimeModal from '../../modals/CreatePresenceTimeModal'

export default function AdditionalRepaymentsSummaryView() {
  const { theme } = useTheme()
  const { date } = useCalendar()
  const { i18n } = useLanguage()
  const createPresenceTimeModal = useModalController()
  const { allowEdit } = useAllowPresenceTimeEdit(date)
  const { additionalRepayments, loading, error, reLoad } = useHistory()

  return (
    <>
      <IM.Card spacing="bottom" disabled={!allowEdit} onPress={() => createPresenceTimeModal.show()}>
        {loading ? (
          <SkeletonText />
        ) : (
          <>
            <IM.View style={IMLayout.flex.row} spacing="bottom">
              <IM.View style={IMLayout.flex.f1}>
                <IM.Text primary>{i18n.t('TAB_ADDITIONAL_REPAYMENTS')}</IM.Text>
              </IM.View>
              {allowEdit && <IM.Icon icon={['fal', 'plus']} size={20} color={theme.icon} />}
            </IM.View>
            {(!additionalRepayments || additionalRepayments?.length === 0) && (
              <>
                {!!error && <ErrorDisplay error={i18n.t('FETCH_ADDITIONAL_REPAYMENTS_ERROR')} />}
                {!error && <IM.Text>{i18n.t('NO_ADDITIONAL_REPAYMENTS')}</IM.Text>}
              </>
            )}

            {!!additionalRepayments?.length && (
              <IM.View>
                {additionalRepayments.map(ap => (
                  <IM.Text key={ap.id}>{`${ap.presenceTimeAdditionalRepaymentKey} - ${ap.presenceTimeAdditionalRepaymentKeyDescription}`}</IM.Text>
                ))}
              </IM.View>
            )}
          </>
        )}
      </IM.Card>
      <CreatePresenceTimeModal controller={createPresenceTimeModal} onClose={reLoad} date={date} showAdditionalRepaymentsTab />
    </>
  )
}
