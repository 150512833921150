import React, { ReactNode, useContext } from 'react'
import Toast, { ToastType, useToast as useToastLib } from 'react-native-toast-notifications'

type ToastReferenceContextType = {
  toast: ToastType | null | undefined
}

const ToastReferenceContext = React.createContext<ToastReferenceContextType | null>(null)

export function ToastReferenceContextProvider({ children, toastRef }: { children: ReactNode; toastRef: Toast | null | undefined }) {
  return <ToastReferenceContext.Provider value={{ toast: toastRef }}>{children}</ToastReferenceContext.Provider>
}

export function useToast() {
  const toastRef = useContext(ToastReferenceContext)
  const toast = useToastLib()
  if (toastRef?.toast) return toastRef.toast
  return toast
}
