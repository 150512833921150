import { IM, useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import cloneDeep from 'lodash/cloneDeep'
import React, { useEffect, useState } from 'react'
import { FlatList, StyleSheet } from 'react-native'
import { useRecoilState } from 'recoil'

import api from '../../apis/apiCalls'
import type { Document, File, Folder } from '../../apis/types/apiResponseTypes'
import IMRefreshControl from '../../components/IMRefreshControl'
import SkeletonCard from '../../components/Infominds/Skeleton/SkeletonCard'
import NoEntry from '../../components/NoEntry'
import { CONSTANTS } from '../../constants/Constants'
import { useToast } from '../../contexts/ToastReferenceContext'
import useIsEmployeeTimeEnabled from '../../hooks/useIsEmployeeTimeEnabled'
import useLayout from '../../hooks/useLayout'
import { documentCountAtom, lastUsedInfoboxAtom } from '../../utils/stateManager'
import appUtils from '../../utils/Utils'

interface Props {
  document: Document | undefined
  abortController: AbortController
  selectedFolder?: Folder
  navigationSource?: string
  onFolderPress?: (folder: Folder, files: File[]) => void
  onResetSelection?: () => void
}

// TODO RT: cancel api calls when component is unmounted
export default function InfoboxFolderView({ document, navigationSource, selectedFolder, abortController, onFolderPress, onResetSelection }: Props) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const toast = useToast()
  const { isSmallDevice } = useLayout()
  const isEmployeeTimeEnabled = useIsEmployeeTimeEnabled()

  const [refresh, setRefresh] = useState(false)
  const [files, setFiles] = useState<File[]>([])
  const [folders, setFolders] = useState<Folder[]>([])
  const [counts, setCounts] = useRecoilState(documentCountAtom)
  const [lastUsed, setLastUsed] = useRecoilState(lastUsedInfoboxAtom(appUtils.generateSessionKey()))

  useEffect(() => {
    if (document) {
      setRefresh(true)
      updateLastUsedDocument(document)
    }

    return () => {
      abortController && abortController.abort()
    }
  }, [document])

  useEffect(() => {
    if (navigationSource) {
      setRefresh(true)
    }
  }, [navigationSource])

  useEffect(() => {
    if (refresh && document) {
      refreshFolders(document)
    }
  }, [refresh, document])

  useEffect(() => {
    if (counts.length === 0 && document) {
      validateDocAndUpdateCount(folders, document)
    }
  }, [counts])

  function updateLastUsedDocument(lastUsedDoc: Document) {
    // Timeout to prevent the user to see the last used update while the modal si appearing on the screen.
    setTimeout(
      () => {
        let clone = cloneDeep(lastUsed)

        const alreadyPresent = clone.find(clonedDoc => clonedDoc.id === lastUsedDoc.id && clonedDoc.pspDetailId === lastUsedDoc.pspDetailId)

        if (alreadyPresent) {
          clone = clone.filter(clonedDoc => !(clonedDoc.id === lastUsedDoc.id && clonedDoc.pspDetailId === lastUsedDoc.pspDetailId))
        }

        if (lastUsed.length >= CONSTANTS.InfoboxMaxSavedLastUsed) {
          clone.shift()
        }

        clone.push(lastUsedDoc)
        setLastUsed(clone)
      },
      isSmallDevice ? 500 : 0
    )
  }

  function refreshFolders(inputDocument: Document) {
    if (!isEmployeeTimeEnabled) return
    setFolders([])
    setFiles([])
    setCounts([])

    api
      .getInfoboxFolders({ infoboxTyp: inputDocument.documentType }, abortController)
      .then(fetchedFolders => {
        setFolders(fetchedFolders)
        validateDocAndUpdateCount(fetchedFolders, inputDocument)
      })
      .catch(err => {
        if (appUtils.isAbortError(err)) {
          return
        }

        console.error(`Failed loading infobox folder for docId ${inputDocument.id}:`, err)
        if (isSmallDevice) {
          navigation.goBack()
        } else {
          onResetSelection && onResetSelection()
        }
        toast.show(Utils.stringValueReplacer(i18n.t('FOLDER_FETCH_ERROR'), inputDocument.documentType), {
          type: 'danger',
        })
      })
      .finally(() => {
        setRefresh(false)
      })
  }

  function validateDocAndUpdateCount(newFolders: Folder[], inputDocument: Document) {
    api
      .getDocuments({ id: inputDocument.id }, abortController)
      .then(val => {
        if (val.length !== 0) {
          updateDocumentCount(newFolders, inputDocument)
        } else {
          if (isSmallDevice) {
            navigation.goBack()
          } else {
            onResetSelection && onResetSelection()
          }
          toast.show(Utils.stringValueReplacer(i18n.t('FILE_FETCH_ERROR'), inputDocument.code), {
            type: 'warning',
          })
          console.error(`Failed loading infobox files for docId ${inputDocument.id}`)
        }
      })
      .catch(err => {
        if (appUtils.isAbortError(err)) {
          return
        }

        console.error(`Failed fetching ${inputDocument.id}`, err)
      })
  }

  function updateDocumentCount(newFolders: Folder[], inputDocument: Document) {
    api
      .getInfoboxFiles({ id: inputDocument.id, infoboxTyp: inputDocument.documentType }, abortController)
      .then(fetchedFiles => {
        setFiles(fetchedFiles)

        const foldersCopy = cloneDeep(newFolders)
        foldersCopy.sort((prev, curr) => {
          return curr.number - prev.number
        })

        if (foldersCopy[0]?.number) {
          const newItem = Array(foldersCopy[0]?.number + 1).fill(0)

          fetchedFiles.forEach(elem => {
            newItem[elem.folderNumber] += 1
          })
          setCounts(newItem)
        }
      })
      .catch(err => {
        if (appUtils.isAbortError(err)) {
          return
        }

        console.error(`Failed loading infobox files for docId ${inputDocument.id}:`, err)
      })
  }

  const handleFolderPress = (doc: Document, folder: Folder, assets: File[]) => {
    if (isSmallDevice) {
      navigation.navigate('InfoboxMedia', { document: doc, folder: folder, files: assets })
    } else {
      onFolderPress && onFolderPress(folder, assets)
    }
  }

  return (
    <>
      <IM.View spacing="top" />
      {document ? (
        <>
          {counts.length === 0 ? (
            <IM.View spacing={['bottom', 'horizontal']}>
              {Array(CONSTANTS.InfoboxSkeletonCard)
                .fill(0)
                .map((_, index) => {
                  return (
                    <IM.View spacing="bottom" key={`SkeletonDataInfobox-${index}`}>
                      <SkeletonCard />
                    </IM.View>
                  )
                })}
            </IM.View>
          ) : (
            <FlatList
              data={folders}
              renderItem={({ item }) => {
                return (
                  <IM.View spacing={['bottom', 'horizontal']}>
                    <IM.Card
                      head={{ backGroundColor: theme.card.headBackground, icon: ['fal', 'folder'] }}
                      defaultContent={{
                        texts: [
                          {
                            text: item.description ?? i18n.t('FOLDER_WITHOUT_NAME'),
                            primary: true,
                          },
                          {
                            text:
                              counts[item.number] !== undefined
                                ? appUtils.manageDocumentsNumber(i18n, counts[item.number])
                                : i18n.t('LOADING_PLACEHOLDER'),
                            secondary: true,
                          },
                        ],
                      }}
                      onPress={() => {
                        handleFolderPress(document, item, files)
                      }}
                      style={!isSmallDevice && selectedFolder?.id === item.id && [styles.selected, { borderColor: theme.primary }]}
                    />
                  </IM.View>
                )
              }}
              refreshControl={<IMRefreshControl refreshing={false} onRefresh={() => setRefresh(true)} />}
            />
          )}
        </>
      ) : (
        <NoEntry description={i18n.t('NO_DOCUMENT_SELECTED')} />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  selected: {
    borderRightWidth: 4,
  },
})
