import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMStyle, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

interface Props {
  description: string
  icon?: IconProp
}

export default function NoEntry({ description, icon }: Props) {
  const { theme } = useTheme()

  return (
    <IM.View spacing="all" style={styles.noDocument}>
      <IM.Icon icon={icon ?? ['fal', 'file-slash']} color={theme.textDetail} size={26} />
      <IM.Text secondary style={{ fontSize: IMStyle.typography.fontSizeSmall - 2 }}>
        {description}
      </IM.Text>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  noDocument: {
    alignItems: 'center',
  },
})
