import { IM, ModalController, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { Pressable } from 'react-native'

import type { Document } from '../../apis/types/apiResponseTypes'
import DocumentCard from '../../cards/DocumentCard'
import BadgeWithText from '../../components/BadgeWithText'
import IMContentCard from '../../components/Card/IMContentCard'
import useLayout from '../../hooks/useLayout'
import DocumentSelectionModal from '../../modals/DocumentSelectionModal'
import { ModifyTimeData } from '../../modals/ModifyTimeModal'
import type { NewTimeModalData } from '../../modals/NewTimeModal'

export interface Props {
  disabled?: boolean
  selectedDocument: Document | undefined
  allowUnselected?: boolean
  setSelectedDocument?: React.Dispatch<React.SetStateAction<Document | undefined>>
  error?: boolean
  newTimeController?: ModalController<NewTimeModalData> | ModalController<ModifyTimeData>
}

export default function DocumentSelectionView({
  disabled,
  selectedDocument,
  allowUnselected = true,
  setSelectedDocument,
  error,
  newTimeController,
}: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()
  const infoboxNavigation = useNavigation()
  const { isSmallDevice } = useLayout()

  const controller = useModalController<Document[]>()
  const enabled = !disabled && !!setSelectedDocument

  function getNavigationSource() {
    if (infoboxNavigation.getState()?.routeNames.find(rn => rn.toLowerCase() === 'home')) return 'HomeStack'
    if (infoboxNavigation.getState()?.routeNames.find(rn => rn.toLowerCase() === 'history')) return 'HistoryStack'
    return ''
  }

  function handleSelectedDocumentPressed() {
    if (enabled) {
      if (allowUnselected) setSelectedDocument(undefined)
      else !controller.isShown && controller.show([])
    }
  }

  const handleInfoboxNavigation = () => {
    if (isSmallDevice) {
      newTimeController?.close()
      infoboxNavigation.navigate('InfoboxFolder', { document: selectedDocument, navigationSource: getNavigationSource() })
    } else {
      newTimeController?.close()
      infoboxNavigation.navigate('InfoBox', { document: selectedDocument })
    }
  }

  return (
    <IMContentCard title={i18n.t('DOCUMENT')} spacing="all" error={error}>
      <IM.View spacing="all">
        {!!selectedDocument && (
          <DocumentCard
            document={selectedDocument}
            onPress={enabled ? handleSelectedDocumentPressed : handleInfoboxNavigation}
            editable={!disabled}
            fetchDocumentCount={!enabled}
            borderless
          />
        )}
        {!selectedDocument && (
          <Pressable disabled={disabled} onPress={() => controller.show([])}>
            <BadgeWithText
              textProps={{ secondary: true }}
              badgeProps={{ children: 'PX', textStyle: { fontSize: 16 }, backgroundColor: theme.card.headBackground }}>
              {i18n.t('SELECT_PLACEHOLDER')}
            </BadgeWithText>
          </Pressable>
        )}
      </IM.View>
      {enabled && <DocumentSelectionModal controller={controller} onSelected={setSelectedDocument} />}
    </IMContentCard>
  )
}
