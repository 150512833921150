import { IM, ViewProps } from '@infominds/react-native-components'
import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'

export type KeyboardAvoidingScrollView = {
  disableKeyboardAvoiding?: boolean
  behavior?: 'height' | 'position' | 'padding'
  keyboardAvoidingViewStyle?: StyleProp<ViewStyle>
} & ViewProps

export default function KeyboardAvoidingScrollView({
  disableKeyboardAvoiding: _disableKeyboardAvoiding,
  keyboardAvoidingViewStyle: _keyboardAvoidingViewStyle,
  children,
  ...viewProps
}: KeyboardAvoidingScrollView) {
  return <IM.ScrollView {...viewProps}>{children}</IM.ScrollView>
}
