import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { AlertContextProps, Language } from '@infominds/react-native-components'
import { LicenseGlobals } from '@infominds/react-native-license'
import type { AssetDisplayMethod, MediaSortingMethod } from '@infominds/react-native-media'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { de, enGB, it } from 'date-fns/locale'
import type { i18n } from 'i18next'
import type { AlertButton } from 'react-native'
import type { AtomEffect, DefaultValue, WrappedValue } from 'recoil'

import type { Document, Employee, File } from '../apis/types/apiResponseTypes'

type AbortError = {
  name: string
}

type FetchError = {
  Code: number
  Message: string
}

type setSelfType<T> =
  | T
  | DefaultValue
  | Promise<T | DefaultValue>
  | WrappedValue<T>
  | ((param: T | DefaultValue) => T | DefaultValue | WrappedValue<T>)

const appUtils = {
  filterDocument(searchText: string, documentsToFilter: Document[]) {
    if (searchText === '') {
      return documentsToFilter
    }

    return documentsToFilter.filter(
      doc => doc.description?.toUpperCase().includes(searchText.toUpperCase()) || doc.code.toUpperCase().includes(searchText.toUpperCase())
    )
  },
  sort: (file: File[], sort: MediaSortingMethod) => {
    if (sort === 'reverse') {
      return file.sort((first, second) => Date.parse(second.date) - Date.parse(first.date))
    }

    if (sort === 'direct') {
      return file.sort((first, second) => Date.parse(first.date) - Date.parse(second.date))
    }

    return file
  },
  languageToLocale(language: Language): Locale {
    switch (language) {
      case 'de':
        return de
      case 'it':
        return it
      case 'en':
        return enGB
      default:
        return enGB
    }
  },
  capitalizeFirstLetter(str: string) {
    if (str[0]) return str[0].toUpperCase() + str.slice(1)

    return str
  },
  localStorageEffect<T>(key: string): AtomEffect<T> {
    return ({ setSelf, onSet }) => {
      AsyncStorage.getItem(key)
        .then(savedValue => {
          if (savedValue !== null) {
            setSelf(JSON.parse(savedValue) as setSelfType<T>)
          }
        })
        .catch(err => console.error(`Failed fetching ${key}:`, err))

      onSet((newValue, _, isReset) => {
        if (isReset) {
          AsyncStorage.removeItem(key).catch(err => console.error(`Failed resetting ${key}:`, err))
        } else {
          AsyncStorage.setItem(key, JSON.stringify(newValue)).catch(err => console.error(`Failed saving ${key}:`, err))
        }
      })
    }
  },
  translateFilter(filter: string, translator: i18n) {
    let toRet = filter

    switch (filter) {
      case 'Production':
        toRet = translator.t('DOCUMENT_PRODUCTION')
        break
      case 'Order':
        toRet = translator.t('DOCUMENT_ORDER')
        break
      case 'Project':
        toRet = translator.t('DOCUMENT_PROJECT')
        break
      default:
        break
    }

    return toRet
  },
  getDocumentIcon(documentType: string) {
    let toRet: IconProp | undefined

    switch (documentType) {
      case 'Production':
        toRet = ['fas', 'gears']
        break
      case 'Order':
        toRet = ['fas', 'file-invoice']
        break
      case 'Project':
        toRet = ['fas', 'books']
        break
      default:
        break
    }

    return toRet
  },
  manageDocumentsNumber(i18n: i18n, elements: number) {
    if (elements === 0) {
      return i18n.t('NO_DOCUMENT')
    } else if (elements === 1) {
      return `${elements} ${i18n.t('DOCUMENT_LOWER_CASE')}`
    } else {
      return `${elements} ${i18n.t('DOCUMENTS_LOWER_CASE')}`
    }
  },
  getMediaDisplayMethod(prevMethod: AssetDisplayMethod): AssetDisplayMethod {
    if (prevMethod === 'twice') {
      return 'details'
    } else if (prevMethod === 'details') {
      return 'once'
    } else {
      return 'twice'
    }
  },
  insert<T>(arr: T[], index: number, newItem: T) {
    return [
      // part of the array before the specified index
      ...arr.slice(0, index),
      // inserted item
      newItem,
      // part of the array after the specified index
      ...arr.slice(index),
    ]
  },
  changeTimeAlert(i18n: i18n, alert: AlertContextProps, title: string, message: string, subordinates?: Employee[]) {
    return new Promise<'mine' | 'all' | 'abort'>(resolve => {
      let buttons: AlertButton[] = [
        {
          text: i18n.t('CANCEL'),
          onPress: () => {
            resolve('abort')
          },
          style: 'cancel',
        },
        {
          text: i18n.t('ASK_APPLY_CHANGES_TO_ASSOCIATED_TIMES_YES'),
          onPress: () => {
            resolve('all')
          },
          style: 'default',
        },
      ]

      if (subordinates && subordinates.length > 0) {
        buttons = appUtils.insert(buttons, 1, {
          text: i18n.t('ASK_APPLY_CHANGES_TO_ASSOCIATED_TIMES_ONLY_MINE'),
          onPress: () => {
            resolve('mine')
          },
          style: 'destructive',
        })
      }

      alert.alert(title, message, buttons)
    })
  },
  isAbortError(err: AbortError | unknown): err is AbortError {
    return (err as AbortError).name !== undefined && (err as AbortError).name === 'AbortError'
  },
  isFetchError(error: unknown): error is FetchError {
    if ((error as FetchError).Message && (error as FetchError).Code) return true
    return false
  },
  generateSessionKey(suffix?: string) {
    return `lic=${LicenseGlobals.license};user=${LicenseGlobals.username};company=${LicenseGlobals.code};${suffix ?? ''};`
  },
}

export function CalculateInitials(inputString: string, maxLength?: number, canBeSmaller?: boolean) {
  if (!maxLength) maxLength = 2
  if (!inputString) return ''
  const simplifiedString = inputString.replace(/[^a-zA-Z0-9]/g, '')
  let initial = ''

  if (simplifiedString.length === 0) {
    initial = Math.random()
      .toString(36)
      .slice(maxLength)
      .substring(maxLength, maxLength * 2)
      .toUpperCase()
  } else if (simplifiedString.length === 1) {
    if (canBeSmaller) {
      initial = simplifiedString
    } else {
      initial =
        simplifiedString[0] +
        Math.random()
          .toString(36)
          .slice(maxLength)
          .substring(maxLength, maxLength + 1)
          .toUpperCase()
    }
  } else {
    initial = simplifiedString.substring(0, maxLength)
  }

  return initial
}

export default appUtils
