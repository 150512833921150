import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { LoginScreen, useAuthentication, useLicenseHelper } from '@infominds/react-native-license'
import { useNavigation } from '@react-navigation/native'
import React, { useContext, useEffect, useState } from 'react'
import { ActivityIndicator, ImageSourcePropType, Platform, StyleSheet } from 'react-native'
import { OrientationType } from 'react-native-orientation-locker'
import { useRecoilState } from 'recoil'

import icon from '../../assets/img/icon.png'
import DemoMode from '../../constants/DemoMode'
import { useActivities } from '../../contexts/ActivitiesContext'
import { useToast } from '../../contexts/ToastReferenceContext'
import { UserSettingsContext } from '../../contexts/UserSettingsContext'
import useLayout from '../../hooks/useLayout'
import GeoLocationUtils from '../../utils/GeoLocationUtils'
import { infoboxFilterPreferenceAtom } from '../../utils/stateManager'

const styles = StyleSheet.create({
  loading: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
})

export default function AppLoginScreen() {
  const toast = useToast()
  const navigation = useNavigation()
  const { isSmallDevice } = useLayout()
  const { theme, colorScheme } = useTheme()
  const { language, i18n } = useLanguage()
  const { isLoggedIn } = useAuthentication()
  const userSettings = useContext(UserSettingsContext)
  const { logout } = useLicenseHelper(navigation)
  const { loadActivities } = useActivities()
  const [filter, setFilter] = useRecoilState(infoboxFilterPreferenceAtom)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (isLoggedIn === false) setLoading(false)
  }, [isLoggedIn])

  function onLoginSuccess() {
    setLoading(true)
    GeoLocationUtils.requestPermission().catch(console.error)

    userSettings
      .load()
      .then(result => {
        if (!result?.employee?.id) {
          throw new Error('ERROR_NO_USER')
        }

        if (!result.isEmployeeTimeManualRecordingActive && !result.isEmployeeTimeOnlineRecordingActive && !result.isPresenceTimeRecordingActive) {
          throw new Error('ERROR_NO_RECORDING_ACTIVE')
        }

        const found = result.documentTypesAvailable.find(available => available === filter)
        if (!found) {
          const firstFilter = result.documentTypesAvailable[0]
          firstFilter && setFilter(firstFilter)
        }
        if (result.isEmployeeTimeManualRecordingActive || result.isEmployeeTimeOnlineRecordingActive) return loadActivities()
        return
      })
      .then(() => {
        navigation.reset({ index: 0, routes: [{ name: 'Root' }] })
        setLoading(false)
      })
      .catch((reason: unknown) => {
        console.error(reason)
        if ((reason as Error)?.message === 'ERROR_NO_USER') {
          toast.show(i18n.t('ERROR_NO_EMPLOYEE_LINKED_TO_USER'), { type: 'danger' })
        } else if ((reason as Error)?.message === 'ERROR_NO_RECORDING_ACTIVE') {
          toast.show(i18n.t('ERROR_NO_TIME_RECORDING_ACTIVE'), { type: 'danger' })
        } else {
          toast.show(i18n.t('ERROR_LOADING_USER_SETTINGS'), { type: 'danger' })
        }
        handleDataError()
      })
  }

  function handleDataError() {
    logout().catch(console.error)
  }

  return (
    <>
      {loading && (
        <IM.View style={[{ backgroundColor: theme.card.background }, styles.loading]}>
          <ActivityIndicator size="large" color={IMStyle.palette.tint} />
        </IM.View>
      )}
      <LoginScreen
        projectCode="APP-RXTIME"
        modulCode="APP"
        demoData={{
          lic: DemoMode.DEMO_LICENSE_KEY,
          username: DemoMode.DEMO_USERNAME,
          password: DemoMode.DEMO_PASSWORD,
        }}
        iconSource={icon as ImageSourcePropType}
        navigation={navigation}
        onSuccess={onLoginSuccess}
        keepLicenseOnLogout
        timeoutInMs={30000}
        language={language}
        theme={colorScheme}
        colors={{ dark: { background: theme.background }, light: { background: theme.background } }}
        apiType=".net"
        hideLoadingSpinner={loading}
        codeScannerOrientationOnExit={isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios') ? OrientationType.PORTRAIT : undefined}
      />
    </>
  )
}
