import { IM, SpacingProps } from '@infominds/react-native-components'
import Color from 'color'
import React, { useMemo } from 'react'

import DurationDisplay from '../../components/DurationDisplay'
import type { HistoryElement } from '../../components/TimeBar'
import TimeBar from '../../components/TimeBar'

export type DocumentElement = HistoryElement & {
  activities: { name: string; duration: number; id: string }[]
}

interface EmployeeTimeDocumentViewProps {
  document: DocumentElement
  showDetails: boolean
  spacing?: SpacingProps
}

const ALPHA_RANGE = 0.86
const MAX_ALPHA = 0.95

export default function EmployeeTimeDocumentView({ document, showDetails, spacing }: EmployeeTimeDocumentViewProps) {
  const activities: HistoryElement[] = useMemo(() => {
    const toRet: HistoryElement[] = []

    const alphaDecrement = ALPHA_RANGE / document.activities.length

    document.activities.forEach((elem, index) => {
      const docDurationSec = document.duration * 60
      const eleDurationSec = elem.duration * 60

      toRet.push({
        id: elem.id,
        name: elem.name,
        duration: elem.duration,
        color: Color(document.color)
          .alpha(MAX_ALPHA - alphaDecrement * index)
          .toString(),
        displayRatio: eleDurationSec / docDurationSec,
        ratio: eleDurationSec / docDurationSec,
      })
    })

    return toRet.reduce<HistoryElement[]>((acc, current, index) => {
      if (index === 0) {
        current.displayRatio = 1
      } else {
        const prevValue = acc[index - 1]

        if (prevValue) {
          current.displayRatio = prevValue.displayRatio - prevValue.ratio
        }
      }

      acc.push(current)
      return acc
    }, [])
  }, [document])

  return (
    <>
      {showDetails ? (
        <IM.View spacing={spacing}>
          <TimeBar elements={activities} title={document.name} time={document.duration} color={document.color} />
          <IM.View spacing="top">
            {activities.map((elem, index) => {
              return (
                <IM.View key={`${document.id}-${elem.id}`}>
                  <DurationDisplay color={elem.color} name={elem.name} duration={elem.duration} formatTimeWithDimensions />
                  {index === activities.length - 1 && <IM.View spacing="bottom" />}
                </IM.View>
              )
            })}
          </IM.View>
        </IM.View>
      ) : (
        <DurationDisplay color={document.color} name={document.name} duration={document.duration} formatTimeWithDimensions />
      )}
    </>
  )
}
