import { BadgeProps, IM, SpacingProps, TextProps } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

export type BadgeWithTextProps = { children?: string; spacing?: SpacingProps; badgeProps?: BadgeProps; textProps?: TextProps }

export default function BadgeWithText({ children, spacing, badgeProps, textProps }: BadgeWithTextProps) {
  return (
    <IM.View spacing={spacing} style={[styles.view]}>
      <IM.Badge {...badgeProps} />
      <IM.View spacing={'left'} style={styles.textContainer}>
        <IM.Text {...textProps}>{children}</IM.Text>
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  textContainer: {
    flex: 1,
    justifyContent: 'center',
    textAlignVertical: 'center',
  },
})
