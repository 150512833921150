import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMStyle, useTheme } from '@infominds/react-native-components'
import { StackActions, useNavigation, useRoute } from '@react-navigation/core'
import React, { useMemo } from 'react'
import { GestureResponderEvent, Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { DefaultBarContent, StringContextText } from './DefaultBarContent'
import PressableIcon from './Infominds/PressableIcon'

export interface ScreenHeaderProps {
  title?: string
  subTitle?: StringContextText
  icon?: IconProp
  onIconPress?: () => void
  style?: StyleProp<ViewStyle>
  barContent?: JSX.Element
  goBack?: boolean | ((event: GestureResponderEvent) => void)
  close?: boolean | ((event: GestureResponderEvent) => void)
  closeIcon?: IconProp
}

export const screenHeaderSettings = {
  minBarHeight: 65.1,
  iconSize: 22,
  borderRadius: 25,
  horizontalMargin: 17,
  title: {
    verticalMargin: 10,
    size: 24,
  },
}

export function ScreenHeader(props: ScreenHeaderProps) {
  const { theme } = useTheme()
  const route = useRoute()
  const navigation = useNavigation()
  const navigationCanGoBack = useMemo(() => navigation.canGoBack(), [])

  const showGoBackIcon = !!props.goBack && (typeof props.goBack === 'function' || navigationCanGoBack)
  const showCloseIcon = !!props.close

  function handleGoBack(event: GestureResponderEvent) {
    if (!props.goBack) return
    if (typeof props.goBack === 'function') return props.goBack(event)
    navigation.goBack()
  }

  function handleClose(event: GestureResponderEvent) {
    if (!props.close) return
    if (typeof props.close === 'function') return props.close(event)
    navigation.dispatch(StackActions.popToTop())
  }

  return (
    <IM.View style={props.style}>
      <IM.View style={[styles.barContainer, Platform.OS !== 'web' && styles.barContainerRadius, { backgroundColor: theme.modal.headBackground }]}>
        {showGoBackIcon && (
          <PressableIcon
            style={styles.barIconContainer}
            icon={['fal', 'arrow-left']}
            color={IMStyle.palette.white}
            onPress={handleGoBack}
            size={screenHeaderSettings.iconSize}
          />
        )}
        <IM.View style={[styles.barContent, !showGoBackIcon && styles.paddingLeft, !showCloseIcon && styles.paddingRight]}>
          {props.barContent ? (
            props.barContent
          ) : (
            <DefaultBarContent routeName={route.name} title={props.title} subtitle={props.subTitle} icon={props.icon} onPress={props.onIconPress} />
          )}
        </IM.View>
        {showCloseIcon && (
          <PressableIcon
            icon={props.closeIcon ?? ['fal', 'times']}
            color={IMStyle.palette.white}
            style={styles.barIconContainer}
            onPress={handleClose}
            size={screenHeaderSettings.iconSize}
          />
        )}
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  barContainer: {
    minHeight: screenHeaderSettings.minBarHeight,
    flexDirection: 'row',
    alignItems: 'center',
  },
  barContainerRadius: {
    borderTopLeftRadius: screenHeaderSettings.borderRadius,
    borderTopRightRadius: screenHeaderSettings.borderRadius,
  },
  barIconContainer: {
    marginHorizontal: IMStyle.layout.horizontalMargin,
  },
  barContent: {
    flex: 1,
  },
  paddingLeft: {
    paddingLeft: screenHeaderSettings.horizontalMargin,
  },
  paddingRight: {
    paddingRight: screenHeaderSettings.horizontalMargin,
  },
})
