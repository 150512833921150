import { isAfter, isBefore, isSameDay } from 'date-fns'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import api from '../apis/apiCalls'
import { ActivityTime, AdditionalRepayment, EmployeeTime, PresenceTime, PresenceTimeKey } from '../apis/types/apiResponseTypes'
import useCalendar from '../components/Infominds/Calendar/hooks/useCalendar'
import useIsEmployeeTimeEnabled from '../hooks/useIsEmployeeTimeEnabled'
import useIsPresenceTimeEnabled from '../hooks/useIsPresenceTimeEnabled'
import useUserSettings from '../hooks/useUserSettings'
import DateUtils from '../utils/DateUtils'
import PresenceTimeUtils from '../utils/PresenceTimeUtils'
import { historyDisplayMode } from '../utils/stateManager'
import TimeUtils from '../utils/TimeUtils'

export type PresenceTimesOfDay = {
  date: Date
  times: PresenceTime[]
}

export enum HistoryDisplayMode {
  Day,
  Month,
}

export type HistoryData = {
  displayMode: HistoryDisplayMode
  employeeTimes: EmployeeTime[] | undefined
  presenceTimes: PresenceTime[] | undefined
  presenceTimeKeys: PresenceTimeKey[] | undefined
  presenceTimesOfDay: PresenceTimesOfDay[] | undefined
  additionalRepayments: AdditionalRepayment[] | undefined
  activityTimes: ActivityTime[] | undefined
  minutesTarget: number | undefined
  loading: boolean
  error: boolean
  reLoad: () => void
  getPresenceTimesOfDay: (date: Date) => PresenceTime[] | null
  loadPresenceTimesRange: (from: Date, until: Date) => Promise<void>
}

const HistoryContext = React.createContext<HistoryData | null>(null)

export function HistoryContextProvider({ children }: { children: ReactNode }) {
  const { date } = useCalendar()
  const userSettings = useUserSettings()
  const isPresenceTimeEnabled = useIsPresenceTimeEnabled()
  const isEmployeeTimeEnabled = useIsEmployeeTimeEnabled()

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [requestCounter, setRequestCounter] = useState(0)
  const [activityTimes, setActivityTimes] = useState<ActivityTime[] | undefined>(undefined)
  const [minutesTarget, setMinutesTarget] = useState<number | undefined>(undefined)
  const [employeeTimes, setEmployeeTimes] = useState<EmployeeTime[] | undefined>(undefined)
  const [presenceTimes, setPresenceTimes] = useState<PresenceTime[] | undefined>(undefined)
  const [presenceTimeKeys, setPresenceTimeKeys] = useState<PresenceTimeKey[] | undefined>(undefined)
  const [presenceTimesOfDay, setPresenceTimesOfDay] = useState<PresenceTimesOfDay[] | undefined>(undefined)
  const [additionalRepayments, setAdditionalRepayments] = useState<AdditionalRepayment[] | undefined>(undefined)
  const displayMode = useRecoilValue(historyDisplayMode)

  useEffect(() => {
    if (!userSettings || !date || displayMode === HistoryDisplayMode.Month) return

    setError(false)
    setLoading(true)
    setEmployeeTimes(undefined)
    setPresenceTimes(undefined)
    setAdditionalRepayments(undefined)
    setActivityTimes(undefined)

    const dateForRequest = TimeUtils.getDateForRequest(date)

    Promise.all([
      isEmployeeTimeEnabled &&
        api.getEmployeeTime({
          employeeId: userSettings.employee?.id,
          from: dateForRequest,
          until: dateForRequest,
        }),
      isPresenceTimeEnabled && api.getPresenceTimes({ employeeId: userSettings.employee.id, date: dateForRequest }),
      isPresenceTimeEnabled && api.getPresenceTimeOfDay({ employeeId: userSettings.employee.id, date: dateForRequest }),
      isPresenceTimeEnabled && api.getPresenceTimeKeys(),
    ])
      .then(([resultEmployeeTimes, resultPresenceTimes, resultPresenceTimeOfDay, resultPresenceTimeKeys]) => {
        setEmployeeTimes(resultEmployeeTimes || [])
        if (resultPresenceTimeKeys === false) return
        setPresenceTimeKeys(resultPresenceTimeKeys)
        if (resultPresenceTimes === false) return
        resultPresenceTimes.sort((a, b) => PresenceTimeUtils.sortTimesByDuration(a, b))
        setPresenceTimes(resultPresenceTimes)
        updatePresenceTimesOfDay(date, resultPresenceTimes)
        if (resultPresenceTimeOfDay) {
          const resultAdditionalRepayments = resultPresenceTimeOfDay.presenceTimeAdditionalRepayments
          const resultActivityTimes = resultPresenceTimeOfDay.activityTimes

          if (resultAdditionalRepayments && resultAdditionalRepayments.length > 0) {
            resultAdditionalRepayments.sort((a, b) => a.presenceTimeAdditionalRepaymentKey.localeCompare(b.presenceTimeAdditionalRepaymentKey))
            setAdditionalRepayments(resultAdditionalRepayments)
          }

          if (resultActivityTimes && resultActivityTimes.length > 0) {
            resultActivityTimes.sort((a, b) => PresenceTimeUtils.sortActivitiesByDuration(a, b))
            setActivityTimes(resultActivityTimes)
          }

          if (resultPresenceTimeOfDay.minutesTarget) {
            setMinutesTarget(resultPresenceTimeOfDay.minutesTarget)
          }
        }
      })
      .catch(getError => {
        console.error('Load HistoryData Error', getError)
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [date, requestCounter, displayMode, isEmployeeTimeEnabled, isPresenceTimeEnabled, userSettings])

  function updatePresenceTimesOfDay(day: Date, times: PresenceTime[]) {
    setPresenceTimesOfDay(prev => {
      if (!prev) return [{ date: day, times } as PresenceTimesOfDay]
      const foundDate = prev.find(p => isSameDay(p.date, day))
      if (foundDate) {
        foundDate.times = times
      } else {
        prev.push({ date: day, times })
      }
      return [...prev]
    })
  }

  function getPresenceTimesOfDay(day: Date) {
    return presenceTimesOfDay?.find(t => isSameDay(t.date, day))?.times ?? null
  }

  async function loadPresenceTimesRange(from: Date, until: Date) {
    try {
      setLoading(true)
      const times = await api.getPresenceTimes({
        employeeId: userSettings?.employee.id ?? '',
        from: TimeUtils.getDateForRequest(from),
        until: TimeUtils.getDateForRequest(until),
      })

      if (presenceTimeKeys === undefined) {
        const keys = await api.getPresenceTimeKeys()
        setPresenceTimeKeys(keys)
      }

      setPresenceTimesOfDay(prev => {
        if (!prev) prev = []
        // remove existing entries in range
        prev = prev.filter(time => isBefore(time.date, from) || isAfter(time.date, until))
        const dates = DateUtils.keepUniqueDates(times.filter(time => time.date).map(time => DateUtils.dateify(time.date ?? '')))
        for (const day of dates) {
          const timesOfDay = times.filter(t => t.date && isSameDay(DateUtils.dateify(t.date), day))
          if (!timesOfDay.length) continue
          prev.push({ date: day, times: timesOfDay })
        }
        return [...prev]
      })
    } catch (exception) {
      console.error(exception)
    } finally {
      setLoading(false)
    }
  }

  return (
    <HistoryContext.Provider
      value={{
        displayMode,
        employeeTimes,
        presenceTimes,
        presenceTimeKeys,
        presenceTimesOfDay,
        additionalRepayments,
        activityTimes,
        minutesTarget,
        loading,
        error,
        reLoad: () => setRequestCounter(prev => prev + 1),
        getPresenceTimesOfDay,
        loadPresenceTimesRange,
      }}>
      {children}
    </HistoryContext.Provider>
  )
}

export function useHistory() {
  const context = useContext(HistoryContext)
  if (!context) throw new Error('useHistory() can only be used inside of HistoryContextProvider')
  return context
}
