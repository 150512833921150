import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useContext } from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { CalendarContext, UpdateSources } from './context/CalendarContext'

export default function GoToToday() {
  const { i18n } = useLanguage()
  const { theme } = useTheme()

  const { setDate } = useContext(CalendarContext)

  return (
    <Pressable
      onPress={() => {
        setDate(new Date(new Date().setHours(0, 0, 0, 0)), UpdateSources.PICKER_SELECT)
      }}
      style={({ pressed }) => [
        {
          borderRadius: IMStyle.layout.borderRadius,
          marginVertical: IMStyle.layout.verticalMargin,
          backgroundColor: pressed ? theme.button.pressedOpacity : 'transparent',
          minWidth: '8%',
          marginRight: 4,
        },
      ]}>
      <IM.View spacing="horizontal" style={styles.view}>
        <IM.Icon icon={['fal', 'calendar-day']} size={18} color={IMStyle.palette.white} />
        <IM.Text
          style={{
            color: IMStyle.palette.white,
            fontSize: IMStyle.typography.fontSizeRegular,
          }}>
          {i18n.t('TODAY')}
        </IM.Text>
      </IM.View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
