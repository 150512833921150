const prodDemo = {
  DEMO_USERNAME: 'RADIX-DEMO',
  DEMO_PASSWORD: '123',
  DEMO_LICENSE_KEY: 'APP-RXTIME_0JO_DEMO',
}

const devDemo = [
  {
    DEMO_USERNAME: 'IPAD_SU',
    DEMO_PASSWORD: 'demo',
    DEMO_LICENSE_KEY: 'APP-RXTIME_0U8_DEMO',
  },
]

export default __DEV__ ? devDemo[0] : prodDemo
