import { IM } from '@infominds/react-native-components'
import isEqual from 'lodash/isEqual'
import React from 'react'

import EmployeeTimeDocumentView, { DocumentElement } from './EmployeeTimeDocumentView'

interface EmployeeTimeSummaryDocumentsViewProps {
  documents: DocumentElement[]
  showDetails: boolean
}

function arePropsEqual(prevProps: EmployeeTimeSummaryDocumentsViewProps, nextProps: EmployeeTimeSummaryDocumentsViewProps) {
  const { documents: prevDoc, ...prevOther } = prevProps
  const { documents: nextDoc, ...nextOther } = nextProps

  return prevDoc.length === nextDoc.length && isEqual(prevOther, nextOther)
}

export const EmployeeTimeSummaryDocumentsView = ({ documents, showDetails }: EmployeeTimeSummaryDocumentsViewProps) => {
  return (
    <IM.View spacing={showDetails ? 'none' : 'top'}>
      {documents.map(elem => {
        return <EmployeeTimeDocumentView key={elem.id} document={elem} showDetails={showDetails} spacing="top" />
      })}
    </IM.View>
  )
}

export default React.memo(EmployeeTimeSummaryDocumentsView, arePropsEqual)
