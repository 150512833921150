import { IM, useLanguage, useModalController } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import type { EmployeeTime } from '../../apis/types/apiResponseTypes'
import IMContentCard from '../../components/Card/IMContentCard'
import Timer from '../../components/Timer'
import useAllowEmployeeTimeEdit from '../../hooks/useAllowEmployeeTimeEdit'
import ModifyTimeModal, { ModifyTimeData } from '../../modals/ModifyTimeModal'
import TimeUtils from '../../utils/TimeUtils'

export type CurrentTimeViewProps = {
  time: EmployeeTime
  onEndEdit?: () => void
}

export default function CurrentTimeView({ time }: CurrentTimeViewProps) {
  const { i18n } = useLanguage()
  const controller = useModalController<ModifyTimeData>()
  const { allowEdit } = useAllowEmployeeTimeEdit()

  const startTime = time.from
  const duration = (time?.duration ?? 0) * 60
  const isTimeActive = !!time.from && !time.until

  const handlePress = () => {
    !controller.isShown && controller.show({ employeeTime: time })
  }

  return (
    <>
      <IMContentCard title={i18n.t('Time')} spacing="all">
        <IM.View spacing="all">
          <IM.View style={styles.startTimeView}>
            <IM.Text>{i18n.t('StartTime')}</IM.Text>
            <IM.Text>{TimeUtils.formatSeconds(startTime, undefined, true)}</IM.Text>
          </IM.View>
          <IM.View style={styles.timerView}>
            {isTimeActive && <Timer style={styles.timer} timeS={startTime} runTimer />}
            {!isTimeActive && <Timer style={styles.timer} timeS={duration} />}
            {!!allowEdit && <IM.PressableIcon icon={['fal', 'pen-to-square']} onPress={handlePress} />}
          </IM.View>
        </IM.View>
      </IMContentCard>
      <ModifyTimeModal controller={controller} />
    </>
  )
}

const styles = StyleSheet.create({
  startTimeView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  timerView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  timer: {
    flex: 1,
  },
})
