export interface UserSettings {
  language: string
  employee: Employee
  subordinates: Employee[] | null
  numberOfDaysForChangesOfHistoricalTimes: number
  commentAsMandatoryInput: boolean
  numberOfLastTimes: number
  documentTypesAvailable: string[]
  isPresenceTimeRecordingActive: boolean
  isPresenceTimeManualRecordingActive: boolean
  isEmployeeTimeOnlineRecordingActive: boolean
  isEmployeeTimeManualRecordingActive: boolean
}

export interface Document {
  id: string
  documentType: string
  code: string
  description: string | null
  pspDetailId: string | null
}

export interface Folder {
  id: string
  number: number
  description: string | null
  type: number
  order: number
  right: FolderRight
}

export enum FolderRight {
  read = 'ReadOnly',
  editLimited = 'EditOnlyOwnDocuments',
  write = 'AllRights',
}

export interface PostFile {
  id: string
  txdokid: string
  filename: string
  extension: string
}

export interface File {
  id: string
  txdokid: string
  createUserId: string
  filename: string
  extension: string
  date: string
  external: boolean | null
  folderNumber: number
  description: string | null
  sizeKB: number
  thumbImageAsPNG: string | null
  canDelete: boolean
}

export interface HighResFile extends File {
  doctype: number
  origin: number
  externalFileId: string | null
  data: string
}

export interface Company {
  id: string
  description: string
}

export interface Employee {
  id: string
  code: string
  firstName: string
  lastName: string
  language: string
  company: string
  userId: string
  employeeTimeTypeId: string
}

export interface EmployeeTime {
  id: string
  masterId?: string
  employeeId: string
  employee: string
  date: Date
  from: number
  until: number
  duration: number
  documentType: string | null
  documentId: string | null
  document: string | null
  description: string
  pspDetId?: string
  employeeTimeTypeId: string
  employeeTimeType: string
  note?: string
  subordinates?: Employee[]
}

export interface EmployeeTimeType {
  id: string
  code?: string
  description?: string
  documentNecessary?: boolean
  abbreviation?: string
}

export interface PresenceTimeKey {
  id: string
  code?: string
  order?: number
  description?: string
  isInputStartStopActive?: boolean
  presenceTimeKeyType?: PresenceTimeKeyType
  inActive?: boolean
  activeOnWeekday?: ActiveOnWeekDay
}

export interface AdditionalRepaymentKey {
  id: string
  code: string
  description: string
}

export interface AdditionalRepayment {
  id: string
  employeeId: string
  employee: string
  date: string
  presenceTimeAdditionalRepaymentKeyId: string
  presenceTimeAdditionalRepaymentKey: string
  presenceTimeAdditionalRepaymentKeyDescription: string
}

export type ActiveOnWeekDay = {
  monday: boolean
  tuesday: boolean
  wednesday: boolean
  thursday: boolean
  friday: boolean
  saturday: boolean
  sunday: boolean
}

export enum PresenceTimeKeyType {
  None = 'None',
  TimeCompensation = 'TimeCompensation',
  Vacation = 'Vacation',
}

export interface PresenceTime {
  employeeId?: string
  employee?: string
  date?: string
  presenceTimeTypeId?: string
  presenceTimeType?: string
  presenceTimeTypeDescription?: string
  duration?: number
  details?: PresenceTimeDetail[]
}

export interface PresenceTimeDetail {
  id: string
  date?: string
  from?: number
  until?: number
  duration?: number
}

export interface PresenceTimeOfDay {
  date?: string
  minutesTarget?: number
  minutesTimeCompensation?: number
  minutesVacation?: number
  activityTimes?: ActivityTime[]
  presenceTimes?: PresenceTime[]
  presenceTimeAdditionalRepayments?: AdditionalRepayment[]
}

export interface ActivityTime {
  activityTypeId: string
  activityTypeCode?: string
  activityTypeDescription?: string
  minutes?: number
}
