import React, { createContext, ReactNode, useState } from 'react'

import api from '../apis/apiCalls'
import type { UserSettings } from '../apis/types/apiResponseTypes'

interface UserSettingsContextType {
  userSettings: UserSettings | undefined
  set: (userSettings: UserSettings) => void
  load: () => Promise<UserSettings | undefined>
}

export const UserSettingsContext = createContext<UserSettingsContextType>({
  userSettings: undefined,
  set: () => {
    return
  },
  load: () => Promise.resolve(undefined),
})

export const UserSettingsProvider = ({ children }: { children: ReactNode }) => {
  const [userSettings, setUserSettings] = useState<UserSettings | undefined>(undefined)

  async function load() {
    const result = await api.getUserSettings()
    setUserSettings(result)

    return result
  }

  return (
    <UserSettingsContext.Provider
      value={{
        userSettings: userSettings,
        set: setUserSettings,
        load: load,
      }}>
      {children}
    </UserSettingsContext.Provider>
  )
}
