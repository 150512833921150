import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMStyle, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

export type StringContextText = string | { text: string; icon: IconProp }

interface DefaultBarContentProps {
  routeName: string
  title?: string
  subtitle?: StringContextText
  icon?: IconProp
  onPress?: () => void
}

export function DefaultBarContent({ routeName, title, subtitle, icon, onPress }: DefaultBarContentProps) {
  const { i18n } = useLanguage()

  return (
    <IM.View style={styles.container}>
      <IM.View>
        <IM.Text style={[styles.contentText, styles.title]}>{title ?? i18n.t(`${routeName}Screen`, { defaultValue: routeName })}</IM.Text>
        {subtitle && (
          <>
            {typeof subtitle === 'string' ? (
              <IM.Text style={[styles.contentText, styles.subtitle]}>{subtitle}</IM.Text>
            ) : (
              <IM.View style={styles.subTitleContainer}>
                <IM.Icon icon={subtitle.icon} color={IMStyle.palette.white} />
                <IM.View spacing="left" />
                <IM.Text style={[styles.contentText, styles.subtitle]}>{subtitle.text}</IM.Text>
              </IM.View>
            )}
          </>
        )}
      </IM.View>
      {icon && <IM.PressableIcon icon={icon} onPress={onPress} color={IMStyle.palette.white} size={24} />}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
  subTitleContainer: { flexDirection: 'row', alignItems: 'center' },
  contentText: {
    color: IMStyle.palette.white,
  },
  subtitle: {
    fontSize: IMStyle.typography.fontSizeSmall,
  },
  title: {
    fontSize: IMStyle.typography.fontSizeRegular,
  },
})
