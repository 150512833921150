import { useLanguage, Utils } from '@infominds/react-native-components'
import { isToday } from 'date-fns'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import { PresenceTimeOfDay } from '../apis/types/apiResponseTypes'
import useIsPresenceTimeEnabled from '../hooks/useIsPresenceTimeEnabled'
import useUserSettings from '../hooks/useUserSettings'
import { ActivePresenceTime, AdditionalRepaymentEntry, PresenceTimeEntry } from '../types'
import PresenceTimeUtils from '../utils/PresenceTimeUtils'
import { useToast } from './ToastReferenceContext'

export type PresenceTimeContextType = {
  date: Date
  dateMode: boolean
  presenceTimeInfo: PresenceTimeOfDay | null
  presenceTimeEntries: PresenceTimeEntry[]
  additionalRepaymentEntries: AdditionalRepaymentEntry[]
  numActiveAdditionalRepayments: number
  showAdditionalRepaymentsTab: boolean
  setShowAdditionalRepaymentsTab: (b: boolean) => void
  selectedKeyId: null | string
  setSelectedKeyId: React.Dispatch<React.SetStateAction<string | null>>
  load: (reload?: boolean, abortController?: AbortController) => Promise<void>
  loading: boolean
  error: false | string
  activeTime: ActivePresenceTime | null
}

const PresenceTimeContext = React.createContext<PresenceTimeContextType | null>(null)

export function PresenceTimeContextProvider({ children, editDate }: { children: JSX.Element; editDate?: Date }) {
  const { i18n } = useLanguage()
  const isPresenceTimeEnabled = useIsPresenceTimeEnabled()
  const [presenceTimeInfo, setPresenceTimeInfo] = useState<PresenceTimeOfDay | null>(null)
  const [presenceTimeEntries, setPresenceTimeEntries] = useState<PresenceTimeEntry[]>([])
  const [additionalRepaymentEntries, setAdditionalRepaymentEntries] = useState<AdditionalRepaymentEntry[]>([])
  const [showAdditionalRepaymentsTab, setShowAdditionalRepaymentsTab] = useState(false)
  const [selectedKeyId, setSelectedKeyId] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<false | string>(false)
  const userSettings = useUserSettings()
  const activeTime = useMemo(() => PresenceTimeUtils.findActiveTime(Utils.to1DList(presenceTimeEntries, key => key.times)), [presenceTimeEntries])
  const numActiveAdditionalRepayments = useMemo(
    () => additionalRepaymentEntries.filter(entry => entry.additionalRepayment !== undefined).length,
    [additionalRepaymentEntries]
  )
  const toast = useToast()
  const dateMode = !!editDate && !isToday(editDate)

  useEffect(() => {
    if (!editDate || !isPresenceTimeEnabled) return
    const abortController = new AbortController()
    load(true, abortController).catch(console.error)

    return () => abortController.abort()
  }, [editDate])

  async function load(reload?: boolean, abortController?: AbortController) {
    if (!isPresenceTimeEnabled) return
    try {
      setLoading(true)
      setError(false)
      if (reload) {
        setPresenceTimeEntries([])
        setAdditionalRepaymentEntries([])
        setPresenceTimeInfo(null)
        setSelectedKeyId(null)
      }
      const result = await PresenceTimeUtils.getPresenceTimeEntries(userSettings?.employee?.id ?? '', editDate)
      if (!userSettings?.isPresenceTimeManualRecordingActive) {
        result.keys = result.keys.filter(key => key.key.isInputStartStopActive)
      }
      result.keys = result.keys.filter(({ key }) => PresenceTimeUtils.filterKeyByWeekDay(key, editDate ?? new Date()))
      if (abortController && !abortController?.signal) return
      setPresenceTimeInfo(result.info)
      setPresenceTimeEntries(result.keys.sort((a, b) => PresenceTimeUtils.sortKeys(a, b)))
      setAdditionalRepaymentEntries(result.additionalRepaymentEntries)

      if (!selectedKeyId && !dateMode) {
        const foundActiveTime = PresenceTimeUtils.findActiveTime(Utils.to1DList(result.keys, key => key.times))
        if (foundActiveTime?.time.presenceTimeTypeId) {
          setSelectedKeyId(foundActiveTime.time.presenceTimeTypeId)
        } else if (!presenceTimeEntries.length) {
          const foundStartStopTime = result.keys.find(key => !!key.key.isInputStartStopActive && !!key.key.order)
          if (foundStartStopTime) setSelectedKeyId(foundStartStopTime.key.id)
        }
      }

      return
    } catch (exception) {
      if (abortController && !abortController?.signal) return
      const message = i18n.t('FETCH_TIMES_ERROR')
      setError(message)
      toast.show(message, { type: 'danger' })
      return
    } finally {
      if (!abortController || !!abortController?.signal) setLoading(false)
    }
  }

  return (
    <PresenceTimeContext.Provider
      value={{
        date: editDate ?? new Date(),
        dateMode: dateMode,
        presenceTimeEntries,
        additionalRepaymentEntries,
        numActiveAdditionalRepayments,
        showAdditionalRepaymentsTab,
        setShowAdditionalRepaymentsTab,
        presenceTimeInfo,
        load,
        loading,
        error,
        selectedKeyId,
        setSelectedKeyId,
        activeTime,
      }}>
      {children}
    </PresenceTimeContext.Provider>
  )
}

export function usePresenceTime() {
  const context = useContext(PresenceTimeContext)
  if (!context) throw new Error('usePresenceTime() can only be used inside of PresenceTimeContextProvider')
  return context
}
