import { apiClient } from '@infominds/react-api'
import { LicenseGlobals, LoginUtils } from '@infominds/react-native-license'
import { mediaUtils } from '@infominds/react-native-media'

import type {
  DeleteEmployeeTimeRequest,
  DeleteInfoboxFileRequest,
  DeletePresenceTimeRequest,
  GetAdditionalRepaymentKeyRequest,
  GetAdditionalRepaymentRequest,
  GetDocumentsRequest,
  GetEmployeeRequest,
  GetEmployeeTimeRequest,
  GetEmployeeTimeTypes,
  GetInfoboxFilesRequest,
  GetInfoboxFolderRequest,
  GetPresenceTimeKeysRequest,
  GetPresenceTimeOfDayRequest,
  GetPresenceTimesRequest,
  PatchEmployeeTimeRequest,
  PatchPresenceTimeRequest,
  PostAdditionalRepaymentRequest,
  PostEmployeeTimeRequest,
  PostInfoboxFileRequest,
  PostPresenceTimeRequest,
  PostStopEmployeeTimeRequest,
  StopPresenceTimeRequest,
} from './types/apiRequestTypes'
import type {
  AdditionalRepayment,
  AdditionalRepaymentKey,
  Company,
  Document,
  Employee,
  EmployeeTime,
  EmployeeTimeType,
  File,
  Folder,
  HighResFile,
  PostFile,
  PresenceTime,
  PresenceTimeKey,
  PresenceTimeOfDay,
  UserSettings,
} from './types/apiResponseTypes'

const client = apiClient(LicenseGlobals, '/api/', LoginUtils.reAuthenticate, false, true)

const api = {
  getDocuments: (request: GetDocumentsRequest, abortController?: AbortController) =>
    client.GET<Document[]>('document', request, undefined, abortController),
  //infobox
  getInfoboxFolders: (request: GetInfoboxFolderRequest, abortController?: AbortController) =>
    client.GET<Folder[]>('infobox/infoboxfolder', request, undefined, abortController),
  getInfoboxFiles: (request: GetInfoboxFilesRequest, abortController?: AbortController) =>
    client.GET<File[]>(
      'infobox/infoboxfiles',
      request,
      fetchedFiles => {
        return (fetchedFiles as File[]).filter(
          elem =>
            mediaUtils.isAudio(elem.extension) ||
            mediaUtils.isVideo(elem.extension) ||
            mediaUtils.isImage(elem.extension) ||
            mediaUtils.isPdf(elem.extension)
        )
      },
      abortController
    ),
  deleteInfoboxFile: (request: DeleteInfoboxFileRequest) => client.DELETE<boolean>('infobox', undefined, request),
  postInfoboxFile: (body: PostInfoboxFileRequest) => client.POST<PostFile>('infobox', body),
  getInfoboxFile: (request: GetInfoboxFilesRequest, abortController?: AbortController) =>
    client.GET<HighResFile>('infobox/infoboxfile', request, undefined, abortController),

  //company
  getCompanies: () => client.GET<Company[]>('company'),
  //employee
  getEmployee: (request: GetEmployeeRequest) => client.GET<Employee[]>('employee', request),
  //employeeTime
  getEmployeeTime: (request: GetEmployeeTimeRequest) => client.GET<EmployeeTime[]>('employeeTime', request),
  postEmployeeTime: (body: PostEmployeeTimeRequest) => client.POST<string>('employeeTime', body),
  patchEmployeeTime: (body: PatchEmployeeTimeRequest) => client.PATCH<string>('employeeTime', body),
  deleteEmployeeTime: (request: DeleteEmployeeTimeRequest) => client.DELETE<string>('employeeTime', undefined, request),
  stopEmployeeTime: (body: PostStopEmployeeTimeRequest) => client.POST<string>('employeeTime/stop', body),
  getEmployeeTimeTypes: (request: GetEmployeeTimeTypes) => client.GET<EmployeeTimeType[]>('employeeTime/types', request),
  //presenceTime
  getPresenceTimeKeys: (request?: GetPresenceTimeKeysRequest) => client.GET<PresenceTimeKey[]>('presenceTime/presenceTimeKeys', request),
  getAdditionalRepaymentKeys: (request?: GetAdditionalRepaymentKeyRequest) =>
    client.GET<AdditionalRepaymentKey[]>('presenceTime/presenceTimeAdditionalRepaymentKeys', request),
  getAdditionalRepayment: (request?: GetAdditionalRepaymentRequest) =>
    client.GET<AdditionalRepayment[]>('presenceTime/presenceTimeAdditionalRepayments', request),
  getPresenceTimes: (request: GetPresenceTimesRequest, abortController?: AbortController) =>
    client.GET<PresenceTime[]>('presenceTime/presenceTimes', request, undefined, abortController),
  getPresenceTimeOfDay: (request: GetPresenceTimeOfDayRequest) => client.GET<PresenceTimeOfDay>('presenceTime/presenceTimeOfDay', request),
  postPresenceTime: (request: PostPresenceTimeRequest) => client.POST<string>('presenceTime/presenceTime', request),
  patchPresenceTime: (request: PatchPresenceTimeRequest) => client.PATCH<string>('presenceTime/presenceTime', request),
  deletePresenceTime: (request: DeletePresenceTimeRequest) => client.DELETE<string>('presenceTime/presenceTime', undefined, request),
  stopPresenceTime: (request: StopPresenceTimeRequest) => client.POST<string>('presenceTime/presenceTimeStop', request),
  postAdditionalRepayment: (request: PostAdditionalRepaymentRequest) => client.POST<string>('presenceTime/presenceTimeAdditionalRepayment', request),
  deleteAdditionalRepayment: (id: string) => client.DELETE<boolean>(`presenceTime/presenceTimeAdditionalRepayment/${id}`),
  //userSettings
  getUserSettings: () => client.GET<UserSettings>('userSettings'),
}

export default api
