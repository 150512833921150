import type { ModalController } from '@infominds/react-native-components'
import React from 'react'

import type { Document, EmployeeTimeType } from '../apis/types/apiResponseTypes'
import Modal from '../components/Infominds/Modal'
import NewTimeScreen from '../screens/Home/NewTimeScreen'

export type NewTimeModalData = {
  activity?: EmployeeTimeType
  document?: Document
}

interface Props {
  controller: ModalController<NewTimeModalData>
}

export default function NewTimeModal({ controller }: Props) {
  return (
    <Modal statusBarTranslucent isVisible={controller.isShown} onClose={controller.close}>
      <NewTimeScreen
        onGoBack={() => {
          controller.close()
        }}
        activity={controller.data?.activity}
        document={controller.data?.document}
        newTimeController={controller}
      />
    </Modal>
  )
}
