import { IM, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

interface ActivityCardProps {
  spacing?: SpacingProps
  onPress?: () => void
  showLess?: boolean
}

export default function MoreCard({ spacing, onPress, showLess }: ActivityCardProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()

  return (
    <IM.Card spacing={spacing} head={{ text: '...', backGroundColor: theme.card.headBackground }} onPress={onPress} style={styles.card}>
      <IM.Text>{showLess ? i18n.t('SHOW_LESS') : i18n.t('SHOW_MORE')}</IM.Text>
    </IM.Card>
  )
}

const styles = StyleSheet.create({
  card: {
    alignItems: 'center',
  },
})
