export const CONSTANTS = {
  InfoboxMaxSavedLastUsed: 10,
  InfoboxMaxDisplayLastUsed: 3,
  InfoboxSkeletonCard: 6,
  InfoboxSkeletonFolders: 8,
  InfoboxDocumentChunkSize: 100,
  maxSeconds: 24 * 60 * 60,
  toastVisibilityTime: 5000,
  appName: 'RX+ Time',
}
