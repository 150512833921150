import { DateUtils } from '@infominds/react-native-components'
import { isBefore, subDays } from 'date-fns'
import { useMemo } from 'react'

import useUserSettings from './useUserSettings'

export default function useAllowPresenceTimeEdit(date?: Date) {
  const userSettings = useUserSettings()
  const allowEdit = useMemo(() => allowEditOfDate(date), [date, userSettings])
  const minEditDate = useMemo(() => {
    if (!userSettings?.isPresenceTimeManualRecordingActive) return new Date()
    if (userSettings?.numberOfDaysForChangesOfHistoricalTimes) {
      return subDays(date ?? new Date(), userSettings?.numberOfDaysForChangesOfHistoricalTimes)
    }
    return undefined
  }, [date])

  function allowEditOfDate(dateToCheck?: Date) {
    return (
      !!userSettings?.isPresenceTimeManualRecordingActive &&
      (!userSettings?.numberOfDaysForChangesOfHistoricalTimes ||
        !isBefore(DateUtils.dateify(dateToCheck ?? new Date()), subDays(new Date(), userSettings.numberOfDaysForChangesOfHistoricalTimes)))
    )
  }

  return { allowEdit, allowEditOfDate, minEditDate }
}
