import { IM, IMStyle, useTheme } from '@infominds/react-native-components'
import type { BadgeIconProps } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

export type ButtonBadge = { badgeStyle?: StyleProp<ViewStyle> }

export default function ButtonBadge({ style, badgeStyle, iconStyle, ...badgeProps }: ButtonBadge & BadgeIconProps) {
  const { theme } = useTheme()

  return (
    <IM.BadgeIcon
      iconStyle={[{ color: IMStyle.palette.white }, iconStyle]}
      style={[{ backgroundColor: theme.card.headBackground }, styles.badge, badgeStyle, style]}
      {...badgeProps}
    />
  )
}

const styles = StyleSheet.create({
  badge: {},
})
