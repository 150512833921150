import { CardBasicProps, IM, IMStyle, SpacingProps } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { Platform, StyleSheet } from 'react-native'

import { useEmployeeTime } from '../contexts/EmployeeTimeContext'
import { useToast } from '../contexts/ToastReferenceContext'
import type { NewTimeHandler } from '../hooks/useNewTimeHandler'
import KeyboardEludingView from './Infominds/KeyboardEludingView'

export type TimeHandlerButtonProps = {
  handler: NewTimeHandler
  spacing?: SpacingProps
  postStart?: (timeId: string) => void
  postStop?: () => void
  postCreated?: () => void
  creationMode?: boolean
}

export default function TimeHandlerButton({ handler, spacing, postStart, postStop, postCreated, creationMode }: TimeHandlerButtonProps) {
  const toast = useToast()
  const employeeTime = useEmployeeTime()
  const timeStarted = !!employeeTime.isTimeActive
  const [loading, setLoading] = useState(false)

  const showButton =
    (timeStarted && !creationMode) || (!!creationMode && !handler.missingDataToCreate.length) || (!creationMode && !handler.missingDataToStart.length)

  function startTime() {
    if (!handler.selectedActivity) return
    setLoading(true)
    employeeTime
      .startTime(handler.selectedActivity, handler.selectedDocument, handler.employeeSelector.items, handler.note)
      .then(result => {
        if (result && postStart) postStart(result)
      })
      .catch(onError)
      .finally(() => {
        setLoading(false)
      })
  }

  function stopTime(type?: 'stop' | 'pause') {
    setLoading(true)
    employeeTime
      .stopTime()
      .catch(onError)
      .finally(() => {
        setLoading(false)
        if (type === 'stop' && postStop) postStop()
      })
  }

  function createTime() {
    if (!handler.selectedActivity) return
    setLoading(true)
    employeeTime
      .createTime(
        handler.date ?? new Date(),
        handler.from,
        handler.until,
        handler.selectedActivity,
        handler.selectedDocument,
        handler.employeeSelector.items,
        handler.note
      )
      .then(result => {
        if (result && postCreated) postCreated()
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onError(reason?: any) {
    console.error(reason)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const message: string = reason?.Message
    if (!message) return
    toast.show(message, { type: 'danger' })
  }

  if (!showButton) return <></>

  //KeyboardEludingView is only required on android
  return (
    <KeyboardEludingView eludeKeyboard={Platform.OS === 'android'} spacing={spacing}>
      {!!loading && (
        <>
          <IM.View style={styles.loadingIndicator}>
            <IM.LoadingSpinner isVisible />
          </IM.View>
        </>
      )}
      {!loading && (
        <>
          {!!creationMode && <CreateButton onPress={createTime} />}
          {!creationMode && (
            <>
              {!timeStarted && <StartButton onPress={startTime} />}
              {!!timeStarted && (
                <IM.View style={styles.stopView}>
                  <StopButton style={styles.stopButton} onPress={() => stopTime('stop')} />
                </IM.View>
              )}
            </>
          )}
        </>
      )}
    </KeyboardEludingView>
  )
}

function StartButton({ style, ...cardProps }: CardBasicProps) {
  return (
    <IM.CardBasic style={[{ backgroundColor: IMStyle.palette.tint }, styles.button, style]} {...cardProps}>
      <IM.Icon size={30} color={IMStyle.palette.white} icon={['fal', 'play']} />
    </IM.CardBasic>
  )
}

function StopButton({ style, ...cardProps }: CardBasicProps) {
  return (
    <IM.CardBasic style={[{ backgroundColor: IMStyle.palette.red }, styles.button, style]} {...cardProps}>
      <IM.Icon size={30} color={IMStyle.palette.white} icon={['fal', 'stop']} />
    </IM.CardBasic>
  )
}

function CreateButton({ style, ...cardProps }: CardBasicProps) {
  return (
    <IM.CardBasic style={[{ backgroundColor: IMStyle.palette.tint }, styles.button, style]} {...cardProps}>
      <IM.Icon size={30} color={IMStyle.palette.white} icon={['fal', 'check']} />
    </IM.CardBasic>
  )
}

const styles = StyleSheet.create({
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
  },
  pauseButton: {
    flex: 1,
    marginRight: 8,
  },
  stopButton: {
    flex: 1,
  },
  stopView: {
    flexDirection: 'row',
  },
  loadingIndicator: {
    height: 50,
  },
})
