import { IM, IMLayout, IMStyle, Language, Storage, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import { LicenseGlobals, LicenseStorageKey, useAuthentication } from '@infominds/react-native-license'
import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Image, Platform, StyleSheet } from 'react-native'
import DeviceInfo from 'react-native-device-info'
import { useRecoilState } from 'recoil'

import api from '../apis/apiCalls'
import type { Company } from '../apis/types/apiResponseTypes'
import MicrosoftLogo from '../assets/img/MicrosoftLogo.svg'
import AADUserCard from '../cards/AADUserCard'
import Separator from '../components/Separator'
import { CONSTANTS } from '../constants/Constants'
import useIsEmployeeTimeEnabled from '../hooks/useIsEmployeeTimeEnabled'
import { saveMediaOnDeviceAtom } from '../utils/stateManager'

const languages: { label: string; value: Language }[] = [
  { label: 'Deutsch', value: 'de' },
  { label: 'Italiano', value: 'it' },
  { label: 'English', value: 'en' },
]

export default function SettingsView() {
  const { aadAuth, apiVersion } = useAuthentication()
  const { i18n, language, setLanguageAsync } = useLanguage()
  const { theme, colorScheme, updateColorScheme } = useTheme()
  const { alert } = useAlert()
  const navigation = useNavigation()

  const originalLanguage = useRef(language)
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(language)
  const originalCompanyId = useRef<string | undefined>(undefined)
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>(undefined)
  const [companies, setCompanies] = useState<Company[]>([])
  const [companiesError, setCompaniesError] = useState(false)
  const [companyDropdownOpen, setCompanyDropdownOpen] = useState(false)
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false)
  const isEmployeeTimeEnabled = useIsEmployeeTimeEnabled()

  const [saveMedia, setSaveMedia] = useRecoilState(saveMediaOnDeviceAtom)

  const CompanyStorage = Storage<string>(LicenseStorageKey.code)

  const companyItems = useMemo(
    () =>
      companies.map(company => {
        return { label: company.description, value: company.id }
      }),
    [companies]
  )

  useEffect(() => {
    api
      .getCompanies()
      .then(fetchedCompanies => {
        setCompanies(filterCompanies(fetchedCompanies))

        if (fetchedCompanies.length > 0) {
          const firstCompany = fetchedCompanies[0]
          if (firstCompany === undefined) {
            setCompaniesError(true)
            return
          }

          const found = fetchedCompanies.find(comp => {
            return comp.id === LicenseGlobals.code
          })

          let res = firstCompany.id
          if (found) {
            res = LicenseGlobals.code
          }

          originalCompanyId.current = res
          setSelectedCompanyId(res)
        } else {
          console.error('Empty company array loaded from db')
        }
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  function filterCompanies(loadedCompanies: Company[]) {
    if (!LicenseGlobals.allowedCodes?.length) return loadedCompanies
    return loadedCompanies.filter(c => LicenseGlobals.allowedCodes?.find(ac => ac.toLowerCase() === c.id?.toLowerCase()))
  }

  useEffect(() => {
    if (selectedCompanyId === undefined || originalCompanyId.current === selectedCompanyId) return

    const newCompany = companies.find(item => item.id === selectedCompanyId)

    if (newCompany) {
      alert(i18n.t('WARNING'), i18n.t('CHANGE_COMPANY_WARNING'), [
        {
          text: i18n.t('CANCEL'),
          style: 'default',
          onPress: () => {
            return setSelectedCompanyId(originalCompanyId.current)
          },
        },
        {
          text: i18n.t('RESTART_APP'),
          style: 'destructive',
          onPress: () => {
            CompanyStorage.save(newCompany.id)
              .then(() => {
                setSelectedCompanyId(newCompany.id)
                navigation.navigate('Login')
              })
              .catch(err => console.error(err))
          },
        },
      ])
    } else {
      console.error(`Company ${selectedCompanyId ?? ''} not found`)
      setCompaniesError(true)
    }
  }, [selectedCompanyId])

  useEffect(() => {
    if (originalLanguage.current === selectedLanguage) return

    alert(i18n.t('WARNING'), i18n.t('CHANGE_LANGUAGE_WARNING'), [
      {
        text: i18n.t('CANCEL'),
        style: 'default',
        onPress: () => {
          return setSelectedLanguage(originalLanguage.current)
        },
      },
      {
        text: i18n.t('RESTART_APP'),
        style: 'destructive',
        onPress: () => {
          setLanguageAsync(selectedLanguage)
            .then(() => {
              navigation.navigate('Login')
            })
            .catch(console.error)
        },
      },
    ])
  }, [selectedLanguage])

  const onLanguageOpen = useCallback(() => {
    setCompanyDropdownOpen(false)
  }, [])

  const onCompanyOpen = useCallback(() => {
    setLanguageDropdownOpen(false)
  }, [])

  const toggleSwitch = useCallback(() => {
    if (colorScheme === 'light') {
      updateColorScheme('dark')
    } else {
      updateColorScheme('light')
    }
  }, [colorScheme])

  const dropdownOpen = companyDropdownOpen ? 0 : 1
  const languageOpen = languageDropdownOpen ? 0 : 1

  return (
    <IM.View
      style={[
        styles.container,
        {
          backgroundColor: theme.background,
        },
      ]}>
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <IM.View style={{ marginBottom: 12 }}>
        <AADUserCard />
      </IM.View>

      <IM.View style={[styles.row]}>
        <IM.Text>{i18n.t('SETTINGS_DARKMODE')}</IM.Text>
        <IM.Switch onValueChange={toggleSwitch} value={colorScheme === 'dark'} />
      </IM.View>

      {isEmployeeTimeEnabled && (
        <>
          <Separator style={styles.divider} />
          <IM.View style={[styles.row]}>
            <IM.Text>{i18n.t('SETTINGS_SAVE_ON_GALLERY')}</IM.Text>
            <IM.Switch onValueChange={setSaveMedia} value={saveMedia} />
          </IM.View>
        </>
      )}

      <Separator style={styles.divider} />

      <IM.View style={[styles.row, { zIndex: dropdownOpen }]}>
        <IM.Text>{i18n.t('SETTINGS_LANGUAGE')}</IM.Text>
        <IM.Dropdown
          closeAfterSelecting
          open={languageDropdownOpen}
          value={selectedLanguage}
          items={languages}
          setOpen={setLanguageDropdownOpen}
          setValue={setSelectedLanguage}
          listMode="SCROLLVIEW"
          containerStyle={styles.dropdown}
          onOpen={onLanguageOpen}
        />
      </IM.View>

      <Separator style={styles.divider} />

      <IM.View style={[styles.row, { zIndex: languageOpen }]}>
        <IM.Text>{i18n.t('SETTINGS_MANDANT')}</IM.Text>
        {selectedCompanyId && companiesError === false ? (
          <IM.Dropdown
            placeholder={i18n.t('LOADING_PLACEHOLDER')}
            loading={companyItems.length === 0}
            closeAfterSelecting
            open={companyDropdownOpen}
            value={selectedCompanyId}
            items={companyItems}
            setOpen={setCompanyDropdownOpen}
            setValue={setSelectedCompanyId}
            listMode="SCROLLVIEW"
            containerStyle={styles.dropdown}
            onOpen={onCompanyOpen}
          />
        ) : (
          <IM.Dropdown
            placeholder={companiesError ? i18n.t('ERROR_PLACEHOLDER') : i18n.t('LOADING_PLACEHOLDER')}
            loading={companyItems.length === 0}
            open={false}
            value={null}
            items={companyItems}
            setOpen={setCompanyDropdownOpen}
            setValue={setSelectedCompanyId}
            listMode="SCROLLVIEW"
            containerStyle={styles.dropdown}
            disabled
          />
        )}
      </IM.View>

      <Separator style={styles.divider} />

      <IM.View style={[styles.row, styles.divider]}>
        <IM.Text>{i18n.t('SETTINGS_SUPPORT')}</IM.Text>
        <IM.TeamViewerButton style={styles.teamViewer} />
      </IM.View>

      <Separator style={styles.divider} />

      <IM.View style={styles.space}>
        <IM.Text secondary>{i18n.t('SETTINGS_VERSION')}:</IM.Text>
        <IM.Text secondary>
          {Platform.OS === 'web' ? CONSTANTS.appName : DeviceInfo.getApplicationName()} v
          {Platform.OS === 'web' ? process.env.VERSION : DeviceInfo.getVersion()}
        </IM.Text>
      </IM.View>

      {apiVersion && (
        <IM.View style={styles.space}>
          <IM.Text secondary>{i18n.t('SETTINGS_API_VERSION')}:</IM.Text>
          <IM.Text secondary>v{apiVersion}</IM.Text>
        </IM.View>
      )}

      <IM.View style={styles.space}>
        <IM.Text secondary>{i18n.t('SETTINGS_LICENSE')}:</IM.Text>
        <IM.View style={[IMLayout.flex.f4, styles.alignEnd, IMLayout.flex.row, styles.logoContainer]}>
          <IM.Text secondary style={styles.text}>
            {LicenseGlobals.license}
          </IM.Text>
          {aadAuth !== undefined && aadAuth.state === 'success' ? (
            <IM.View style={styles.microsoftLogo}>
              <MicrosoftLogo />
            </IM.View>
          ) : (
            <>
              {colorScheme === 'light' ? (
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                <Image source={require('../assets/img/InfomindsLogoDark.png')} style={styles.infomindsLogo} />
              ) : (
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                <Image source={require('../assets/img/InfomindsLogoLight.png')} style={styles.infomindsLogo} />
              )}
            </>
          )}
        </IM.View>
      </IM.View>

      <IM.View style={styles.space}>
        <IM.Text secondary>{i18n.t('SETTINGS_SERVER')}:</IM.Text>
        <IM.Text secondary style={styles.infoDetail}>
          {LicenseGlobals.baseUrl}
        </IM.Text>
      </IM.View>

      <IM.View style={styles.space}>
        <IM.Text secondary>{i18n.t('USERNAME')}:</IM.Text>
        <IM.Text secondary>{LicenseGlobals.username}</IM.Text>
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  container: { paddingHorizontal: IMLayout.horizontalMargin },
  dropdown: { width: 210 },
  divider: {
    zIndex: -1,
  },
  infoDetail: {
    flex: 1,
    flexWrap: 'wrap',
    textAlign: 'right',
  },
  row: { marginHorizontal: 5, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
  space: { marginHorizontal: 5, flexDirection: 'row', justifyContent: 'space-between' },
  text: { textAlign: 'right' },
  teamViewer: { marginLeft: 2 * IMStyle.layout.horizontalMargin, maxWidth: 130, flex: 1 },
  microsoftLogo: { width: 12, aspectRatio: 1, marginLeft: 4 },
  infomindsLogo: { width: 16, aspectRatio: 1, marginLeft: 4 },
  alignEnd: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  logoContainer: { justifyContent: 'flex-end', alignItems: 'center' },
})
