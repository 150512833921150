import { IM, IMStyle, SpacingProps } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

export type ErrorDisplayProps = {
  error: string | null | undefined | false
  spacing?: SpacingProps
}

export default function ErrorDisplay({ error, spacing }: ErrorDisplayProps) {
  if (!error) return <></>
  return (
    <IM.View style={styles.errorMessageContainer} spacing={spacing}>
      <IM.View spacing="right">
        <IM.Icon icon={['fal', 'triangle-exclamation']} size={18} color={IMStyle.palette.red} />
      </IM.View>
      <IM.Text>{error}</IM.Text>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  errorMessageContainer: { justifyContent: 'center', flexDirection: 'row', alignItems: 'center' },
})
