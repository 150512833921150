import { DefaultTheme, IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { useLicenseHelper } from '@infominds/react-native-license'
import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { Pressable, StyleSheet } from 'react-native'

export default function SettingsHeader() {
  const { i18n } = useLanguage()
  const { theme } = useTheme()
  const navigation = useNavigation()
  const { logout } = useLicenseHelper(navigation)

  const fontColor = DefaultTheme.dark.text

  const handleLogout = () => {
    logout().catch(err => console.error('Logout error', err))
  }

  return (
    <IM.View style={[styles.main]}>
      <IM.Text style={[{ color: fontColor }, styles.text]}>{i18n.t('SETTINGS')}</IM.Text>
      <IM.View style={styles.logoutView}>
        <Pressable onPress={handleLogout} style={{ marginRight: IMStyle.layout.horizontalMargin }}>
          <IM.TextWithIcon
            alignIcon="right"
            icon={['fal', 'person-to-door']}
            iconSize={24}
            iconColor={theme.error}
            style={[styles.logout, { color: theme.error }]}>
            {i18n.t('LOGOUT')}
          </IM.TextWithIcon>
        </Pressable>
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    alignContent: 'flex-end',
    justifyContent: 'space-between',
  },
  logoutView: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  text: {
    fontSize: IMStyle.typography.fontSizeRegular + 4,
    alignSelf: 'flex-end',
  },
  logout: { fontSize: IMStyle.typography.fontSizeRegular, color: 'red' },
})
