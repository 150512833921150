/* eslint-disable react-native/no-inline-styles */

import { IM, IMStyle, SpacingProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import TimeUtils from '../utils/TimeUtils'

interface DurationDisplayProps {
  name: string
  color?: string
  duration: number
  durationUnit?: 'minutes' | 'seconds'
  spacing?: SpacingProps
  formatTimeWithDimensions?: boolean
  primary?: boolean
}

export default function DurationDisplay({
  name,
  color,
  duration,
  durationUnit = 'minutes',
  spacing,
  formatTimeWithDimensions,
  primary,
}: DurationDisplayProps) {
  const durationText = useMemo(() => {
    if (durationUnit === 'minutes') return TimeUtils.formatDurationMinutes(duration, formatTimeWithDimensions)
    return TimeUtils.formatDurationSeconds(duration, formatTimeWithDimensions)
  }, [duration, durationUnit, formatTimeWithDimensions])

  return (
    <IM.View style={styles.textRow} spacing={spacing}>
      <IM.View style={styles.documentName}>
        {!!color && <IM.Icon icon={['fas', 'circle']} size={IMStyle.typography.fontSizeSmall} color={color} spacing="right" />}
        <IM.View style={{ flex: 1 }}>
          {/* @ts-ignore ok on web */}
          <IM.Text primary={primary} numberOfLines={1} style={Platform.select({ web: { display: 'inherit' } })}>
            {name}
          </IM.Text>
        </IM.View>
      </IM.View>
      <IM.View style={styles.durationView}>
        <IM.Text primary={primary} style={styles.text}>
          {durationText}
        </IM.Text>
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  documentName: { flexDirection: 'row', alignItems: 'center', flex: 1 },
  text: { textAlign: 'right', flex: 1 },
  textRow: { flexDirection: 'row', justifyContent: 'space-between' },
  durationView: { marginLeft: 10, minWidth: 65, alignItems: 'flex-end' },
})
