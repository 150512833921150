import { IM } from '@infominds/react-native-components'
import React from 'react'

export default function SkeletonText() {
  return (
    <IM.SkeletonContainer height={15}>
      <IM.Rect x="0" y="0" rx="5" ry="5" width="120" height="15" />
    </IM.SkeletonContainer>
  )
}
