import { IM, ViewProps } from '@infominds/react-native-components'
import React from 'react'

import useIsKeyboardVisible from './hooks/useIsKeyboardVisible'

export type KeyboardEludingViewProps = {
  eludeKeyboard?: boolean
} & ViewProps

export default function KeyboardEludingView({ eludeKeyboard = true, ...viewProps }: KeyboardEludingViewProps) {
  const keyboardVisible = useIsKeyboardVisible()
  const hide = keyboardVisible && !!eludeKeyboard

  if (hide) return <></>
  return <IM.View {...viewProps} />
}
