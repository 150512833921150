import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import React from 'react'

import PresenceTimeActivitiesCard from '../../cards/PresenceTime/PresenceTimeActivitiesCard'
import PresenceTimeHourCard from '../../cards/PresenceTime/PresenceTimeHourCard'
import { usePresenceTime } from '../../contexts/PresenceTimeContext'
import useLayout from '../../hooks/useLayout'

export default function PresenceTimeInfoView({ forceSmallLayout }: { forceSmallLayout?: boolean }) {
  const presenceTime = usePresenceTime()
  const { isLargeDevice } = useLayout(forceSmallLayout && 'small')
  const { i18n } = useLanguage()

  if (!presenceTime.presenceTimeEntries.length) return <></>

  return (
    <IM.View spacing={['horizontal', 'top']}>
      <IM.View style={IMLayout.flex.row} spacing={['bottom']}>
        {!!presenceTime.presenceTimeInfo?.minutesTarget && (
          <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row]}>
            <PresenceTimeHourCard title={i18n.t('TARGET_HOURS')} timeM={presenceTime.presenceTimeInfo.minutesTarget} />
          </IM.View>
        )}
        {!!presenceTime.presenceTimeInfo?.minutesTimeCompensation && (
          <PresenceTimeHourCard
            spacing={presenceTime.presenceTimeInfo?.minutesVacation ? 'right' : 'none'}
            title={i18n.t('TIME_COMPENSATION')}
            timeM={presenceTime.presenceTimeInfo.minutesTimeCompensation}
          />
        )}
        {!!presenceTime.presenceTimeInfo?.minutesVacation && (
          <PresenceTimeHourCard title={i18n.t('VACATION')} timeM={presenceTime.presenceTimeInfo.minutesVacation} />
        )}
      </IM.View>
      {(!!isLargeDevice || !!presenceTime.presenceTimeInfo?.activityTimes?.length) && <PresenceTimeActivitiesCard />}
    </IM.View>
  )
}
