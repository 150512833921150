import React, { createContext, ReactNode, useCallback, useMemo, useState } from 'react'

export enum UpdateSources {
  CALENDAR_INIT = 'calendarInit',
  DAY_PRESS = 'dayPress',
  WEEK_SCROLL = 'weekScroll',
  PICKER_SELECT = 'pickerSelect',
}

export interface CalendarContextProps {
  date: Date
  today: Date
  setDate: (date: Date, updateSource: UpdateSources) => void
  setToday: () => void
  updateSource: UpdateSources
}

export const CalendarContext = createContext<CalendarContextProps>({
  date: new Date(),
  today: new Date(),
  setDate: () => {
    return
  },
  setToday: () => {
    return
  },
  updateSource: UpdateSources.CALENDAR_INIT,
})

export const CalendarProvider = ({ children }: { children: ReactNode; storeSelectedDate?: boolean }) => {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [todayDate, setTodayDate] = useState(new Date())
  const [updateSource, setUpdateSource] = useState(UpdateSources.CALENDAR_INIT)

  const _setDate = useCallback((date: Date, updateSrc: UpdateSources) => {
    setUpdateSource(updateSrc)
    setCurrentDate(date)
  }, [])

  const setToday = () => {
    setTodayDate(new Date())
  }

  const contextValue = useMemo(() => {
    return {
      date: currentDate,
      today: todayDate,
      updateSource: updateSource,
      setDate: _setDate,
      setToday: setToday,
    }
  }, [currentDate, todayDate, updateSource])

  return <CalendarContext.Provider value={contextValue}>{children}</CalendarContext.Provider>
}
