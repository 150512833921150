import { IM, SpacingProps, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import type { EmployeeTimeType } from '../apis/types/apiResponseTypes'
import { CalculateInitials } from '../utils/Utils'

interface ActivityCardProps {
  spacing?: SpacingProps
  activity: EmployeeTimeType | undefined
  onPress?: () => void
  selected?: boolean
  editable?: boolean
}

export default function ActivityCard({ spacing, activity, onPress, selected, editable }: ActivityCardProps) {
  const { theme, themeUtils } = useTheme()

  const headText = useMemo(() => activity?.abbreviation?.toUpperCase() ?? CalculateInitials(activity?.code ?? ''), [activity])
  const headBackground = useMemo(() => themeUtils.getRandomColorFromTheme(activity?.code ?? ''), [activity])

  if (!activity) return <></>
  return (
    <IM.Card
      spacing={spacing}
      head={{ text: headText, backGroundColor: headBackground }}
      defaultContent={{
        texts: [{ text: activity.code, primary: true }, !!activity.description && { text: activity.description, secondary: true }],
      }}
      onPress={onPress}
      borderless={selected}
      buttons={
        !!selected && !!editable
          ? { buttons: [{ icon: ['fal', 'pen-to-square'], backGroundColor: 'transparent', fontColor: theme.textDetail }] }
          : undefined
      }
    />
  )
}
