import { IM, Spacing, useTheme } from '@infominds/react-native-components'
import React, { ReactNode, useMemo } from 'react'
import { ImageBackground, Platform, SafeAreaView, StatusBar, StyleSheet } from 'react-native'

export type ScreenProps = {
  children?: ReactNode
  spacing?: Spacing
  screenHeader?: JSX.Element
  hideBackgroundImage?: boolean
}

export default function Screen({ spacing, children, screenHeader, hideBackgroundImage }: ScreenProps) {
  const { theme, colorScheme } = useTheme()

  const backImg = require(`../../assets/img/ellipse10.png`) as number //eslint-disable-line @typescript-eslint/no-var-requires
  const background = useMemo(() => (hideBackgroundImage ? 'transparent' : theme.background), [theme])
  const hideImage = useMemo(() => (hideBackgroundImage ? 0 : colorScheme === 'dark' ? 0.8 : 1), [colorScheme])

  return (
    <ImageBackground
      source={backImg}
      imageStyle={{
        opacity: hideImage,
      }}
      style={[styles.flex, { backgroundColor: background }]}>
      <SafeAreaView style={[styles.flex, screenHeader && !hideBackgroundImage && styles.header]}>
        {screenHeader}
        <IM.View spacing={spacing} style={[styles.flex, { backgroundColor: theme.background }]}>
          {children}
        </IM.View>
      </SafeAreaView>
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  header: { paddingTop: Platform.OS === 'android' ? Math.round(StatusBar.currentHeight ?? 0) : 0 },
})
