import { IM, IMStyle, ItemSelector, useLanguage, useModalController, Utils } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import type { Employee } from '../../apis/types/apiResponseTypes'
import ButtonBadge from '../../components/Badges/ButtonBadge'
import EmployeeBadge from '../../components/Badges/EmployeeBadge'
import IMContentCard from '../../components/Card/IMContentCard'
import useUserSettings from '../../hooks/useUserSettings'
import PersonalSelectionModal from '../../modals/PersonalSelectionModal'

export interface PersonalSelectionViewProps {
  disabled?: boolean
  reducedView?: boolean
  employeeSelector: ItemSelector<Employee>
}

export default function PersonalSelectionView({ disabled, reducedView, employeeSelector }: PersonalSelectionViewProps) {
  const { i18n } = useLanguage()
  const userSettings = useUserSettings()

  const controller = useModalController()
  const allEmployees = useMemo(() => userSettings?.subordinates ?? [], [])

  const employeesToShow: Employee[] = []
  if (userSettings?.employee) employeesToShow.push(userSettings.employee)
  employeesToShow.push(...employeeSelector.items)

  return (
    <IMContentCard title={i18n.t('Personal')} spacing="all">
      <IM.View spacing={['horizontal', 'top']}>
        <IM.View style={styles.content}>
          {!!employeesToShow.length && (
            <IM.Grid
              style={styles.grid}
              maxNumberOfRows={reducedView ? 1 : undefined}
              elementWidth={65 + IMStyle.layout.horizontalMargin} //BadgeSize 65 + DefaultMargin 8
              data={employeesToShow}
              renderItem={(employee, index) => (
                <EmployeeBadge
                  spacing={['right', 'bottom']}
                  key={`EmployeeBadge${employee.id}`}
                  employee={employee}
                  onPress={disabled || index === 0 ? undefined : () => employeeSelector.remove(employee)}
                />
              )}
              hiddenElementsIndicator={HiddenElementsIndicator}
            />
          )}
          {!disabled && <ButtonBadge spacing="bottom" icon={['fal', 'plus']} onPress={() => !controller.isShown && controller.show()} />}
        </IM.View>
      </IM.View>
      <PersonalSelectionModal
        controller={controller}
        allEmployees={allEmployees}
        selectedEmployees={employeeSelector.items}
        setSelectedEmployees={employeeSelector.set}
      />
    </IMContentCard>
  )
}

function HiddenElementsIndicator(elements: Employee[]) {
  const { i18n } = useLanguage()

  if (!elements?.length) return <></>
  return (
    <IM.View style={styles.HiddenElementsIndicator}>
      <IM.Text secondary>{Utils.stringValueReplacer(i18n.t('HiddenElements'), elements.length)}</IM.Text>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  content: {
    flexDirection: 'row',
  },
  grid: {
    flex: 1,
  },
  HiddenElementsIndicator: {
    alignContent: 'center',
    alignItems: 'center',
  },
})
