import { IM, IMStyle, useLanguage } from '@infominds/react-native-components'
import { format } from 'date-fns'
import React from 'react'
import { StyleSheet } from 'react-native'
import { useSetRecoilState } from 'recoil'

import useCalendar from '../../../components/Infominds/Calendar/hooks/useCalendar'
import { HistoryDisplayMode } from '../../../contexts/HistoryContext'
import { historyDisplayMode } from '../../../utils/stateManager'
import appUtils from '../../../utils/Utils'

export interface Dimension {
  height: number
  width: number
}

export default function WeekCalendar() {
  const { language } = useLanguage()
  const { date } = useCalendar()
  const setDisplayMode = useSetRecoilState(historyDisplayMode)

  return (
    <IM.View style={styles.weekCalendarContainer}>
      <IM.View style={styles.dateView}>
        <IM.Text style={[{ color: IMStyle.palette.white, fontSize: IMStyle.typography.fontSizeRegular + 2 }, styles.yearText]}>
          {format(date, 'yyyy', { locale: appUtils.languageToLocale(language) })}
        </IM.Text>
        <IM.Text
          style={{
            color: IMStyle.palette.white,
            fontSize: IMStyle.typography.fontSizeRegular + 2,
          }}>
          {format(date, 'MMMM', { locale: appUtils.languageToLocale(language) })}
        </IM.Text>
      </IM.View>
      <IM.View style={styles.calendarButtonView}>
        <IM.PressableIcon icon={['fal', 'list']} color={IMStyle.palette.white} size={22} onPress={() => setDisplayMode(HistoryDisplayMode.Day)} />
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: IMStyle.palette.grey },
  icon: { alignItems: 'center', height: '100%' },
  weekCalendarContainer: {
    flexDirection: 'row',
    height: 64,
  },
  weekCalendar: { flex: 1 },
  calendarButtonView: {
    justifyContent: 'center',
  },
  dateView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  yearText: {
    marginRight: 10,
  },
})
