import { IM, IMStyle, SpacingProps } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native'

import useTimer from '../hooks/useTimer'
import DateUtils from '../utils/DateUtils'
import TimeUtils from '../utils/TimeUtils'

export type TimerProps = {
  timeS: number
  runTimer?: boolean
  intervalS?: number
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  noSeconds?: boolean
}

export default function Timer({ timeS, runTimer, intervalS, spacing, style, textStyle, noSeconds }: TimerProps) {
  const timer = useTimer(intervalS)

  const time = timer.isRunning ? timer.time : timeS

  useEffect(() => {
    if (!runTimer) {
      timer.stop()
      return
    }
    const currentTime = TimeUtils.getCurrentSeconds()
    timer.setTime(TimeUtils.subtractSeconds(timeS, currentTime))
    timer.start()
  }, [timeS, runTimer])

  return (
    <IM.View spacing={spacing} style={[styles.view, style]}>
      <IM.Text style={[styles.textHoursMinutes, textStyle]}>
        {DateUtils.formatDate(TimeUtils.revertSecondsToDate(time), time < 3600 && !noSeconds ? 'mm' : 'H:mm')}
      </IM.Text>
      {!noSeconds && (
        <IM.Text style={[styles.textSeconds, textStyle]}>{DateUtils.formatDate(TimeUtils.revertSecondsToDate(time % 60), ':ss')}</IM.Text>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
  },
  textHoursMinutes: {
    fontSize: IMStyle.typography.fontSizeBig,
    includeFontPadding: false,
  },
  textSeconds: {
    fontSize: IMStyle.typography.fontSizeBig,
    includeFontPadding: false,
  },
})
