import { differenceInDays } from 'date-fns'

import { PresenceTimeKey } from '../apis/types/apiResponseTypes'
import { PresenceTimesOfDay } from '../contexts/HistoryContext'
import { MarkedDate } from '../types'
import PresenceTimeUtils from './PresenceTimeUtils'

const PresenceTimeCalendarUtils = {
  convertTimesToMarkedDates(presenceTimesOfDay: PresenceTimesOfDay[] | undefined, presenceTimeKeys: PresenceTimeKey[] | undefined) {
    if (!presenceTimesOfDay?.length || !presenceTimeKeys?.length) return []
    const times = presenceTimesOfDay.filter(ptd => !!ptd.times.find(time => !!time.duration) && !!ptd.date)
    times.sort((a, b) => a.date.getTime() - b.date.getTime())
    return times.map((time, index) => {
      const markedDate: MarkedDate = { date: time.date, type: PresenceTimeUtils.getDayKeyType(time.times, presenceTimeKeys) }
      if (
        index === 0 ||
        differenceInDays(time.date, times[index - 1].date) > 1 ||
        PresenceTimeUtils.getDayKeyType(times[index - 1].times, presenceTimeKeys) !== markedDate.type
      ) {
        markedDate.startingDay = true
      }
      if (
        index === times.length - 1 ||
        differenceInDays(times[index + 1].date, time.date) > 1 ||
        PresenceTimeUtils.getDayKeyType(times[index + 1].times, presenceTimeKeys) !== markedDate.type
      ) {
        markedDate.endingDay = true
      }
      return markedDate
    })
  },
}

export default PresenceTimeCalendarUtils
