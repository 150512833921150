import { IM, SpacingProps, useTheme } from '@infominds/react-native-components'
import React from 'react'

import type { Employee } from '../apis/types/apiResponseTypes'
import EmployeeUtils from '../utils/EmployeeUtils'

interface EmployeeCardProps {
  spacing?: SpacingProps
  employee: Employee | undefined
  onPress?: () => void
  showSelector?: boolean
  selected?: boolean
  disabled?: boolean
}

export default function EmployeeCard({ spacing, employee, onPress, selected, showSelector, disabled }: EmployeeCardProps) {
  const { theme, themeUtils } = useTheme()

  const code = EmployeeUtils.getInitials(employee)
  const name = EmployeeUtils.getName(employee)

  if (!employee) return <></>
  return (
    <IM.Card
      spacing={spacing}
      head={{ text: code, backGroundColor: themeUtils.getRandomColorFromTheme(code) }}
      defaultContent={{
        texts: [
          { text: name, primary: true },
          { text: employee.code, secondary: true },
        ],
      }}
      onPress={onPress}
      disabled={disabled}
      selector={showSelector ? { icon: 'circle-check', selectedColor: theme.primary, isSelected: selected, centered: true } : undefined}
    />
  )
}
