import { IM } from '@infominds/react-native-components'
import React from 'react'

import WeekCalendar from '../../components/Infominds/Calendar/WeekCalendar'
import { ScreenHeader } from '../../components/ScreenHeader'
import { HistoryDisplayMode, useHistory } from '../../contexts/HistoryContext'
import HistoryCalendarView from '../../views/History/HistoryCalendarView'
import HistoryDayView from '../../views/History/HistoryDayView'
import HistoryCalendarHeader from './Header/HistoryCalendarHeader'

export default function HistoryScreen() {
  const { displayMode } = useHistory()

  return (
    <IM.Screen
      screenHeader={<ScreenHeader barContent={displayMode === HistoryDisplayMode.Day ? <WeekCalendar /> : <HistoryCalendarHeader />} />}
      spacing="none">
      {displayMode === HistoryDisplayMode.Day && <HistoryDayView />}
      {displayMode === HistoryDisplayMode.Month && <HistoryCalendarView />}
    </IM.Screen>
  )
}
