import { IM, IMStyle, useTheme } from '@infominds/react-native-components'
import Color from 'color'
import React from 'react'
import { Pressable, PressableProps, StyleSheet } from 'react-native'

export interface PressableIconProps extends PressableProps {
  title: string
  color?: string
  disabled?: boolean
  onPress?: () => void
  onPressOut?: () => void
}

export default function AlertButton({ title, color, disabled = false, onPress, onPressOut, ...pressableProps }: PressableIconProps) {
  const { theme } = useTheme()

  return (
    <IM.View style={styles.container}>
      <Pressable
        disabled={disabled}
        onPress={onPress}
        onPressOut={onPressOut}
        style={({ pressed }) => [
          styles.pressable,
          {
            backgroundColor: pressed ? theme.button.pressedOpacity : 'transparent',
          },
        ]}
        {...pressableProps}>
        <IM.Text style={{ color: disabled ? Color(IMStyle.palette.grey).lighten(1).toString() : color }}>{title}</IM.Text>
      </Pressable>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    alignSelf: 'flex-start',
  },
  pressable: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
})
