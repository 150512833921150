import { IM, IMStyle, useTheme } from '@infominds/react-native-components'
import Color from 'color'
import React, { useState } from 'react'
import { LayoutRectangle, StyleSheet } from 'react-native'

export type StartStopIconButtonProps = {
  isStarted: boolean
  onStartPressed: () => Promise<void> | void
  onStopPressed: () => Promise<void> | void
  disabled?: boolean
}

export default function StartStopIconButton({ isStarted, onStartPressed, onStopPressed, disabled }: StartStopIconButtonProps) {
  const { theme } = useTheme()
  const [busy, setBusy] = useState(false)
  const [layout, setLayout] = useState<LayoutRectangle | null>(null)

  function handleButtonPressed() {
    setBusy(true)
    Promise.resolve(isStarted ? onStopPressed() : onStartPressed())
      .catch(console.error)
      .finally(() => setBusy(false))
  }

  return (
    <IM.View>
      {busy && (
        <IM.View style={{ width: layout?.width ?? 52, height: layout?.height ?? 52 }}>
          <IM.LoadingSpinner isVisible />
        </IM.View>
      )}
      {!busy && (
        <IM.PressableIcon
          onLayout={e => setLayout(e.nativeEvent.layout)}
          icon={isStarted ? ['fal', 'square'] : ['fal', 'play']}
          size={30}
          onPress={handleButtonPressed}
          disabled={disabled}
          color={IMStyle.palette.white}
          opacityColor={Color(theme.button.pressedOpacity).alpha(0.5).toString()}
          // eslint-disable-next-line react-native/no-inline-styles
          iconStyle={!isStarted && { marginLeft: 4 }}
          style={[styles.button, { backgroundColor: isStarted ? IMStyle.palette.red : IMStyle.palette.tint }]}
        />
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  button: {
    justifyContent: 'center',
    alignContent: 'center',
  },
})
