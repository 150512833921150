import { useTheme } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'

import TabSelector from './TabSelector'

export type PresenceTimeTabItem = {
  name: string
  onClick: () => void
}

type PresenceTimeTabsProps = {
  items: PresenceTimeTabItem[]
  startSelectedIndex?: number
}

export default function PresenceTimeTabs({ items, startSelectedIndex }: PresenceTimeTabsProps) {
  const [selectedIndex, setSelectedIndex] = useState<number>(startSelectedIndex ?? 0)
  const { colorScheme } = useTheme()
  const activeColor = useMemo(() => (colorScheme === 'light' ? '#333B47' : '#424242'), [colorScheme])

  useEffect(() => {
    if (startSelectedIndex) setSelectedIndex(startSelectedIndex)
  }, [])

  function handlePress(index: number) {
    setSelectedIndex(index)
    items[index].onClick()
  }

  return (
    <TabSelector height={34} selectionColor={activeColor} style={styles.container}>
      <>
        {items.map((item, index) => (
          <TabSelector.Item
            key={`${index}_${item.name}`}
            text={item.name}
            selected={index === selectedIndex}
            onPress={() => handlePress(index)}
            disableDivider
          />
        ))}
      </>
    </TabSelector>
  )
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 8,
    marginTop: 12,
  },
})
