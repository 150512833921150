import { IM } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'

import { PresenceTimeKeyType } from '../../../apis/types/apiResponseTypes'
import { useHistory } from '../../../contexts/HistoryContext'
import useCalendarDayColor from '../../../hooks/useCalendarDayColor'
import useIsPresenceTimeEnabled from '../../../hooks/useIsPresenceTimeEnabled'
import PresenceTimeUtils from '../../../utils/PresenceTimeUtils'
import TimeUtils from '../../../utils/TimeUtils'
import type { CalendarContextProps } from './context/CalendarContext'
import Day from './Day'

interface WeekProps {
  weekStart: Date
  width: number
  context: CalendarContextProps
}

type DayColor = {
  date: Date
  keyType: PresenceTimeKeyType | null
}

export default function Week({ weekStart, width, context }: WeekProps) {
  const days = useMemo(() => TimeUtils.getWeek(weekStart), [weekStart])
  const presenceTimeEnabled = useIsPresenceTimeEnabled()
  const [dayColors, setDayColors] = useState<DayColor[]>([])
  const { getPresenceTimesOfDay, presenceTimeKeys, presenceTimesOfDay } = useHistory()
  const { getDayColorByKeyType } = useCalendarDayColor()

  useEffect(() => {
    if (!presenceTimeEnabled) return
    const abortController = new AbortController()
    setDayColors([])
    setDayColors(
      days.map(day => ({ date: day, keyType: PresenceTimeUtils.getDayKeyType(getPresenceTimesOfDay(day) ?? [], presenceTimeKeys ?? []) }) as DayColor)
    )

    return () => abortController.abort()
  }, [days, presenceTimeKeys, presenceTimesOfDay])

  function getDayColor(keyType: PresenceTimeKeyType | null | undefined) {
    if (!presenceTimeEnabled) return null
    return getDayColorByKeyType(keyType)
  }

  return (
    <IM.View style={[styles.week, { width: width }]}>
      {days.map(elem => (
        <Day
          key={elem.getDate()}
          displayDate={elem}
          width={width}
          context={context}
          barColor={getDayColor(dayColors.find(dayColor => elem === dayColor.date)?.keyType)}
        />
      ))}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  week: {
    height: '100%',
    alignItems: 'center',
    paddingVertical: 16,
    flexDirection: 'row',
  },
})
