import { IM } from '@infominds/react-native-components'
import React from 'react'

import { ScreenHeader } from '../../components/ScreenHeader'
import SettingsView from '../../views/SettingsView'
import SettingsHeader from './SettingsHeader'

export default function SettingsScreen() {
  return (
    <IM.Screen screenHeader={<ScreenHeader barContent={<SettingsHeader />} />}>
      <SettingsView />
    </IM.Screen>
  )
}
