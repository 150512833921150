import { IM, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'
import type Toast from 'react-native-toast-notifications'
import { ToastType } from 'react-native-toast-notifications'

import { useToast } from '../contexts/ToastReferenceContext'
import TimeUtils from '../utils/TimeUtils'
import Timer from './Timer'
import TimeSelector from './TimeSelector'

export type FromUntilTimeSelectorProps = {
  from: number
  until: number
  setFrom: (value: number) => void
  setUntil: (value: number) => void
  toastRef?: React.MutableRefObject<Toast | undefined>
  spacing?: SpacingProps
  error?: boolean
  allowDateSkip?: boolean | 'modify'
  disabled?: boolean
  noActiveTime?: boolean
}

export default function TimeSpanSelector({
  from,
  until,
  setFrom,
  setUntil,
  spacing,
  error,
  toastRef,
  allowDateSkip = true,
  disabled,
  noActiveTime,
}: FromUntilTimeSelectorProps) {
  const { i18n } = useLanguage()
  const fromTimeM = useMemo(() => TimeUtils.roundSecondsToMinutes(from), [from])
  const isActiveTime = until === 0 && !noActiveTime
  const untilTimeM = useMemo(() => TimeUtils.roundSecondsToMinutes(until), [until])
  const toast = useToast()

  function handleSetFrom(value: Date) {
    const newFrom = TimeUtils.getSecondsOfDay(value)
    if (!checkInput(newFrom, until)) return
    setFrom(newFrom)
  }

  function handleSetUntil(value: Date) {
    const newUntil = TimeUtils.getSecondsOfDay(value)
    if (!checkInput(from, newUntil)) return
    setUntil(newUntil)
  }

  function checkInput(checkFrom: number, checkUntil: number) {
    if (allowDateSkip || isActiveTime || checkFrom < checkUntil) {
      return true
    }

    let localToast: ToastType | undefined

    if (toastRef) {
      localToast = toastRef.current
    } else {
      localToast = toast
    }

    localToast?.show(i18n.t('ERROR_UNTIL_LT_FROM'), { type: 'warning' })
    return false
  }

  return (
    <IM.View style={styles.timeView} spacing={spacing}>
      <IM.View style={styles.flex}>
        <TimeSelector
          type="start"
          setTime={handleSetFrom}
          time={TimeUtils.revertMinutesToDate(fromTimeM)}
          spacing={'bottom'}
          error={error}
          disabled={disabled}
        />
        {!isActiveTime && (
          <TimeSelector
            type="stop"
            setTime={handleSetUntil}
            time={TimeUtils.revertMinutesToDate(untilTimeM)}
            error={error}
            disabled={until === 0 || disabled}
          />
        )}
      </IM.View>
      <Timer
        spacing="left"
        noSeconds
        style={styles.timer}
        timeS={isActiveTime ? from : 60 * TimeUtils.subtractMinutes(fromTimeM, untilTimeM)}
        runTimer={isActiveTime}
      />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  timeView: {
    flexDirection: 'row',
  },
  timer: {
    justifyContent: 'flex-end',
    alignSelf: 'center',
  },
  flex: {
    flex: 1,
  },
  error: {
    borderWidth: 1,
  },
})
