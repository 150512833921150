import { useEffect, useState } from 'react'
import { EmitterSubscription, Keyboard, Platform } from 'react-native'

export default function useIsKeyboardVisible() {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false)

  useEffect(() => {
    const handleKeyboardShow = () => setIsKeyboardVisible(true)
    const handleKeyboardHide = () => setIsKeyboardVisible(false)

    let subscriptions: EmitterSubscription[]

    if (Platform.OS === 'ios') {
      subscriptions = [Keyboard.addListener('keyboardWillShow', handleKeyboardShow), Keyboard.addListener('keyboardWillHide', handleKeyboardHide)]
    } else {
      subscriptions = [Keyboard.addListener('keyboardDidShow', handleKeyboardShow), Keyboard.addListener('keyboardDidHide', handleKeyboardHide)]
    }

    return () => {
      subscriptions.forEach(s => s.remove())
    }
  }, [])

  return isKeyboardVisible
}
