import debounce from 'lodash/debounce'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { AppState, Platform } from 'react-native'
import { DataProvider, Dimension, LayoutProvider, RecyclerListView, RecyclerListViewProps } from 'recyclerlistview'

import TimeUtils from '../../../utils/TimeUtils'
import { CalendarContext, UpdateSources } from './context/CalendarContext'
import Week from './Week'

const WEEK_SPAN = 27

interface WeekListProps {
  pageWidth: number
  height: number
}

interface RecyclerListViewState {
  renderStack: RenderStack
  internalSnapshot: Record<string, object>
}

interface RenderStackItem {
  dataIndex?: number
}

export interface RenderStack {
  [key: string]: RenderStackItem
}

export interface ScrollEvent {
  nativeEvent: {
    contentOffset: {
      x: number
      y: number
    }
    layoutMeasurement?: Dimension
    contentSize?: Dimension
  }
}

function WeekList({ pageWidth, height }: WeekListProps) {
  const listRef = useRef<RecyclerListView<RecyclerListViewProps, RecyclerListViewState> | null>(null)
  const pageIndex = useRef<number | undefined>(undefined)
  const scrolledByUser = useRef(false)
  const isOnEdge = useRef(false)
  const layoutProvider = useMemo(
    () =>
      new LayoutProvider(
        index => {
          return index
        },
        (_type, dim) => {
          dim.width = pageWidth
          dim.height = height
        }
      ),
    [pageWidth, height]
  )

  const context = useContext(CalendarContext)
  const { date, updateSource, setToday, setDate } = context

  const [loadedWeeks, setLoadedWeeks] = useState(TimeUtils.getWeekStart(new Date(), WEEK_SPAN))

  const dataProvider = useMemo(
    () =>
      new DataProvider((r1: Date, r2: Date) => {
        return r1 !== r2
      }).cloneWithRows(loadedWeeks),
    [loadedWeeks]
  )

  useEffect(() => {
    const unsubscribe = AppState.addEventListener('change', nextState => {
      if (nextState === 'active') {
        setToday()
      }
    })

    return () => {
      unsubscribe.remove()
    }
  }, [])

  useEffect(() => {
    setTimeout(
      () => {
        const x = Math.floor(loadedWeeks.length / 2) * pageWidth
        listRef.current?.scrollToOffset(x, 0, false, false)
      },
      updateSource === UpdateSources.CALENDAR_INIT ? 100 : 0
    )
  }, [loadedWeeks, pageWidth])

  useEffect(() => {
    if (updateSource === UpdateSources.PICKER_SELECT) {
      setLoadedWeeks(TimeUtils.getWeekStart(date, WEEK_SPAN))
    }
  }, [date])

  function reloadPages(index: number) {
    const newDate = loadedWeeks[index]
    newDate && setLoadedWeeks(TimeUtils.getWeekStart(newDate, WEEK_SPAN))
  }

  const reloadPagesDebounce = useCallback(debounce(reloadPages, 200, { leading: false, trailing: true }), [reloadPages])

  function onScrollBeginDrag() {
    scrolledByUser.current = true
  }

  function onMomentumScrollEnd() {
    if (scrolledByUser.current === true) {
      scrolledByUser.current = false

      if (pageIndex.current !== undefined && isOnEdge.current) {
        reloadPagesDebounce(pageIndex.current)
      }
    }
  }

  function onScroll(event: ScrollEvent, _offsetX: number, _offsetY: number) {
    reloadPagesDebounce?.cancel()

    if (updateSource === UpdateSources.PICKER_SELECT) {
      setDate(date, UpdateSources.WEEK_SCROLL)
      return
    }

    const { x } = event.nativeEvent.contentOffset
    const newPageIndex = Math.round(x / pageWidth)

    if (pageIndex.current !== newPageIndex) {
      if (pageIndex.current !== undefined) {
        const newDate = loadedWeeks[newPageIndex]

        if (newDate) {
          setDate(newDate, UpdateSources.WEEK_SCROLL)
          isOnEdge.current = false

          if (newPageIndex === 0 || newPageIndex === loadedWeeks.length - 1) {
            isOnEdge.current = true
          }
        }
      }

      pageIndex.current = newPageIndex
    }
  }

  function rowRenderer(_type: string | number, data: Date) {
    return <Week weekStart={data} width={pageWidth} context={context} />
  }

  return (
    <RecyclerListView
      ref={listRef}
      layoutProvider={layoutProvider}
      dataProvider={dataProvider}
      rowRenderer={rowRenderer}
      onScroll={onScroll}
      scrollViewProps={{
        pagingEnabled: true,
        bounces: false,
        showsHorizontalScrollIndicator: false,
        scrollEnabled: !(Platform.OS === 'web'),
        onScrollBeginDrag,
        onMomentumScrollEnd,
      }}
      isHorizontal
      initialRenderIndex={Math.floor(WEEK_SPAN / 2)}
    />
  )
}

export default WeekList
