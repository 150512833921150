import { IM, IMLayout, SpacingProps, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { Platform, Pressable, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { usePresenceTime } from '../../contexts/PresenceTimeContext'
import { useToast } from '../../contexts/ToastReferenceContext'
import useUserSettings from '../../hooks/useUserSettings'
import { AdditionalRepaymentEntry } from '../../types'
import PresenceTimeUtils from '../../utils/PresenceTimeUtils'
import { CalculateInitials } from '../../utils/Utils'

interface AdditionalRepaymentKeyCardProps {
  spacing?: SpacingProps
  additionalRepayment: AdditionalRepaymentEntry | undefined
}

export default function AdditionalRepaymentKeyCard({ spacing, additionalRepayment }: AdditionalRepaymentKeyCardProps) {
  const { theme, themeUtils } = useTheme()
  const presenceTime = usePresenceTime()
  const userSettings = useUserSettings()
  const toast = useToast()
  const alert = useAlert()
  const { i18n } = useLanguage()

  const [busy, setBusy] = useState(false)

  const headText = useMemo(() => CalculateInitials(additionalRepayment?.key.code ?? '', Platform.OS === 'web' ? 2 : 3, true), [additionalRepayment])
  const headBackground = useMemo(() => themeUtils.getRandomColorFromTheme(additionalRepayment?.key.code ?? ''), [additionalRepayment])
  const isActive = useMemo(() => additionalRepayment?.additionalRepayment !== undefined, [additionalRepayment])

  function handleCardPressed() {
    if (!additionalRepayment) return
    setBusy(true)

    //if Additional Repayment not active, activate it
    if (!isActive) {
      PresenceTimeUtils.createAdditionalRepayment(userSettings?.employee.id ?? '', additionalRepayment?.key.id ?? '', presenceTime.date)
        .then(() => presenceTime.load())
        .catch(() => handleError('create'))
        .finally(() => {
          setBusy(false)
        })
    }
    //else delete it
    else {
      if (additionalRepayment.additionalRepayment) {
        api
          .deleteAdditionalRepayment(additionalRepayment.additionalRepayment.id)
          .then(() => presenceTime.load())
          .catch(() => handleError('delete'))
          .finally(() => setBusy(false))
      } else {
        handleError('delete')
        setBusy(false)
      }
    }
  }

  function handleError(type: 'create' | 'delete', message?: string) {
    if (!message) {
      switch (type) {
        case 'create':
          toast.show(i18n.t('ERROR_ADDITIONAL_REPAYMENT_CREATE'), { type: 'danger' })
          break
        case 'delete':
          toast.show(i18n.t('ERROR_ADDITIONAL_REPAYMENT_DELETE'), { type: 'danger' })
          break
      }
      return
    }
    alert.alert(i18n.t('ALERT_ERROR_TITLE'), message)
  }

  if (!additionalRepayment) return <></>
  return (
    <IM.View spacing={spacing}>
      <IM.Card
        head={{ text: headText, backGroundColor: headBackground }}
        defaultContent={{
          texts: [{ text: additionalRepayment.key.description, primary: true }],
        }}
        onPress={handleCardPressed}
        endComponent={
          <Pressable style={styles.endComponent} onPress={handleCardPressed}>
            {busy && <IM.LoadingSpinner isVisible />}
            {!busy && (
              <IM.View>
                <IM.Icon icon={['fas', 'circle-check']} color={isActive ? theme.primary : theme.textDetail} size={22} />
              </IM.View>
            )}
          </Pressable>
        }
      />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  endComponent: {
    borderTopRightRadius: IMLayout.borderRadius,
    borderBottomRightRadius: IMLayout.borderRadius,
    justifyContent: 'center',
    alignItems: 'center',
    width: 60, // hardcoded for web (since primary text needs more space in web)
  },
})
