import InfomindsAnalytics from '@infominds/react-native-analytics'
import { IM, IMStyle } from '@infominds/react-native-components'
import { AuthenticationContainer } from '@infominds/react-native-license'
import Analytics from 'appcenter-analytics'
import React, { useEffect } from 'react'
import { LogBox, Platform, StatusBar, StyleSheet } from 'react-native'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import SplashScreen from 'react-native-splash-screen'
import { ToastProvider } from 'react-native-toast-notifications'
import { RecoilEnv, RecoilRoot } from 'recoil'

import { languageResources } from './assets/languages/LanguageResources'
import AppContent from './components/Infominds/AppContent'
import { CalendarProvider } from './components/Infominds/Calendar/context/CalendarContext'
import { CONSTANTS } from './constants/Constants'
import { firebaseConfig } from './constants/FirebaseConfigWeb'
import { ActivitiesContextProvider } from './contexts/ActivitiesContext'
import { UserSettingsProvider } from './contexts/UserSettingsContext'
import devEnvironments from './devEnvironments'
import InitStructures, { themeExpansion } from './InitStructures'
import Navigator from './navigation/Navigator'
import versionCheck from './versions.json'

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = !__DEV__

export default function App() {
  useEffect(() => {
    InfomindsAnalytics.init(firebaseConfig)

    if (Platform.OS !== 'web') {
      LogBox.ignoreAllLogs()
      Analytics.setEnabled(devEnvironments.appCenterAnalytics).catch(err => console.error(err))
    }

    if (Platform.OS !== 'web') SplashScreen.hide()
  }, [])

  return (
    <RecoilRoot>
      <InitStructures />
      <CalendarProvider>
        <IM.ThemeProvider theme={themeExpansion}>
          <AppContent>
            <IM.LanguageProvider resources={languageResources}>
              <IM.LanguageContext.Consumer>
                {languageContext => {
                  if (!languageContext?.initOk) return <IM.LoadingSpinner isVisible />

                  return (
                    <ToastProvider
                      placement="bottom"
                      duration={CONSTANTS.toastVisibilityTime}
                      offsetBottom={IMStyle.layout.verticalMargin * (Platform.OS === 'ios' ? 4 : 2)}
                      offset={20}
                      swipeEnabled={true}>
                      <IM.AlertProvider>
                        <IM.AlertContext.Consumer>
                          {alertContext => (
                            <UserSettingsProvider>
                              <ActivitiesContextProvider>
                                <AuthenticationContainer
                                  aadEnabled
                                  isSinglePageApp
                                  language={languageContext.language}
                                  versionsFile={versionCheck}
                                  onVersionCheckEnd={({ title, message }) => alertContext?.alert(title, message)}>
                                  <SafeAreaProvider>
                                    <IM.View style={styles.screen}>
                                      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
                                      <Navigator />
                                    </IM.View>
                                  </SafeAreaProvider>
                                </AuthenticationContainer>
                              </ActivitiesContextProvider>
                            </UserSettingsProvider>
                          )}
                        </IM.AlertContext.Consumer>
                      </IM.AlertProvider>
                    </ToastProvider>
                  )
                }}
              </IM.LanguageContext.Consumer>
            </IM.LanguageProvider>
          </AppContent>
        </IM.ThemeProvider>
      </CalendarProvider>
    </RecoilRoot>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  screen: {
    height: '100%',
    justifyContent: 'center',
  },
})
