import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { Pressable, StyleSheet } from 'react-native'

import appUtils from '../../../utils/Utils'
import DatePicker from './DatePicker'
import useCalendar from './hooks/useCalendar'

export default function DateSelector({ disabled }: { disabled?: boolean }) {
  const { theme } = useTheme()
  const { date } = useCalendar()
  const { language } = useLanguage()

  const [show, setShow] = useState(false)

  return (
    <Pressable
      disabled={disabled}
      onPress={() => setShow(true)}
      style={({ pressed }) => [
        {
          borderRadius: IMStyle.layout.borderRadius,
          marginVertical: IMStyle.layout.verticalMargin,
          backgroundColor: pressed ? theme.button.pressedOpacity : 'transparent',
          minWidth: '8%',
          marginRight: 4,
        },
      ]}>
      <IM.View spacing="horizontal" style={styles.view}>
        <IM.Text style={{ color: IMStyle.palette.white }}>{format(date, 'yyyy', { locale: appUtils.languageToLocale(language) })}</IM.Text>
        <IM.Text
          style={{
            color: IMStyle.palette.white,
            fontSize: IMStyle.typography.fontSizeRegular,
          }}>
          {format(date, 'LLL', { locale: appUtils.languageToLocale(language) })}
        </IM.Text>
      </IM.View>
      <DatePicker show={show} setShow={setShow} />
    </Pressable>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
