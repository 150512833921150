import { IMStyle, useModalController } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import type { TimeCardProps } from '../cards/TimeCard'
import TimeCard from '../cards/TimeCard'
import TimePicker from './Infominds/TimePicker'

export type TimeSelectorProps = {
  setTime: (time: Date) => void
  disabled?: boolean
  error?: boolean
}

export default function TimeSelector({ time, setTime, disabled, onPress, error, style, ...cardProps }: TimeSelectorProps & TimeCardProps) {
  const controller = useModalController<{ time: Date }>()

  function handleCardPress() {
    if (onPress) onPress()
    controller.show({ time: time })
  }

  return (
    <>
      <TimeCard time={time} disabled={disabled} onPress={handleCardPress} style={[!!error && styles.error, style]} {...cardProps} />
      <TimePicker controller={controller} setTime={setTime} />
    </>
  )
}

const styles = StyleSheet.create({
  error: {
    borderWidth: 1,
    borderColor: IMStyle.palette.red,
  },
})
