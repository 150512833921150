import InfomindsAnalytics from '@infominds/react-native-analytics'

import api from '../apis/apiCalls'
import type { Document, Employee, EmployeeTime, EmployeeTimeType } from '../apis/types/apiResponseTypes'
import type { LastUsedTimeData } from '../types'
import TimeUtils from './TimeUtils'

const EmployeeUtils = {
  getInitials(employee?: Employee) {
    if (!employee) return ''
    if (employee.firstName && employee.lastName) return (employee.firstName.substring(0, 1) + employee.lastName.substring(0, 1)).toUpperCase()
    return employee.code?.substring(0, 2).toUpperCase()
  },
  getName(employee?: Employee) {
    if (!employee) return ''
    let result = ''
    if (employee.firstName) result += employee.firstName + ' '
    if (employee.lastName) result += employee.lastName
    return result
  },
  createEmployeeTypeFromEmployeeTime(employeeTime: EmployeeTime): EmployeeTimeType {
    return {
      id: employeeTime.employeeTimeTypeId,
      code: employeeTime.employeeTimeType,
      description: '',
      documentNecessary: !!employeeTime.documentId,
    }
  },
  async getUpdatedLastUsedTimeData(item: LastUsedTimeData) {
    if (!item) return undefined
    try {
      const result = await Promise.all([
        api.getEmployeeTimeTypes({ code: item.activity.code }),
        item.document ? api.getDocuments({ id: item.document.id, documentType: item.document.documentType }) : undefined,
      ])
      if (!result || !result[0].length || (item.document && !result[1]?.length)) return undefined
      return { activity: result[0][0], document: result[1]?.length ? result[1][0] : undefined } as LastUsedTimeData
    } catch (exception) {
      console.error(exception)
    }
    return false
  },
  async createTime(
    employeeId: string,
    date: Date,
    from: number,
    until: number,
    activity: EmployeeTimeType,
    document?: Document,
    subordinates?: Employee[],
    note?: string,
    latitude?: number,
    longitude?: number
  ) {
    const result = await api.postEmployeeTime({
      date: TimeUtils.getDateForRequest(date),
      employeeId: employeeId,
      employeeTimeTypeId: activity?.id,
      from: from,
      until,
      documentId: document?.id,
      documentType: document?.documentType,
      pspDetailId: document?.pspDetailId ?? undefined,
      note,
      subordinateIds: subordinates?.map(e => e.id),
      latitude,
      longitude,
    })
    InfomindsAnalytics.logCustomEvent('new_time')
    return result
  },
}

export default EmployeeUtils
