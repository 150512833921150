import { IM, ModalFullScreenProps, Spacing, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { KeyboardAvoidingView, Platform, SafeAreaView, StatusBar, StyleSheet, useWindowDimensions } from 'react-native'
import DefaultModal from 'react-native-modal'
import Toast from 'react-native-toast-notifications'

import useLayout from '../../hooks/useLayout'
import ModalFullScreen from './ModalFullScreen'

interface Props {
  screenHeader?: JSX.Element
  spacing?: Spacing
  toastRef?: React.MutableRefObject<Toast | undefined>
  backdropOpacity?: number
}

export default function Modal({
  children,
  statusBarTranslucent,
  screenHeader,
  backdropOpacity,
  spacing,
  toastRef,
  ...props
}: ModalFullScreenProps & Props) {
  const { theme } = useTheme()
  const { isSmallDevice } = useLayout()
  const { height, width } = useWindowDimensions()

  const useNativeDriver = Platform.OS === 'android'

  return (
    <>
      {isSmallDevice ? (
        <ModalFullScreen statusBarTranslucent={statusBarTranslucent} useNativeDriver={useNativeDriver} {...props}>
          <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={styles.keyboardAvoidingView}>
            <IM.Screen screenHeader={screenHeader} spacing={spacing}>
              {children}
            </IM.Screen>
            <Toast
              ref={ref => {
                if (ref && toastRef) {
                  toastRef.current = ref
                }
              }}
            />
          </KeyboardAvoidingView>
        </ModalFullScreen>
      ) : (
        <DefaultModal
          backdropOpacity={backdropOpacity ?? 0.4}
          propagateSwipe
          statusBarTranslucent={statusBarTranslucent}
          swipeDirection={[]}
          useNativeDriver={useNativeDriver}
          style={styles.modal}
          deviceHeight={height}
          deviceWidth={width}
          animationIn="slideInRight"
          animationOut="slideOutRight"
          onBackdropPress={props.onClose}
          onBackButtonPress={props.onClose}
          {...props}>
          <SafeAreaView style={[styles.flex, styles.header]}>
            <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={styles.keyboardAvoidingView}>
              {screenHeader ? (
                <>
                  {screenHeader}
                  <IM.View
                    style={[
                      styles.flex,
                      {
                        backgroundColor: theme.background,
                      },
                    ]}
                    spacing={spacing}>
                    {children}
                  </IM.View>
                </>
              ) : (
                <>{children}</>
              )}
              <Toast
                ref={ref => {
                  if (ref && toastRef) {
                    toastRef.current = ref
                  }
                }}
              />
            </KeyboardAvoidingView>
          </SafeAreaView>
        </DefaultModal>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  header: { paddingTop: Platform.OS === 'android' ? Math.round(StatusBar.currentHeight ?? 0) : 0 },
  keyboardAvoidingView: {
    height: '100%',
  },
  modal: {
    alignSelf: 'flex-end',
    height: '100%',
    width: '50%',
    margin: 0,
  },
})
