import InfomindsAnalytics from '@infominds/react-native-analytics'
import { useAlert, useLanguage, Utils } from '@infominds/react-native-components'
import { Asset, assetManagementUtils, BackendAsset, Media, MediaSortingMethod, mediaUtils } from '@infominds/react-native-media'
import { useNavigation } from '@react-navigation/native'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Platform, StyleSheet } from 'react-native'
import DeviceInfo from 'react-native-device-info'
import { OrientationType } from 'react-native-orientation-locker'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'

import api from '../../apis/apiCalls'
import { FolderRight, type Document, type File, type Folder } from '../../apis/types/apiResponseTypes'
import NoEntry from '../../components/NoEntry'
import UploadModal from '../../components/UploadModal'
import { useToast } from '../../contexts/ToastReferenceContext'
import useLayout from '../../hooks/useLayout'
import {
  assetsToSyncAtom,
  assetUploadVisibleAtom,
  documentCountAtom,
  mediaSortingMethodInfoboxAtom,
  saveMediaOnDeviceAtom,
  uploadCountAtom,
} from '../../utils/stateManager'
import appUtils from '../../utils/Utils'

interface Props {
  document: Document
  folder: Folder | undefined
  files: File[]
  abortController: AbortController
}

export interface InfoboxMediaViewImperativeMethods {
  handleUpload: () => void
  mediaAlert: () => void
}

const InfoboxMediaView = (props: Props, ref: ForwardedRef<InfoboxMediaViewImperativeMethods>) => {
  useImperativeHandle(ref, () => ({
    handleUpload: () => {
      handleUpload()
    },
    mediaAlert: () => {
      mediaAlert()
    },
  }))

  const { language, i18n } = useLanguage()
  const navigation = useNavigation()
  const toast = useToast()
  const alert = useAlert()

  const [mediaLoading, setMediaLoading] = useState(false)
  const [sortingMethod, _] = useState<MediaSortingMethod>('reverse')
  const [selectedAsset, setSelectedAsset] = useState<Asset[]>([])

  const [upload, setUpload] = useRecoilState(uploadCountAtom)
  const [assetToSync, setAssetToSync] = useRecoilState(assetsToSyncAtom)
  const displayMethod = useRecoilValue(mediaSortingMethodInfoboxAtom)
  const saveMedia = useRecoilValue(saveMediaOnDeviceAtom)
  const resetDocumentCount = useResetRecoilState(documentCountAtom)
  const resetUploadCounter = useResetRecoilState(uploadCountAtom)
  const setVisible = useSetRecoilState(assetUploadVisibleAtom)
  const { isSmallDevice } = useLayout()

  useEffect(() => {
    return () => {
      props.abortController && props.abortController.abort()
    }
  }, [])

  useEffect(() => {
    return () => {
      if (!isSmallDevice) {
        props.abortController && props.abortController.abort()
      }
    }
  }, [props.folder])

  useEffect(() => {
    setMediaLoading(true)
  }, [props.document, props.folder, props.files])

  useEffect(() => {
    setAssetToSync(mediaUtils.getAssetNumberToSync(selectedAsset))
  }, [selectedAsset])

  useEffect(() => {
    if (mediaLoading === false || props.folder === undefined) {
      return
    }

    const promises: Promise<Asset>[] = []

    appUtils.sort(props.files, sortingMethod).forEach(file => {
      if (file.folderNumber === props.folder?.number) {
        if (selectedAsset.find(elem => elem.origin === 'backend' && elem.id === file.id) === undefined) {
          if (file.thumbImageAsPNG) {
            promises.push(
              BackendAsset.Create(
                'image',
                file.id,
                file.filename,
                undefined,
                file.thumbImageAsPNG,
                file.sizeKB * 1000,
                file.canDelete,
                new Date(file.date)
              )
            )
          } else {
            promises.push(
              BackendAsset.Create(
                mediaUtils.getAssetType(file.extension),
                file.id,
                file.filename,
                undefined,
                undefined,
                file.sizeKB * 1000,
                file.canDelete,
                new Date(file.date)
              )
            )
          }
        }
      }
    })

    Promise.all(promises)
      .then(newAssets => {
        setSelectedAsset(newAssets)
      })
      .catch(err => console.error('Can not create backend asset', err))
      .finally(() => {
        setMediaLoading(false)
      })
  }, [props.files, props.folder, mediaLoading])

  useEffect(() => {
    if (upload.current === upload.total) {
      setTimeout(() => {
        goBack()
      }, 1200)
    }
  }, [upload])

  function fetchHighResAsset(idSelected: string) {
    return new Promise<{ path?: string; base64?: string }>((resolve, reject) => {
      api
        .getInfoboxFile({ id: idSelected, infoboxTyp: props.document.documentType }, props.abortController)
        .then(result => {
          if (Platform.OS === 'web') {
            resolve({ base64: result.data })
          } else {
            const path = assetManagementUtils.getCacheDir() + `/highRes/${result.filename}`
            assetManagementUtils
              .writeFile(path, result.data, 'base64')
              .then(() => {
                resolve({ path })
              })
              .catch(err => reject(err))
          }
        })
        .catch(err => {
          reject(err)
          toast.show(i18n.t('ASSET_HIGH_RES_RETRIEVE_ERROR'), {
            type: 'danger',
          })
        })
    })
  }

  function handleUpload() {
    if (assetToSync > 0) {
      console.debug(`Upload preparation result: files to sync ${assetToSync}`)

      setUpload({ current: 0, total: assetToSync })
      setVisible(true)
      syncMedia(selectedAsset)
    } else {
      goBack()
    }
  }

  function goBack(isDiscarded = false) {
    if (isDiscarded === false || !isSmallDevice) {
      setSelectedAsset([])
      resetDocumentCount()
    }
    resetUploadCounter()
    setVisible(false)
    isSmallDevice && navigation.goBack()
  }

  function syncMedia(assets: Asset[]) {
    assets.forEach(asset => {
      if (asset.origin === 'backend' && asset.markedForRemoval) {
        if (asset.id === null) {
          return console.error('The asset has been marked for removal but no id has been assigned')
        }

        api
          .deleteInfoboxFile({ id: asset.id, infoboxTyp: props.document.documentType })
          .catch(err => {
            console.error(`Failed deleting infobox file ${asset.id} -`, err)
            toast.show(Utils.stringValueReplacer(i18n.t('ASSET_DELETE_ERROR'), asset.filename), {
              type: 'danger',
            })
          })
          .finally(() => setUpload(prev => ({ ...prev, current: prev.current + 1 })))
      }

      if (asset.origin !== 'backend') {
        asset
          .getCompressedBase64()
          .then(data => {
            if (typeof data === 'string') {
              InfomindsAnalytics.logCustomEvent('file_upload')

              api
                .postInfoboxFile({
                  file: data,
                  infoboxTyp: props.document.documentType,
                  filename: asset.filename,
                  id: props.document.id,
                  note: null,
                  foldernumber: props.folder?.number ?? 0,
                })
                .then(res => console.debug('POST new file', res))
                .catch(err => {
                  console.error(`Failed post infobox file ${asset.filename} -`, err)
                  toast.show(Utils.stringValueReplacer(i18n.t('ASSET_POST_ERROR'), asset.filename), {
                    type: 'danger',
                  })
                })
                .finally(() => setUpload(prev => ({ ...prev, current: prev.current + 1 })))
            }
          })
          .catch(err => {
            console.error(`Failed compressing asset ${asset.filename} -`, err)
            toast.show(Utils.stringValueReplacer(i18n.t('ASSET_COMPRESSION_ERROR'), asset.filename), {
              type: 'danger',
            })
            setUpload(prev => ({ ...prev, current: prev.current + 1 }))
          })
      }
    })
  }

  const mediaAlert = () => {
    alert.alert(i18n.t('WARNING'), i18n.t('MEDIA_WARNING_MESSAGE'), [
      {
        text: i18n.t('DISCARD'),
        onPress: () => goBack(true),
        style: 'destructive',
      },
      {
        text: i18n.t('CANCEL'),
        style: 'cancel',
      },
      { text: i18n.t('UPLOAD'), onPress: handleUpload, style: 'default' },
    ])
  }

  if (!props.folder) return <NoEntry description={i18n.t('NO_FOLDER_SELECTED')} />
  return (
    <>
      <Media
        loading={mediaLoading}
        media={selectedAsset}
        onMediaChange={setSelectedAsset}
        onMarkForRemoval={() => {
          setAssetToSync(mediaUtils.getAssetNumberToSync(selectedAsset))
        }}
        language={language}
        displayMethod={displayMethod}
        buttonContainerStyle={styles.mediaButtonContainer}
        fetchHighResAsset={fetchHighResAsset}
        sortingMethod={sortingMethod}
        right={props.folder.right === FolderRight.read ? 'read' : 'write'}
        saveOnGallery={saveMedia}
        widthRatio={Platform.OS === 'web' || DeviceInfo.isTablet() ? 1 / 3 : undefined}
        orientationOnExit={isSmallDevice ? OrientationType.PORTRAIT : OrientationType.UNKNOWN}
      />
      <UploadModal />
    </>
  )
}

const styles = StyleSheet.create({ mediaButtonContainer: { paddingHorizontal: 5 } })

export default forwardRef(InfoboxMediaView)
