import { useApi } from '@infominds/react-api'
import React, { createContext, ReactNode, useContext } from 'react'

import api from '../apis/apiCalls'
import type { EmployeeTimeType } from '../apis/types/apiResponseTypes'

export interface ActivitiesContext {
  activities: EmployeeTimeType[]
  loadingActivities: boolean
  loadActivities: () => Promise<EmployeeTimeType[] | undefined>
  getActivityById: (id: string) => EmployeeTimeType | undefined
}

export const ActivitiesContext = createContext<ActivitiesContext>({
  activities: [],
  loadingActivities: false,
  loadActivities: () => Promise.resolve(undefined),
  getActivityById: () => undefined,
})

export function ActivitiesContextProvider({ children }: { children: ReactNode }) {
  const [employeeTimeTypes, loadEmployeeTimeTypes, loadingEmployeeTimeTypes] = useApi(api.getEmployeeTimeTypes, [])

  function getActivityById(id: string) {
    return employeeTimeTypes?.find(ac => ac.id === id)
  }

  return (
    <ActivitiesContext.Provider
      value={{
        activities: employeeTimeTypes ?? [],
        loadingActivities: loadingEmployeeTimeTypes,
        loadActivities: loadEmployeeTimeTypes,
        getActivityById,
      }}>
      {children}
    </ActivitiesContext.Provider>
  )
}

export function useActivities() {
  return useContext(ActivitiesContext)
}
