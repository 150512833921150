import { IM } from '@infominds/react-native-components'
import React from 'react'

import SkeletonText from '../components/Infominds/Skeleton/SkeletonText'

export default function LoadingIndicatorCard() {
  return (
    <IM.Card spacing="bottom">
      <SkeletonText />
    </IM.Card>
  )
}
