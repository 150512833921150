import type { Language } from '@infominds/react-native-components'
import { format as dateFormat, differenceInCalendarDays, isSameDay, parseISO } from 'date-fns'
import { de, enGB, it } from 'date-fns/locale'

type FormatOptions = {
  locale?: Locale | undefined
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined
  firstWeekContainsDate?: number | undefined
  useAdditionalWeekYearTokens?: boolean | undefined
  useAdditionalDayOfYearTokens?: boolean | undefined
}

const DateUtils = {
  getLocale(id: Language) {
    switch (id) {
      case 'en':
        return enGB
      case 'de':
        return de
      case 'it':
        return it
      default:
        return enGB
    }
  },
  dateify(date: Date | string | number) {
    if (typeof date === 'object') return date
    if (typeof date === 'string') {
      return parseISO(date)
    }
    return new Date(date)
  },
  formatDate(date: Date | undefined, format: string, language?: Language) {
    if (!date) return ''
    if (typeof date !== 'object') date = this.dateify(date)
    try {
      const options: FormatOptions = {}
      if (language) options.locale = this.getLocale(language)
      return dateFormat(date, format, options)
    } catch (exception) {
      console.error('formatDate Error', exception)
    }
    return ''
  },
  differenceInDays(dateA: Date, dateB: Date) {
    if (!dateA || !dateB) return 0
    return Math.abs(differenceInCalendarDays(dateA, dateB))
  },
  keepUniqueDates(dates: Date[]) {
    if (!dates) return []
    const uniqueDates: Date[] = []
    for (const date of dates) {
      if (!uniqueDates.find(d => isSameDay(d, date))) uniqueDates.push(date)
    }
    return uniqueDates
  },
}

export default DateUtils
