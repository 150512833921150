import { IM } from '@infominds/react-native-components'
import { RouteProp, useRoute } from '@react-navigation/native'
import React, { createRef, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { useRecoilValue } from 'recoil'

import type { Document, File, Folder } from '../../apis/types/apiResponseTypes'
import InfoboxHeader from '../../components/InfoboxHeader'
import InfoboxTabletHeader from '../../components/InfoboxTabletHeader'
import Screen from '../../components/Infominds/Screen'
import { ScreenHeader } from '../../components/ScreenHeader'
import useLayout from '../../hooks/useLayout'
import type { RootStackParamList } from '../../navigation/Navigator'
import { assetsToSyncAtom } from '../../utils/stateManager'
import InfoBoxDocumentsView from '../../views/InfoBox/InfoBoxDocumentsView'
import InfoboxFolderView from '../../views/InfoBox/InfoboxFolderView'
import InfoboxMediaView, { InfoboxMediaViewImperativeMethods } from '../../views/InfoBox/InfoboxMediaView'

export type InfoBoxScreenProps = { onDocumentSelected?: (document: Document) => void; onHeaderClose?: () => void; forceSmallDevice?: boolean }

export type SelectedAsset = {
  document: Document | undefined
  folder: Folder | undefined
  files: File[]
}

export default function InfoBoxScreen({ onDocumentSelected, onHeaderClose, forceSmallDevice }: InfoBoxScreenProps) {
  const { isSmallDevice } = useLayout()
  const routeDocument = useRoute<RouteProp<RootStackParamList, 'InfoBox'>>().params?.document
  const folderAbortController = new AbortController()
  const mediaAbortController = new AbortController()

  const [selected, setSelected] = useState<SelectedAsset>({ document: undefined, files: [], folder: undefined })

  const mediaRef = createRef<InfoboxMediaViewImperativeMethods>()
  const assetToSync = useRecoilValue(assetsToSyncAtom)

  useEffect(() => {
    setSelected({ folder: undefined, files: [], document: routeDocument })
  }, [routeDocument])

  useEffect(() => {
    setSelected({ document: undefined, files: [], folder: undefined })
  }, [isSmallDevice])

  function handleDocumentPress(document: Document) {
    if (!isSmallDevice) {
      if (assetToSync > 0) {
        mediaRef.current?.mediaAlert()
      } else {
        setSelected({ folder: undefined, files: [], document: document })
      }

      return
    }
  }

  function handleFolderPress(folder: Folder, files: File[]) {
    if (assetToSync > 0) {
      mediaRef.current?.mediaAlert()
    } else {
      setSelected(prevState => ({ ...prevState, folder: folder, files: files }))
    }
  }

  function handleMediaUpload() {
    mediaRef.current?.handleUpload()
    setSelected(prevState => ({ ...prevState, files: [] }))
  }

  return (
    <Screen
      hideBackgroundImage={!isSmallDevice && forceSmallDevice}
      screenHeader={
        <ScreenHeader
          close={onHeaderClose}
          barContent={
            <InfoboxHeader
              documentName={selected.document?.code}
              mediaHeader={selected.folder ? <InfoboxTabletHeader onMediaUpload={handleMediaUpload} /> : undefined}
              forceSmallDevice={forceSmallDevice}
            />
          }
        />
      }>
      <IM.MasterDetail forceSmallDevice={forceSmallDevice}>
        <IM.MasterDetail.Master>
          <InfoBoxDocumentsView selectedDocument={selected.document} onDocumentPress={handleDocumentPress} onDocumentSelected={onDocumentSelected} />
        </IM.MasterDetail.Master>

        <IM.MasterDetail.Detail>
          <IM.MasterDetail flexRatio={0.5}>
            <IM.MasterDetail.Master>
              <InfoboxFolderView
                document={selected.document}
                selectedFolder={selected.folder}
                onFolderPress={handleFolderPress}
                onResetSelection={() => {
                  setSelected({ folder: undefined, files: [], document: undefined })
                }}
                abortController={folderAbortController}
              />
            </IM.MasterDetail.Master>
            <IM.MasterDetail.Detail>
              {selected.document && (
                <IM.View spacing="bottom" style={styles.mediaContainer}>
                  <InfoboxMediaView
                    ref={mediaRef}
                    document={selected.document}
                    files={selected.files}
                    folder={selected.folder}
                    abortController={mediaAbortController}
                  />
                </IM.View>
              )}
            </IM.MasterDetail.Detail>
          </IM.MasterDetail>
        </IM.MasterDetail.Detail>
      </IM.MasterDetail>
    </Screen>
  )
}

const styles = StyleSheet.create({
  mediaContainer: {
    flex: 1,
  },
})
