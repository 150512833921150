import { CardBasicProps, IM, IMStyle, SpacingProps, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import DateUtils from '../utils/DateUtils'

export type TimeCardProps = {
  time: Date
  spacing?: SpacingProps
  type?: 'start' | 'stop'
} & CardBasicProps

export default function TimeCard({ time, spacing, type, style, disabled, ...cardProps }: TimeCardProps) {
  const { theme } = useTheme()
  const timeText = DateUtils.formatDate(time, 'HH:mm')

  return (
    <IM.CardBasic spacing={spacing} style={style} disabled={disabled} {...cardProps}>
      <IM.View spacing={'all'} style={styles.contentView}>
        <IM.View style={styles.textContainer}>
          {!type && <IM.Text>{timeText}</IM.Text>}
          {!!type && (
            <IM.TextWithIcon
              iconSize={15}
              iconColor={type === 'start' ? IMStyle.palette.tint : IMStyle.palette.red}
              icon={type === 'start' ? ['fas', 'play-circle'] : ['fas', 'stop-circle']}>
              {timeText}
            </IM.TextWithIcon>
          )}
        </IM.View>
        {!disabled && <IM.Icon icon={['fal', 'pen-to-square']} color={theme.textDetail} />}
      </IM.View>
    </IM.CardBasic>
  )
}

const styles = StyleSheet.create({
  contentView: {
    flexDirection: 'row',
  },
  textContainer: {
    flex: 1,
  },
})
