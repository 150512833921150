import { IM } from '@infominds/react-native-components'
import React from 'react'

export default function SkeletonCard() {
  return (
    <IM.SkeletonContainer height={60}>
      <IM.Rect x="0" y="0" rx="5" ry="5" width="60" height="60" />
      <IM.Rect x="84" y="13" rx="5" ry="5" width="100" height="12" />
      <IM.Rect x="84" y="33" rx="5" ry="5" width="180" height="12" />
    </IM.SkeletonContainer>
  )
}
