import { IM, useTheme } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'
import DefaultModal from 'react-native-modal'

export interface ModalFullScreenProps {
  isVisible: boolean
  children: ReactNode
  style?: StyleProp<ViewStyle>
  coverScreen?: boolean
  statusBarTranslucent?: boolean
  useNativeDriver?: boolean
  onClose: () => void
}

export default function ModalFullScreen({
  isVisible,
  children,
  style,
  coverScreen = true,
  useNativeDriver = false,
  statusBarTranslucent = false,
  onClose,
}: ModalFullScreenProps) {
  const { theme } = useTheme()

  return (
    <DefaultModal
      isVisible={isVisible}
      onSwipeComplete={onClose}
      onBackButtonPress={onClose}
      propagateSwipe
      coverScreen={coverScreen}
      hideModalContentWhileAnimating
      statusBarTranslucent={statusBarTranslucent}
      useNativeDriver={useNativeDriver}
      backdropOpacity={0}
      style={[styles.container, { backgroundColor: theme.background }, style]}>
      <IM.View style={styles.view}>{children}</IM.View>
    </DefaultModal>
  )
}

const styles = StyleSheet.create({
  container: { margin: 0 },
  view: { height: '100%' },
})
