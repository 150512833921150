import { CardBasicProps, IM, IMStyle, SpacingProps, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet, View } from 'react-native'

export type IMContentCardProps = {
  title?: string
  spacing?: SpacingProps
  error?: boolean
} & CardBasicProps

export default function IMContentCard({ title, spacing, children, error, ...baseCardProps }: IMContentCardProps) {
  const { theme } = useTheme()

  return (
    <IM.CardBasic style={!!error && styles.error} spacing={spacing} {...baseCardProps}>
      <View style={[styles.view, { backgroundColor: theme.card.headBackground }, !!error && { backgroundColor: theme.error }]}>
        <IM.Text numberOfLines={1} style={{ color: IMStyle.palette.white }}>
          {title}
        </IM.Text>
      </View>
      {children}
    </IM.CardBasic>
  )
}

const styles = StyleSheet.create({
  view: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderTopRightRadius: IMStyle.layout.borderRadius,
    borderTopLeftRadius: IMStyle.layout.borderRadius,
  },
  error: {
    borderWidth: 1,
    borderColor: IMStyle.palette.red,
  },
})
