import { IM } from '@infominds/react-native-components'
import { useIsFocused } from '@react-navigation/native'
import React, { useEffect } from 'react'

import ErrorDisplay from '../../components/ErrorDIsplay'
import { ScreenHeader } from '../../components/ScreenHeader'
import { usePresenceTime } from '../../contexts/PresenceTimeContext'
import PresenceTimeLoadingView from '../../views/PresenceTimes/PresenceTimeLoadingView'
import PresenceTimeView from '../../views/PresenceTimes/PresenceTimeView'
import PresenceTimeHeader from './PresenceTimeHeader'

export default function PresenceTimeScreen() {
  const presenceTime = usePresenceTime()

  const isFocused = useIsFocused()

  useEffect(() => {
    if (!isFocused) return
    load()
  }, [isFocused])

  function load() {
    presenceTime.load().catch(console.error)
  }

  return (
    <IM.Screen screenHeader={<ScreenHeader barContent={<PresenceTimeHeader mode="home" />} />}>
      {!!presenceTime.loading && !presenceTime.presenceTimeEntries.length && <PresenceTimeLoadingView />}
      {!presenceTime.loading && <ErrorDisplay error={presenceTime.error} spacing={'all'} />}
      {!!presenceTime.presenceTimeInfo && <PresenceTimeView />}
    </IM.Screen>
  )
}
