import { IM, IMLayout, SpacingProps, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'
import ToastContainer from 'react-native-toast-notifications'

import { PresenceTimeDetail } from '../../apis/types/apiResponseTypes'
import TimeSpanSelector from '../../components/TimeSpanSelector'

export type PresenceTimeKeyDetailCardProps = {
  detail: PresenceTimeDetail
  updateDetail: (detail: PresenceTimeDetail) => void
  deleteDetail: () => void
  spacing?: SpacingProps
  toastRef?: React.MutableRefObject<ToastContainer | undefined>
}

export default function PresenceTimeKeyDetailCard({ detail, updateDetail, deleteDetail, spacing, toastRef }: PresenceTimeKeyDetailCardProps) {
  const { theme } = useTheme()

  return (
    <IM.View spacing={spacing} style={styles.main}>
      <IM.CardBasic style={styles.card}>
        <IM.View style={IMLayout.flex.f1}>
          <TimeSpanSelector
            from={detail.from ?? 0}
            until={detail.until ?? 0}
            setFrom={from => updateDetail({ ...detail, from: from || 1 })}
            setUntil={until => updateDetail({ ...detail, until: until || 1 })}
            allowDateSkip={false}
            toastRef={toastRef}
            noActiveTime
          />
        </IM.View>
      </IM.CardBasic>

      <IM.View style={styles.deleteButtonView}>
        <IM.PressableIcon icon={['fal', 'trash']} size={22} color={theme.error} onPress={deleteDetail} />
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
  },
  card: {
    padding: 10,
    flex: 1,
  },
  deleteButtonView: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 10,
  },
})
