import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../types'

interface Props {
  color?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  height?: any
  style?: StyleProp<ViewStyle>
}

const Divider = memo(function Divider({ color, height = '60%', style }: Props) {
  const { colorScheme } = useTheme<ThemeColorExpanded>()
  const dividerColor = color ?? colorScheme === 'light' ? '#535F71' : '#202020'

  return (
    <IM.View
      style={[
        styles.divider,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        { height, backgroundColor: color ?? dividerColor, marginHorizontal: 2 * IMLayout.horizontalMargin },
        style,
      ]}
    />
  )
})

export default Divider

const styles = StyleSheet.create({
  divider: { width: 1, borderRadius: IMLayout.borderRadius, zIndex: 10000 },
})
