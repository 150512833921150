import { IM, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { uploadCountAtom } from '../utils/stateManager'

export type UploadType = {
  total: number
  current: number
}

const UploadModalMessage = () => {
  const { i18n } = useLanguage()
  const upload = useRecoilValue(uploadCountAtom)

  return (
    <IM.Text>
      {i18n.t('UPLOADING')}
      {`: ${Math.floor((upload.current / upload.total) * 100)}%`}
    </IM.Text>
  )
}

export default UploadModalMessage
