import { IM, IMLayout, IMStyle, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'

import type { EmployeeTime } from '../../apis/types/apiResponseTypes'
import ErrorDisplay from '../../components/ErrorDIsplay'
import useCalendar from '../../components/Infominds/Calendar/hooks/useCalendar'
import SkeletonText from '../../components/Infominds/Skeleton/SkeletonText'
import TimeBar from '../../components/TimeBar'
import useAllowEmployeeTimeEdit from '../../hooks/useAllowEmployeeTimeEdit'
import CreateTimeModal, { CreateTimeData } from '../../modals/CreateTimeModal'
import type { DocumentElement } from './EmployeeTimeDocumentView'
import EmployeeTimeSummaryDocumentsView from './EmployeeTimeSummaryDocumentsView'

interface EmployeeTimeSummaryViewProps {
  times: EmployeeTime[] | undefined
  loading: boolean
  error: boolean
  reLoad?: () => void
  disableEdit?: boolean
}

export default function EmployeeTimeSummaryView({ loading, times, error, reLoad, disableEdit }: EmployeeTimeSummaryViewProps) {
  const { i18n } = useLanguage()
  const { theme, themeUtils } = useTheme()
  const { date } = useCalendar()
  const createEmployeeTimeModal = useModalController<CreateTimeData>()
  const { allowEdit } = useAllowEmployeeTimeEdit(date)

  const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    showMore && setShowMore(false)
  }, [times])

  const filterTimeInProgress = times?.filter(elem => {
    if (times.length === 1) {
      return elem.until > elem.from
    } else {
      return true
    }
  })

  const totalTime = useMemo(() => {
    return filterTimeInProgress?.reduce((acc: number, current) => acc + current.duration, 0)
  }, [filterTimeInProgress])

  let documents = useMemo(() => {
    return filterTimeInProgress
      ?.reduce<DocumentElement[]>((acc, current) => {
        if (totalTime === undefined) {
          return acc
        }

        const documentId = current.documentId ?? 'DocWithoutID'
        const documentFound = acc.find(elem => elem.id === documentId)
        const activity = { id: current.employeeTimeTypeId, name: current.employeeTimeType, duration: current.duration }

        if (documentFound) {
          documentFound.duration += current.duration
          documentFound.ratio = documentFound.duration / totalTime

          const activityFound = documentFound.activities.find(elem => elem.id === activity.id)

          if (activityFound) {
            activityFound.duration += activity.duration
          } else {
            documentFound.activities.push(activity)
          }
        } else {
          const doc = current.document ?? i18n.t('NO_DOCUMENT')

          acc.push({
            id: documentId,
            name: doc,
            duration: current.duration,
            ratio: current.duration / totalTime,
            activities: [activity],
            color: themeUtils.getRandomColorFromTheme(doc),
            displayRatio: current.duration / totalTime,
          })
        }

        return acc
      }, [])
      .sort((a, b) => a.duration - b.duration)
  }, [filterTimeInProgress, totalTime])

  documents = useMemo(
    () =>
      documents
        ?.reduce<DocumentElement[]>((acc, current, index) => {
          const pos = index - 1

          if (pos >= 0) {
            const prevValue = acc[pos]

            if (prevValue) {
              current.displayRatio = prevValue.displayRatio + current.ratio
            }
          } else {
            current.displayRatio = current.ratio
          }

          acc.push(current)
          return acc
        }, [])
        .sort((a, b) => {
          return b.duration - a.duration
        }),
    [documents]
  )

  const enabled = allowEdit && !disableEdit

  return (
    <IM.Card spacing="bottom" disabled={!enabled} onPress={() => createEmployeeTimeModal.show({ date })}>
      {loading || !times ? (
        <SkeletonText />
      ) : (
        <>
          <IM.View style={IMLayout.flex.row} spacing="bottom">
            <IM.View style={IMLayout.flex.f1}>
              <IM.Text primary>{i18n.t('TAB_EMPLOYEE_TIMES')}</IM.Text>
            </IM.View>
            {enabled && <IM.Icon icon={['fal', 'plus']} size={20} color={theme.icon} />}
          </IM.View>
          {times?.length === 0 && (
            <>
              {!!error && <ErrorDisplay error={i18n.t('FETCH_TIMES_ERROR')} />}
              {!error && <IM.Text>{i18n.t('NO_TIMES')}</IM.Text>}
            </>
          )}

          {!!times.length && (
            <>
              <TimeBar
                elements={documents ?? []}
                title={i18n.t('TOTAL')}
                time={totalTime ?? 0}
                spacing={filterTimeInProgress?.length === 0 ? 'none' : 'bottom'}
              />
              {filterTimeInProgress?.length !== 0 && (
                <>
                  <EmployeeTimeSummaryDocumentsView documents={documents ?? []} showDetails={showMore} />
                  <IM.View style={styles.details} spacing={showMore ? 'none' : 'top'}>
                    <IM.PressableTextIcon
                      icon={['fal', showMore ? 'angle-up' : 'angle-down']}
                      size={12}
                      onPress={() => {
                        setShowMore(prev => !prev)
                      }}
                      leftText={showMore ? i18n.t('SHOW_LESS') : i18n.t('SHOW_MORE')}
                    />
                  </IM.View>
                </>
              )}
            </>
          )}
          {!!reLoad && !disableEdit && <CreateTimeModal controller={createEmployeeTimeModal} onTimeCreated={reLoad} />}
        </>
      )}
    </IM.Card>
  )
}

const styles = StyleSheet.create({
  barContainer: { height: IMStyle.typography.fontSizeSmall },
  barRow: { position: 'absolute', top: 0, left: 0, borderRadius: IMStyle.layout.borderRadius, height: '100%', borderWidth: 1 },
  details: { justifyContent: 'center', alignItems: 'center', flexDirection: 'row', flex: 1 },
  textRow: { flexDirection: 'row', justifyContent: 'space-between' },
})
